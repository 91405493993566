import { Navigate } from 'react-router-dom';

import Occurrences from 'pages/Main/pages/BI/Occurrences';
import Budgets from 'pages/Main/pages/BI/Budgets';

import PrivateRoute from 'routes/PrivateRoute';

import { SUBROUTES, MODULES, VISUALIZE } from 'utils/constants';

const { path, subroutes } = SUBROUTES.bi;
const { main, sub } = MODULES.bi;

const BiRoutes = [
  {
    path: subroutes.occurrences.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.occurrences}
        permission={VISUALIZE}>
        <Occurrences />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.budgets.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.budgets}
        permission={VISUALIZE}>
        <Budgets />
      </PrivateRoute>
    ),
  },
  {
    path,
    element: <Navigate to={subroutes.occurrences.path} replace />,
  },
];

export default BiRoutes;
