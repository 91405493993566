import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';

import 'react-image-crop/dist/ReactCrop.css';

import PageContainer from 'components/ui-kit/PageContainer';
import PageHeader from 'components/ui-kit/PageHeader';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import ModalImage from 'components/pages/Profile/ModalImage';

import ChangePassword from 'components/pages/Profile/ChangePassword';

import api from 'services/api';
import { BEARER, SUBROUTES } from 'utils/constants';
import { NO_DATA_FOUND } from 'utils/messages';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

import { ReactComponent as UserIcon } from 'assets/images/svg/user.svg';
import { ReactComponent as ArrowDown } from 'assets/images/svg/red_arrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/images/svg/green_arrow_up.svg';

import { variant } from 'utils/functions';

const { subroutes } = SUBROUTES.register;

const Container = styled.div`
  ${tw`flex flex-wrap w-full gap-5 justify-center`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-wrap w-full h-fit justify-center gap-5 bg-white p-4 rounded-xl shadow-lg`}

  max-width: ${({ $maxWidth }) => $maxWidth || 'fit-content'};
`;

const DataProfile = styled.div`
  ${tw`flex flex-wrap w-full justify-center gap-x-10 gap-y-4`}
`;

const Infos = styled.div`
  ${tw`flex flex-col w-auto h-auto items-center gap-2`}
`;

const OccurrencesCount = styled.div`
  ${tw`flex flex-col w-auto h-auto gap-5`}
`;

const UserPhoto = styled.div`
  ${tw`w-auto h-auto shadow-lg bg-[linear-gradient(180deg, rgba(9, 28, 39, 1) 0%, #084A86 100%)] p-5 rounded-circle flex justify-center items-center text-center`}
  img {
    ${tw`flex object-cover rounded-circle `}
    width: 180px;
    object-fit: cover;
  }
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-wrap w-full h-auto gap-5 justify-center my-4`}
`;

const Text = styled.span`
  ${tw`text-base text-center text-[var(--gray-dark)] font-bold truncate`}

  ${({ $variant }) =>
    variant({
      title: tw`text-2xl leading-9 bg-clip-text bg-gradient-to-b from-[#1F53FF] to-[#0A1B24] [-webkit-text-fill-color: transparent;]`,
    })({ $variant })}
`;

const DataWrapper = styled.div`
  ${tw`flex flex-col items-start`}
`;

const iconStyle = tw`w-[40px] h-[40px]`;

const Profile = () => {
  const [userData, setUserData] = useState([]);

  const [imageURL, setImageURL] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const navigate = useNavigate();

  const getUserData = async () => {
    setIsLoading(true);

    try {
      const { data: response, status } = await api.get(`/users/${user.id}`, {
        onProgress,
        headers: {
          Authorization: BEARER + user.token,
        },
      });

      if (status !== 200) throw new Error();
      else if (!response?.data) {
        throw new Error();
      }

      setUserData(response?.data);

      if (response?.data?.image_id) {
        const imageResponse = await api.get(
          `/files/${response?.data.image_id}`,
          {
            headers: {
              Authorization: BEARER + user.token,
            },
          },
        );

        if (imageResponse?.status === 200)
          setImageURL(imageResponse.request.responseURL);
      }
    } catch (error) {
      toast.error(NO_DATA_FOUND);
    } finally {
      resetProgress();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader titles={['Perfil', 'Meu Perfil']} />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <Container>
        <Wrapper $maxWidth="75rem">
          <UserPhoto>
            {imageURL ? (
              <img src={imageURL} alt="photo" />
            ) : (
              <UserIcon width={100} height={100} />
            )}
          </UserPhoto>

          <DataProfile>
            <DataWrapper>
              <Text>Nome Completo</Text>
              <Text $variant="title">{userData?.display_name || '-'}</Text>
            </DataWrapper>

            <DataWrapper>
              <Text>Username</Text>
              <Text $variant="title">{userData?.display_name || '-'}</Text>
            </DataWrapper>

            <DataWrapper>
              <Text>Email</Text>
              <Text $variant="title">{userData?.email || '-'}</Text>
            </DataWrapper>

            <DataWrapper>
              <Text>Permissão</Text>
              <Text $variant="title">
                {userData?.role?.display_name || '-'}
              </Text>
            </DataWrapper>
          </DataProfile>

          <ButtonsContainer>
            <PrimaryButton
              type="button"
              maxWidth="300px"
              onClick={() => {
                navigate(`${subroutes.users.path}/${userData.id}`);
              }}>
              Editar Perfil
            </PrimaryButton>

            <PrimaryButton
              type="button"
              maxWidth="300px"
              onClick={() => setChangePassword(true)}>
              Alterar Senha
            </PrimaryButton>

            <PrimaryButton
              type="button"
              maxWidth="300px"
              onClick={() => toast('Em desenvolvimento!')}>
              Alterar Foto
            </PrimaryButton>
          </ButtonsContainer>

          {changePassword && (
            <ChangePassword
              userData={userData}
              setUserData={setUserData}
              setChangePassword={setChangePassword}
            />
          )}
          {isModalOpen && (
            <ModalImage
              closeModal={() => setModalOpen(false)}
              imageURL={imageURL}
              setImageURL={setImageURL}
            />
          )}
        </Wrapper>

        <OccurrencesCount>
          <Wrapper $maxWidth="300px">
            <Infos>
              <Text $variant="title">Chamados Abertos</Text>
              <Text $variant="title">12</Text>
            </Infos>
            <ArrowDown style={iconStyle} />
          </Wrapper>

          <Wrapper $maxWidth="300px">
            <Infos>
              <Text $variant="title">Chamados Encerrados</Text>
              <Text $variant="title">12</Text>
            </Infos>
            <ArrowUp style={iconStyle} />
          </Wrapper>
        </OccurrencesCount>
      </Container>
    </PageContainer>
  );
};

export default Profile;
