import { useCallback, useEffect, useMemo } from 'react';
import useAuth from 'hooks/useAuth';
import { useTimer } from 'react-timer-hook';

import styled from 'styled-components';
import tw from 'twin.macro';

import DefaultModal from './DefaultModal';
import PrimaryButton from './PrimaryButton';

const Container = styled.div`
  ${tw`w-full h-full flex flex-col items-center`}

  & > * {
    ${tw`mb-3 last:mb-0`}
  }
`;

const Text = styled.p`
  ${tw`text-themes-dark-gray text-center font-medium`}
`;

const Button = styled(PrimaryButton)`
  ${tw`!bg-themes-red !h-fit !w-fit box-border py-1 px-4`}
`;

const SessionExpiredModal = ({ isOpen = false }) => {
  const { logout } = useAuth();

  const expiryTimestamp = useMemo(() => {
    const now = new Date();

    now.setSeconds(now.getSeconds() + 30);

    return now;
  }, []);

  const { seconds, start } = useTimer({
    expiryTimestamp,
    autoStart: false,
    onExpire: logout,
  });

  const startCountup = useCallback(() => {
    if (!isOpen) return;

    start();
  }, [isOpen]);

  useEffect(() => {
    startCountup();
  }, [isOpen]);

  return (
    <DefaultModal hideHeader isOpen={isOpen}>
      <Container>
        <Text>
          Sua sessão expirou, por favor, <br /> faça o login novamente.
        </Text>

        <Text style={{ fontSize: '14px' }}>
          Saindo em: <b>{seconds}</b> segundos.
        </Text>

        <Button type="button" onClick={logout}>
          Sair
        </Button>
      </Container>
    </DefaultModal>
  );
};

export default SessionExpiredModal;
