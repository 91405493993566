import styled from 'styled-components';
import tw from 'twin.macro';
import { motion } from 'framer-motion';

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="#FFF"
    strokeLinecap="round"
    {...props}
  />
);

const Button = styled(motion.button)`
  ${tw`flex h-full w-fit p-6 justify-center items-center outline-none`}
`;

const MenuToggle = ({ isOpen, onClick }) => (
  <Button
    initial={false}
    animate={isOpen ? 'open' : 'closed'}
    onClick={onClick}>
    <svg width="25" height="25" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </svg>
  </Button>
);

export default MenuToggle;
