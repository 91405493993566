import {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  subWeeks,
} from 'date-fns';
import { IMaskInput } from 'react-imask';

import PageHeader from 'components/ui-kit/PageHeader';
import PageContainer from 'components/ui-kit/PageContainer';
import FilterTool from 'components/ui-kit/FilterTool';
import Table from 'components/ui-kit/Table';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import StatusDisplay from 'components/ui-kit/StatusDisplay';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';
import api from 'services/api';
import Formatter from 'utils/formatter';
import { BEARER, SUBROUTES } from 'utils/constants';
import {
  STANDARD_ERROR,
  NO_DATA_FOUND,
  GETTING_PDF,
  GETTING_XLS,
  PDF_ERROR,
  PDF_READY,
  XLS_ERROR,
  XLS_READY,
} from 'utils/messages';
import {
  getInitialDates,
  getExportData,
  getBackendDateFormat,
  getUniqueValues,
} from 'utils/functions';
import exportPDF from 'services/pdf';
import exportXLS from 'services/xls';

const { subroutes } = SUBROUTES.financial;

import { ReactComponent as ClockIcon } from 'assets/images/svg/clock.svg';
import { ReactComponent as CheckedIcon } from 'assets/images/svg/check.svg';

const defaultColumns = [
  { label: 'N° Protocolo', field: 'numProtocol' },
  {
    label: 'Data da Ocorrência',
    field: 'occurrenceDate',
    display: 'fmtdOccurrenceDate',
  },
  { label: 'Associado(s)', field: 'associateName' },
  { label: 'Placa(s)', field: 'vehiclePlate' },
  { label: 'Origem(ns)', field: 'origin' },
  { label: 'Destino(s)', field: 'destiny' },
  { label: 'Prestador(es) de Serviço', field: 'serviceProviderName' },
  {
    label: 'Valor Orçado',
    field: 'amountBudget',
    display: 'amountBudgetBRL',
  },
  {
    label: 'Valor Fechado',
    field: 'amountClosed',
    display: 'amountClosedBRL',
  },
  {
    label: 'Pago Associação',
    field: 'amountPaid',
    display: 'amountPaidBRL',
  },
  { label: 'Pagamentos concluídos', field: 'paymentsCompleted' },
  { label: 'Status', field: 'statusValue', display: 'statusDisplay' },
];

const configStatus = {
  valueOptions: ['Em aberto', 'Finalizado'],
  labels: ['Em aberto', 'Finalizado'],
  colors: ['var(--warning-dark)', 'var(--basic-primary)'],
  icons: [ClockIcon, CheckedIcon],
};

const List = () => {
  const [paymentsList, setPaymentsList] = useState([]);

  const [numProtocolInput, setNumProtocolInput] = useState();
  const [associate, setAssociate] = useState();
  const [vehicle, setVehicle] = useState();
  const [serviceProvider, setServiceProvider] = useState();
  const [statusPayment, setStatusPayment] = useState(false);

  const [dates, setDates] = useState({
    startDate: getBackendDateFormat(subMonths(new Date(), 1)),
    endDate: getBackendDateFormat(new Date()),
  });
  const [columns, setColumns] = useState(defaultColumns);
  const [isLoading, setIsLoading] = useState(false);

  const vehicleSeletRef = useRef(null);
  const tableRef = useRef(null);

  const { user } = useAuth();
  const { defaultDateFormat, exportDateFormat, formatToBRL } = new Formatter();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const navigate = useNavigate();

  const [searchHistory, setSearchHistory] = useState({});

  const inputs = [
    {
      onAccept: value => setNumProtocolInput(value.toUpperCase()),
      as: IMaskInput,
      value: numProtocolInput,
      mask: 'a-0000000',
      placeholder: 'N° Protocolo',
      size: 'sm',
      height: '33px',
      maxWidth: '180px',
      $variant: 'registry',
    },
    {
      type: 'select',
      maxWidth: '300px',
      endpoint: 'clients/all?active=true',
      fieldToOptionLabel: 'display_name',
      fieldToOptionValue: 'id',
      placeholder: 'Associado',
      onOptionChange: option => {
        if (vehicle?.id) vehicleSeletRef.current?.clearValue();

        setAssociate(option);
      },
    },
    {
      ref: vehicleSeletRef,
      type: 'select',
      maxWidth: '220px',
      isDisabled: !associate?.id,
      endpoint: `vehicles/?client_id=${associate?.id}`,
      fieldToOptionLabel: 'plate',
      fieldToOptionValue: 'id',
      placeholder: 'Selecione uma Placa',
      onOptionChange: option => setVehicle(option),
    },
    {
      type: 'select',
      maxWidth: '250px',
      endpoint: 'tows/',
      fieldToOptionLabel: 'name',
      fieldToOptionValue: 'id',
      placeholder: 'Prestador de Serviço',
      onOptionChange: option => setServiceProvider(option),
    },
    {
      type: 'select',
      size: 'sm',
      maxWidth: '250px',
      placeholder: 'Status',
      onOptionChange: option => setStatusPayment(option.value),
      options: [
        {
          label: 'Em aberto',
          value: false,
        },
        {
          label: 'Finalizado',
          value: true,
        },
      ],
      initialValue: {
        label: 'Em aberto',
        value: false,
      },
    },
  ];

  const getPayments = useCallback(async () => {
    setIsLoading(true);

    setPaymentsList([]);

    const formatParam = value => {
      if (!value) return undefined;

      return value.trim();
    };

    try {
      const { data: response, status } = await api.get(
        'occurrencies/budgets/',
        {
          onProgress,
          headers: {
            Authorization: BEARER + user.token,
          },
          params: {
            created_on_from: dates?.startDate,
            created_on_to: dates?.endDate,
            nr_protocol: formatParam(numProtocolInput),
            associate_id: associate?.value,
            vehicle_id: vehicle?.value,
            tow_id: serviceProvider?.value,
            status_payment_finish: statusPayment,
          },
        },
      );

      if (status !== 200) throw new Error();

      const paymentsLog = response.data.map(item => ({
        occurrenceId: item?.occurrency_id,
        numProtocol: item?.occurrency_nr_protocol,
        occurrenceDate: item?.date,
        associateName: getUniqueValues(
          item?.vehicles || [],
          'client',
          item => item?.display_name || '-',
        ),
        vehiclePlate: item?.vehicles?.map(v => v?.plate || '-').join(', '),
        origin: getUniqueValues(
          item?.vehicles?.flatMap(v => v.locales) || [],
          'from_city',
          item => `${item?.name || '-'} / ${item?.state?.short_code || '-'}`,
        ),
        destiny: getUniqueValues(
          item?.vehicles?.flatMap(v => v.locales) || [],
          'to_city',
          item => `${item?.name || '-'} / ${item?.state?.short_code || '-'}`,
        ),
        serviceProviderName: item?.services_providers
          ?.map(v => v?.name || '-')
          .join(', '),
        amountBudget: item?.value_total_budget || 0,
        amountClosed: item?.value_total_closed || 0,
        amountPaid: item?.value_total_paid || 0,
        totalCompPayments: item?.completed_payments || 0,
        totalBudgets:
          (item?.open_payments || 0) + (item?.completed_payments || 0),
        statusPayment: item?.status_payment_budgets,
      }));

      setPaymentsList(paymentsLog);

      setSearchHistory({
        startDate: dates?.startDate,
        endDate: dates?.endDate,
        numProtocolInput: numProtocolInput,
        vehicleId: vehicle?.value,
        vehiclePlate: vehicle?.label,
        serviceProviderId: serviceProvider?.value,
        serviceProviderName: serviceProvider?.label,
        statusPayment: statusPayment,
      });
    } catch (error) {
      let errorMessage;
      switch (error.response?.status) {
        case 404:
          errorMessage = NO_DATA_FOUND;
          break;
        default:
          errorMessage = STANDARD_ERROR;
      }
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
      resetProgress();
    }
  }, [
    dates,
    numProtocolInput,
    associate,
    vehicle,
    serviceProvider,
    statusPayment,
    searchHistory,
  ]);

  const dataTable = useMemo(() => {
    return paymentsList.map(item => ({
      ...item,
      numProtocol: item?.numProtocol || '-',
      occurrenceDate: item.occurrenceDate,
      fmtdOccurrenceDate: item.occurrenceDate
        ? defaultDateFormat(item.occurrenceDate)
        : '-',
      associateName: item?.associateName || '-',
      vehiclePlate: item?.vehiclePlate || '-',
      origin: item?.origin || '-',
      destiny: item?.destiny || '-',
      serviceProviderName: item?.serviceProviderName || 'Não contém',
      amountBudget: item.amountBudget,
      amountBudgetBRL: formatToBRL(item.amountBudget),
      amountClosed: item.amountClosed,
      amountClosedBRL: formatToBRL(item.amountClosed),
      amountPaid: item.amountPaid,
      amountPaidBRL: formatToBRL(item.amountPaid),
      paymentsCompleted: `${item.totalCompPayments} / ${item.totalBudgets}`,
      statusValue: item?.statusPayment || '-',
      statusDisplay: (
        <StatusDisplay config={configStatus} value={item.statusPayment} />
      ),
    }));
  }, [paymentsList]);

  const handleExportData = () => {
    const formatting = {
      occurrenceDate: defaultDateFormat,
      amountBudget: formatToBRL,
      amountClosed: formatToBRL,
      amountPaid: formatToBRL,
    };

    try {
      const { items, visibleCols } = getExportData(
        tableRef.current?.getData(),
        columns,
        {
          formatting,
          validateColumns: true,
        },
      );

      const data = items.map(item => item.filter(Boolean));
      const headers = visibleCols.map(column => column.label);

      return { data, headers };
    } catch {
      return;
    }
  };

  const onExportPDF = async () => {
    const id = toast.loading(GETTING_PDF);

    const { data, headers } = handleExportData();

    const description =
      (searchHistory.vehiclePlate
        ? 'Placa: ' + searchHistory.vehiclePlate + '\n'
        : '') +
      (searchHistory.serviceProviderName
        ? 'Prestador de Serviço: ' + searchHistory.serviceProviderName + '\n'
        : '') +
      'Status: ' +
      (searchHistory.statusPayment === true
        ? 'Finalizado'
        : searchHistory.statusPayment === false
          ? 'Em aberto'
          : 'Em aberto e Finalizado') +
      '\n' +
      'Período: ' +
      defaultDateFormat(searchHistory.startDate) +
      ' a ' +
      defaultDateFormat(searchHistory.endDate) +
      '\nProcessado em: ' +
      defaultDateFormat(new Date()) +
      '\nTotal de registros: ' +
      data.length;

    const exportData = {
      headers,
      title: 'Relatório Financeiro - Pagamentos',
      description,
      data,
    };

    exportPDF(
      exportData,
      `${exportData.title} - ${exportDateFormat(
        searchHistory.startDate,
      )} - ${exportDateFormat(searchHistory.endDate)}`,
    )
      .then(() =>
        toast.update(id, {
          render: PDF_READY,
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        }),
      )
      .catch(() => {
        toast.update(id, {
          render: PDF_ERROR,
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      });
  };

  const onExportXLS = () => {
    const id = toast.loading(GETTING_XLS);

    const { data, headers } = handleExportData();

    const exportData = {
      headers,
      title: 'Relatório Financeiro - Pagamentos',
      data,
    };

    exportXLS(
      exportData,
      `${exportData.title} - ${exportDateFormat(
        searchHistory.startDate,
      )} - ${exportDateFormat(searchHistory.endDate)}`,
    )
      .then(() =>
        toast.update(id, {
          render: XLS_READY,
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        }),
      )
      .catch(() => {
        toast.update(id, {
          render: XLS_ERROR,
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      });
  };

  useLayoutEffect(() => {
    const fetchData = async () => {
      await getPayments();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isLoading) return;

    const intervalId = setInterval(async () => {
      await getPayments();
      if (!document.hidden) {
        toast.success('Buscando informações automaticamente');
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [isLoading]);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={['Financeiro', 'Pagamentos']}
        onExportPDF={paymentsList.length > 0 && onExportPDF}
        onExportXLS={paymentsList.length > 0 && onExportXLS}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <FilterTool
        getDates={setDates}
        useColumnFilter={true}
        disableFilter={paymentsList.length === 0}
        initialDates={getInitialDates({
          months: 1,
        })}
        inputs={inputs}
        defaultColumns={columns}
        disableSearch={false}
        onSubmit={getPayments}
        onFilterColumns={setColumns}
        customPreSelection={[
          {
            label: 'Semana atual',
            startDate: startOfWeek(new Date()),
            endDate: endOfWeek(new Date()),
          },
          {
            label: 'Últimas duas semanas',
            startDate: startOfWeek(subWeeks(new Date(), 2)),
            endDate: endOfWeek(new Date()),
          },
          {
            label: 'Mês atual',
            startDate: startOfMonth(new Date()),
            endDate: new Date(),
          },
          {
            label: 'Mês passado',
            startDate: startOfMonth(subMonths(new Date(), 1)),
            endDate: endOfMonth(subMonths(new Date(), 1)),
          },
          {
            label: 'Últimos 3 Meses',
            startDate: subMonths(new Date(), 3),
            endDate: new Date(),
          },
        ]}
      />

      {paymentsList.length > 0 && (
        <Table
          ref={tableRef}
          columns={columns}
          data={dataTable}
          useActionRow
          onRowClick={item => {
            if (!isLoading) {
              navigate(`${subroutes.payments.path}/${item?.occurrenceId}`);
            }
          }}
        />
      )}
    </PageContainer>
  );
};

export default List;
