import { useState, useCallback, useMemo, useLayoutEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { IMaskInput } from 'react-imask';

import PageContainer from 'components/ui-kit/PageContainer';
import PageHeader from 'components/ui-kit/PageHeader';
import FilterTool from 'components/ui-kit/FilterTool';
import Table from 'components/ui-kit/Table';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import IconButton from 'components/ui-kit/IconButton';
import StatusDisplay from 'components/ui-kit/StatusDisplay';

import api from 'services/api';
import {
  BEARER,
  SUBROUTES,
  MODULES,
  UPDATE,
  CREATE,
  DELETE,
} from 'utils/constants';
import { getExportData, hasPermission } from 'utils/functions';
import Formatter from 'utils/formatter';

import {
  STANDARD_ERROR,
  NO_DATA_FOUND,
  GETTING_PDF,
  PDF_ERROR,
  PDF_READY,
  GETTING_XLS,
  XLS_ERROR,
  XLS_READY,
  SUCCESS_SERVICE_PROVIDER,
  ERROR_SERVICE_PROVIDER,
} from 'utils/messages';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

import exportXLS from 'services/xls';
import exportPDF from 'services/pdf';

import { ReactComponent as AddIcon } from 'assets/images/svg/add.svg';

const { subroutes } = SUBROUTES.register;
const { main, sub } = MODULES.register;

const ActionsContainer = styled.div`
  ${tw`flex flex-row p-2 h-fit w-fit gap-2`}
`;

const defaultColumns = [
  { label: 'Nome Empresarial', field: 'businessName' },
  { label: 'CNPJ', field: 'businessTaxId', display: 'businessTaxIdDisplay' },
  { label: 'Endereço', field: 'address' },
  {
    label: 'Cadastrado em',
    field: 'registeredIn',
    display: 'registeredInDisplay',
  },
  {
    label: 'Status',
    field: 'isActive',
    display: 'statusDisplay',
  },
  {
    label: 'Ações',
    field: 'actions',
    disableSort: true,
  },
];

const List = () => {
  const [providersList, setProvidersList] = useState([]);

  const [businessNameInput, setBusinessNameInput] = useState();
  const [businessTaxId, setBusinessTaxId] = useState();
  const [countryId, setCountryId] = useState(1);
  const [stateId, setStateId] = useState();
  const [cityId, setCityId] = useState();
  const [statusProviders, setStatusProviders] = useState();

  const stateSelectRef = useRef(null);
  const citySelectRef = useRef(null);

  const [columns, setColumns] = useState(defaultColumns);
  const [isLoading, setIsLoading] = useState(false);

  const { defaultDateFormat, exportDateFormat, cnpjMask } = new Formatter();

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const { state } = useLocation();
  const navigate = useNavigate();

  const tableRef = useRef(null);

  const canCreate = hasPermission(
    user?.role,
    main,
    sub.servicesProviders,
    CREATE,
  );
  const canEdit = hasPermission(
    user?.role,
    main,
    sub.servicesProviders,
    UPDATE,
  );
  const canDelete = hasPermission(
    user?.role,
    main,
    sub.servicesProviders,
    DELETE,
  );

  const inputs = [
    {
      onChange: e => setBusinessNameInput(e.target.value),
      value: businessNameInput,
      placeholder: 'Buscar por nome empresarial',
      size: 'sm',
      height: '33px',
      maxWidth: '300px',
      $variant: 'registry',
    },
    {
      onAccept: value => setBusinessTaxId(value),
      as: IMaskInput,
      unmask: true,
      value: businessTaxId,
      mask: '00.000.000/0000-00',
      placeholder: 'CNPJ',
      size: 'sm',
      height: '33px',
      maxWidth: '200px',
      $variant: 'registry',
    },
    {
      type: 'select',
      maxWidth: '200px',
      fieldToOptionValue: 'id',
      fieldToOptionLabel: 'name',
      endpoint: 'georef/countries',
      placeholder: 'País',
      initialValue: {
        label: 'Brasil',
        value: 1,
      },
      onOptionChange: option => {
        if (stateId) {
          stateSelectRef.current?.clearValue();
        }
        setCountryId(option?.value);
      },
    },
    {
      type: 'select',
      ref: stateSelectRef,
      maxWidth: '250px',
      fieldToOptionValue: 'id',
      fieldToOptionLabel: 'name',
      endpoint: `georef/states/?country_id=${countryId}`,
      placeholder: 'Estado',
      onOptionChange: option => {
        if (cityId) {
          citySelectRef.current?.clearValue();
        }
        setStateId(option?.value);
      },
      isDisabled: !countryId,
    },
    {
      type: 'select',
      ref: citySelectRef,
      maxWidth: '300px',
      fieldToOptionValue: 'id',
      fieldToOptionLabel: 'name',
      endpoint: `georef/cities/?uf_id=${stateId}`,
      placeholder: 'Cidade',
      onOptionChange: option => {
        setCityId(option?.value);
      },
      isDisabled: !stateId,
    },
    {
      type: 'select',
      placeholder: 'Status',
      options: [
        {
          label: 'Ativos',
          value: true,
        },
        {
          label: 'Inativos',
          value: false,
        },
      ],
      onOptionChange: option => setStatusProviders(option.value),
      size: 'sm',
      maxWidth: '200px',
    },
  ];

  const getServicesProviders = useCallback(async () => {
    setIsLoading(true);

    setProvidersList([]);

    const formatParam = value => {
      if (!value) return undefined;

      return value.trim();
    };

    try {
      const { data: response, status } = await api.get('tows/', {
        onProgress,
        headers: {
          Authorization: BEARER + user.token,
        },
        params: {
          name: formatParam(businessNameInput),
          gov_registry: formatParam(businessTaxId),
          country_id: countryId,
          uf_id: stateId,
          city_id: cityId,
          active: statusProviders,
        },
      });

      if (status !== 200) throw new Error();

      const servicesProvidersLog =
        response?.data?.map(item => ({
          id: item?.id,
          businessName: item?.name,
          businessTaxId: item?.gov_registry,
          address: `${item?.locality?.address ? item.locality.address + ', ' : ''}${item?.locality?.complement ? item.locality.complement + ', ' : ''}${item?.locality?.place_number !== null ? item.locality.place_number + ', ' : ''}${item?.locality?.neighborhood ? item.locality.neighborhood + ', ' : ''}${item?.locality?.city?.name ? item.locality.city.name + ' / ' : ''}${item?.locality?.city?.state?.short_code ? item.locality.city.state.short_code : ''}`,
          registeredIn: item?.created_on,
          isActive: item?.active || false,
        })) || [];

      setProvidersList(servicesProvidersLog);
    } catch (error) {
      let errorMessage;
      switch (error.response?.status) {
        case 404:
          errorMessage = NO_DATA_FOUND;
          break;
        default:
          errorMessage = STANDARD_ERROR;
      }
      toast.error(errorMessage);
    } finally {
      resetProgress();
      setIsLoading(false);
    }
  }, [
    businessNameInput,
    businessTaxId,
    countryId,
    stateId,
    cityId,
    statusProviders,
    state,
  ]);

  const updateStatus = async item => {
    setIsLoading(true);
    const config = {
      autoclose: 600,
    };

    try {
      if (item.isActive) {
        const { status } = await api.delete(`tows/${item.id}`, {
          headers: {
            Authorization: BEARER + user.token,
          },
        });

        if (status !== 200) throw new Error();

        toast.success(SUCCESS_SERVICE_PROVIDER('desativado'), config);
      } else {
        const { status } = await api.put(
          `tows/${item.id}`,
          {
            data: { active: true },
          },
          {
            headers: {
              Authorization: BEARER + user.token,
            },
          },
        );

        if (status !== 200) throw new Error();

        toast.success(SUCCESS_SERVICE_PROVIDER('ativado'), config);
      }

      const updatedList = [...providersList];
      const index = providersList.findIndex(data => data.id === item.id);

      updatedList[index].isActive = !updatedList[index].isActive;

      setProvidersList(updatedList);
    } catch {
      toast.error(ERROR_SERVICE_PROVIDER('alterar'), config);
    } finally {
      setIsLoading(false);
    }
  };

  const tableData = useMemo(() => {
    return providersList.map(item => ({
      ...item,
      businessName: item?.businessName || '-',
      businessTaxId: item?.businessTaxId ? item.businessTaxId : '-',
      businessTaxIdDisplay: item?.businessTaxId
        ? cnpjMask(item.businessTaxId)
        : '-',

      registeredInDisplay: defaultDateFormat(item?.registeredIn),
      isActive: item.isActive === true ? 'Ativo' : 'Inativo',
      statusDisplay: <StatusDisplay config="isActive" value={item?.isActive} />,
      actions: (
        <ActionsContainer>
          <IconButton
            type={canEdit ? 'edit' : 'view'}
            onClick={() =>
              navigate(`${subroutes.servicesProviders.path}/${item?.id}`, {
                state: { currentPage: tableRef.current?.getCurrentPage() },
              })
            }
          />

          {item.isActive && canDelete ? (
            <IconButton type="deactivate" onClick={() => updateStatus(item)} />
          ) : (
            !item.isActive &&
            canEdit && (
              <IconButton type="activate" onClick={() => updateStatus(item)} />
            )
          )}
        </ActionsContainer>
      ),
    }));
  }, [providersList, tableRef]);

  const handleExportData = () => {
    const cols = columns.filter(col => col.field !== 'actions');
    try {
      const { items, visibleCols } = getExportData(
        tableRef.current?.getData(),
        cols,
        {
          formatting: {
            businessTaxId: cnpjMask,
            registeredIn: defaultDateFormat,
          },
          validateColumns: true,
        },
      );

      const data = items.map(item => item.filter(Boolean));
      const headers = visibleCols.map(column => column.label);

      return { data, headers };
    } catch {
      return;
    }
  };

  const onExportPDF = async () => {
    const id = toast.loading(GETTING_PDF);

    const { data, headers } = handleExportData();

    const description =
      'Processado em: ' +
      defaultDateFormat(new Date()) +
      '\n' +
      'Total de registros: ' +
      data.length;

    const exportData = {
      title: 'Cadastros de Prestadores de Serviço',
      description,
      headers,
      data,
    };

    try {
      const { status } = await exportPDF(
        exportData,
        `${exportData.title} - ${exportDateFormat(new Date())}`,
      );

      if (status !== 200) throw new Error();

      toast.update(id, {
        render: PDF_READY,
        type: 'success',
        isLoading: false,
        autoClose: 1500,
      });
    } catch {
      toast.update(id, {
        render: PDF_ERROR,
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
    }
  };

  const onExportXLS = async () => {
    const id = toast.loading(GETTING_XLS);

    const { data, headers } = handleExportData();

    const exportData = {
      title: 'Cadastros de Prestadores de Serviço',
      headers,
      data,
    };

    try {
      const { status } = await exportXLS(
        exportData,
        `${exportData.title} - ${exportDateFormat(new Date())}`,
      );

      if (status !== 200) throw new Error();

      toast.update(id, {
        render: XLS_READY,
        type: 'success',
        isLoading: false,
        autoClose: 1500,
      });
    } catch {
      toast.update(id, {
        render: XLS_ERROR,
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
    }
  };

  useLayoutEffect(() => {
    const fetchServicesProviders = async () => {
      await getServicesProviders();
    };

    fetchServicesProviders();
  }, []);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={['Cadastros', 'Prestadores de Serviço']}
        onExportXLS={providersList.length > 0 && onExportXLS}
        onExportPDF={providersList.length > 0 && onExportPDF}
        addRedirects={[
          ...(canCreate
            ? [
                {
                  title: 'Novo Prestador de Serviço',
                  disabled: isLoading,
                  path: subroutes.servicesProviders.subroutes.new.path,
                  icon: <AddIcon />,
                  state: {
                    field: 'currentPage',
                    getValue: () => tableRef.current?.getCurrentPage(),
                  },
                },
              ]
            : []),
        ]}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <FilterTool
        inputs={inputs}
        useColumnFilter={true}
        defaultColumns={columns}
        disableSearch={false}
        useDatePicker={false}
        disableInputs={true}
        onSubmit={getServicesProviders}
        onFilterColumns={setColumns}
      />
      {providersList.length > 0 && (
        <Table
          ref={tableRef}
          columns={columns}
          data={tableData}
          startPage={state?.currentPage}
        />
      )}
    </PageContainer>
  );
};

export default List;
