import { useState, useMemo, useCallback, useEffect, memo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { AnimatePresence, motion } from 'framer-motion';
import { NavLink, useLocation } from 'react-router-dom';

import { routes } from 'routes/Menu';
import { DEV_ID, ROUTES } from 'utils/constants';
import useAuth from 'hooks/useAuth';

import { ReactComponent as DropIcon } from 'assets/images/svg/arrow_down.svg';
import { ReactComponent as LogoutIcon } from 'assets/images/svg/logout.svg';
import { ReactComponent as UserIcon } from 'assets/images/svg/user-fill.svg';

const Sidebar = styled(motion.nav)`
  ${tw`flex flex-col bg-[var(--dark-blue-theme)] absolute left-0 overflow-y-auto overflow-x-hidden pt-20 p-2 -z-10
        w-full md:max-w-[350px] h-screen`}
`;

const LinkContainer = styled.div`
  ${tw`flex flex-row h-fit w-full justify-between items-center select-none`}
`;

const Link = styled(NavLink)`
  ${tw`flex flex-row h-fit w-full justify-start items-center py-2 gap-2 cursor-pointer transition ease-in-out hover:-translate-y-1
  text-base font-bold md:text-sm hover:text-[var(--light-cyan-theme)] hover:fill-[var(--light-cyan-theme)]`};
  ${props =>
    props.$isActive
      ? tw`fill-[var(--light-cyan-theme)] text-[var(--light-cyan-theme)]`
      : tw`fill-white text-white`};

  .main-icon {
    ${tw`w-[25px] h-[25px]`}
  }
  .sub-icon {
    ${tw`w-[20px] h-[20px]`}
  }
`;

const DropDownButton = styled(DropIcon)`
  ${tw`cursor-pointer h-[20px] w-[20px]`}
  ${props =>
    props.$isActive ? tw`fill-[var(--light-cyan-theme)]` : tw`fill-white`};
`;

const RoutesContainer = styled.div`
  ${tw`flex flex-col w-full h-full px-2 sm:px-4 overflow-x-hidden overflow-y-auto gap-4 pb-20 lg:pb-0 pt-4 md:pt-10`}

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--dark-blue-theme);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
`;

const SubRoutesContainer = styled(motion.div)`
  ${tw`flex flex-col w-full h-fit pl-10 gap-1 text-sm`}
`;

const SearchInput = styled.input`
  ${tw`h-8 w-full outline-none border-solid border-b-[1px] border-white bg-transparent px-2
  text-base text-white font-normal`}

  ::placeholder {
    ${tw`text-gray-200`}
  }
`;

const UserContainer = styled.div`
  ${tw`flex flex-row w-full h-fit px-2 sm:px-4 bg-[var(--dark-blue-theme)] items-center justify-around lg:hidden`}

  .text {
    ${tw`flex flex-col h-full w-full justify-center items-start text-white text-center font-normal`}
    span {
      ${tw`font-bold truncate [max-width: 100px]`}
    }
  }

  .actions {
    ${tw`flex flex-row justify-between items-center gap-4`}
  }
`;

const filterModules = (modules, roleId) => {
  if (roleId === DEV_ID) {
    return modules;
  }

  return (
    modules
      ?.filter(module => module.isActive || module.unrestricted)
      ?.map(module => ({
        ...module,
        submodules:
          module.submodules
            ?.filter(submodule => submodule.isActive)
            ?.map(submodule => ({
              ...submodule,
              permissions: submodule.permissions.visualize
                ? submodule.permissions
                : {},
            })) || [],
        permissions: module.permissions.visualize ? module.permissions : {},
      })) || []
  );
};

const filterByPermissions = (routes, permissions) => {
  return routes.filter(route => {
    const routePermission = permissions.find(
      p => p.identifier === route.identifier,
    );

    const isUnrestricted = route.unrestricted;

    if (
      isUnrestricted ||
      (routePermission && routePermission.permissions.visualize)
    ) {
      if (route.subroutes) {
        route.subroutes = route.subroutes.filter(subroute => {
          const subroutePermission = routePermission?.submodules.find(
            p => p.identifier === subroute.identifier,
          );
          return (
            isUnrestricted ||
            (subroutePermission && subroutePermission.permissions.visualize)
          );
        });
      }
      return true;
    }
    return false;
  });
};

const filterRoutes = (routes, searchTerm, permissions) => {
  if (!searchTerm) {
    return filterByPermissions(routes, permissions);
  }

  let filteredRoutes = [];

  routes.forEach(route => {
    const isUnrestricted = route.unrestricted;

    const routePermission = permissions.find(
      p => p.identifier === route.identifier,
    );

    if (
      isUnrestricted ||
      (routePermission && routePermission.permissions.visualize)
    ) {
      const isRouteNameMatch = route.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      if (isRouteNameMatch || isUnrestricted) {
        filteredRoutes.push(route);
      }

      if (route.subroutes) {
        const matchingSubroutes = route.subroutes.filter(subroute => {
          const subroutePermission = routePermission?.submodules.find(
            p => p.identifier === subroute.identifier,
          );
          return (
            isUnrestricted ||
            (subroutePermission &&
              subroutePermission.permissions.visualize &&
              subroute.name.toLowerCase().includes(searchTerm.toLowerCase()))
          );
        });

        if (matchingSubroutes.length > 0) {
          filteredRoutes.push({
            ...route,
            subroutes: matchingSubroutes,
          });
        }
      }
    }
  });

  return filteredRoutes;
};

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 60,
      restDelta: 2,
    },
    display: 'flex',
  }),
  closed: {
    clipPath: 'circle(30px at 40px 40px)',
    opacity: 0,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
    transitionEnd: {
      display: 'none',
    },
  },
};

const subRoutesVariants = {
  open: { opacity: 1, height: 'auto', x: 0 },
  closed: { opacity: 0, height: 0, x: '-100%' },
};

const Menu = ({ isOpen, setIsOpen }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRoute, setExpandedRoute] = useState(null);

  const { pathname } = useLocation();
  const { user, logout } = useAuth();

  const toggleSubRoutes = useCallback(routeName => {
    setExpandedRoute(prevRoute => (prevRoute === routeName ? null : routeName));
  }, []);

  const handleLinkClick = useCallback(
    route => {
      if (route.subroutes) {
        toggleSubRoutes(route.name);
      } else {
        setIsOpen(false);
      }
    },
    [toggleSubRoutes],
  );

  const isActiveRoute = route => {
    if (route.path === ROUTES.HOME) {
      return pathname === ROUTES.HOME;
    }

    return pathname.startsWith(route.path);
  };

  const filteredModules = filterModules(user?.role?.modules, user?.role?.id);

  const renderedRoutes = useMemo(
    () =>
      filterRoutes(routes, searchTerm, filteredModules).map((route, index) => (
        <div key={index}>
          {route.subroutes ? (
            <LinkContainer>
              <Link
                $isActive={isActiveRoute(route)}
                onClick={event => {
                  event.stopPropagation();
                  toggleSubRoutes(route.name);
                }}>
                {route.icon}
                {route.name}
              </Link>
              <DropDownButton
                $isActive={isActiveRoute(route)}
                style={{
                  transform:
                    expandedRoute === route.name ? 'rotate(180deg)' : '',
                  transition: 'transform 0.3s',
                }}
                onClick={event => {
                  event.stopPropagation();
                  toggleSubRoutes(route.name);
                }}
              />
            </LinkContainer>
          ) : (
            <LinkContainer onClick={() => handleLinkClick(route)}>
              <Link to={route.path} $isActive={isActiveRoute(route)}>
                {route.icon}
                {route.name}
              </Link>
            </LinkContainer>
          )}

          <AnimatePresence>
            {route?.name === expandedRoute && route?.subroutes && (
              <SubRoutesContainer
                variants={subRoutesVariants}
                initial="closed"
                animate="open"
                exit="closed">
                {route.subroutes.map((subroute, subIndex) => (
                  <Link
                    key={subIndex}
                    to={subroute.path}
                    $isActive={isActiveRoute(subroute)}
                    onClick={() => setIsOpen(false)}>
                    {subroute.icon}
                    {subroute.name}
                  </Link>
                ))}
              </SubRoutesContainer>
            )}
          </AnimatePresence>
        </div>
      )),
    [user, routes, expandedRoute, searchTerm, isOpen],
  );

  useEffect(() => {
    if (!isOpen) {
      setSearchTerm('');
      setExpandedRoute(null);
    }
  }, [isOpen]);

  return (
    <Sidebar
      variants={sidebar}
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      onMouseLeave={() => {
        if (isOpen) {
          setTimeout(() => {
            setIsOpen(false);
          }, 400);
        }
      }}>
      <UserContainer>
        <div className="text">
          <p>Olá,</p>
          <span>{user?.name}</span>
        </div>

        <div className="actions">
          <NavLink to="/profile" onClick={() => setIsOpen(false)}>
            <UserIcon width={25} height={25} fill="#fff" />
          </NavLink>
          <LogoutIcon
            width={25}
            height={25}
            fill="var(--red-theme)"
            onClick={logout}
          />
        </div>
      </UserContainer>
      <RoutesContainer>
        <SearchInput
          type="text"
          placeholder="Pesquisar..."
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
        />
        {isOpen && renderedRoutes}
      </RoutesContainer>
    </Sidebar>
  );
};

export default memo(Menu);
