import { useState, useRef, useEffect, useLayoutEffect, memo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';

import Select from 'components/ui-kit/Select';
import Map from 'components/ui-kit/Map';

import {
  LOADING_ROUTE,
  ROUTE_NOT_LOADED,
  ROUTE_DISTANCE_BETWEEN_POINTS,
} from 'utils/messages';

import { marker as MarkerBase, Routing } from 'leaflet';
import 'leaflet-routing-machine';
import { IconFactory } from 'components/ui-kit/Map/Icon';

const Container = styled.div`
  ${tw`flex flex-row w-full max-w-[calc(100% - 8px)] md:max-w-[calc(100% - 24px)] h-fit justify-center absolute bottom-0 m-1 md:m-3 md:mb-16 z-[1]`}
`;

const RouteOptions = styled.div`
  ${tw`flex flex-row bg-white justify-center w-full h-fit gap-3 px-2 pt-4 pb-2 
  rounded-lg shadow-md transition-all ease-in-out`}
`;

const createMarker = (index, waypoint) => {
  const factory = new IconFactory();

  const marker = MarkerBase(waypoint.latLng, {
    draggable: false,
    bounceOnAdd: false,
    icon: factory.FlagIcon(index === 0 ? 'red' : 'green', {
      iconSize: [50, 50],
      iconAnchor: [25, 50],
    }),
  });
  return marker;
};

const handleRoutesFound = event => {
  if (!event || !event.routes || event.routes.length === 0) return;

  const waypoints = [];

  for (const route of event.routes) {
    if (!route) return;

    const summary = {
      distance: route?.summary?.totalDistance,
      time: route?.summary?.totalTime,
      name: route?.name,
    };

    waypoints.push(summary);
  }
};

const getRouteOptions = data => {
  if (data?.length === 0) return;
  const routeLogs =
    data?.locations
      ?.filter(
        item =>
          item?.origin?.position?.length > 0 &&
          item?.destiny?.position?.length > 0,
      )
      .map(item => ({
        label: `${item?.origin?.cityName || '-'} / ${item?.origin?.stateShortCode || '-'} até ${item?.destiny?.cityName || '-'} / ${item?.destiny?.stateShortCode || '-'}`,
        value: `${item?.origin?.cityId}${item?.destiny?.cityId}`,
        positions: {
          startPoint: item?.origin?.position,
          endPoint: item?.destiny?.position,
        },
      })) || [];

  return routeLogs;
};

const MapContainer = ({
  plateOptions = [],
  routingIsLoading = false,
  setRoutingIsLoading = () => {},
}) => {
  const [routeOptions, setRouteOptions] = useState([]);

  const [chosenRoute, setChosenRoute] = useState({});
  const [chosenPlateId, setChosenPlateId] = useState();
  const [routing, setRouting] = useState();

  const mapRef = useRef(null);
  const selectPlateRef = useRef(null);
  const selectRouteRef = useRef(null);

  useLayoutEffect(() => {
    if (plateOptions.length > 0) {
      const updateOptions = plateOptions?.find(
        option =>
          chosenPlateId === option?.value && option?.locations?.length > 0,
      );

      if (updateOptions) {
        setRouteOptions(getRouteOptions(updateOptions));
      }

      const firstValidOption = plateOptions?.find(option => {
        const locations = option?.locations;
        if (locations && locations.length > 0) {
          const origin = locations[0]?.origin;
          const destiny = locations[0]?.destiny;
          if (origin?.position?.length > 0 && destiny?.position?.length > 0) {
            return true;
          }
        }
        return false;
      });

      if (firstValidOption && Object.keys(chosenRoute).length === 0) {
        selectPlateRef.current?.setValue({
          value: firstValidOption?.value,
          label: firstValidOption?.label,
          autoSelection: true,
        });
        setChosenPlateId(firstValidOption?.value);

        setRouteOptions(getRouteOptions(firstValidOption));

        selectRouteRef.current?.setValue({
          value: `${firstValidOption?.locations[0]?.origin?.cityId}${firstValidOption?.locations[0]?.destiny?.cityId}`,
          label: `${firstValidOption?.locations[0]?.origin?.cityName || '-'} / ${firstValidOption?.locations[0]?.origin?.stateShortCode || '-'} até ${firstValidOption.locations[0]?.destiny?.cityName || '-'} / ${firstValidOption.locations[0]?.destiny?.stateShortCode || '-'}`,
          autoSelection: true,
        });
        setChosenRoute({
          startPoint: firstValidOption.locations[0]?.origin?.position,
          endPoint: firstValidOption.locations[0]?.destiny?.position,
        });
      }
    }
  }, [plateOptions, chosenRoute, chosenPlateId]);

  useEffect(() => {
    if (
      chosenRoute?.startPoint?.length > 0 &&
      chosenRoute?.endPoint?.length > 0 &&
      !routingIsLoading
    ) {
      if (routing) routing?.remove();

      setRoutingIsLoading(true);
      const toastId = toast.loading(LOADING_ROUTE);

      const osrm = new Routing.osrmv1({
        suppressDemoServerWarning: true,
      });

      const newRouting = new Routing.control({
        waypointMode: 'connect',
        autoRoute: true,
        addWaypoints: false,
        showAlternatives: true,
        router: osrm,
        useZoomParameter: false,
        routeWhileDragging: false,
        waypoints: [chosenRoute?.startPoint, chosenRoute?.endPoint],
        lineOptions: {
          styles: [{ color: 'blue', opacity: 0.6, weight: 4 }],
          addWaypoints: false,
        },
        altLineOptions: {
          styles: [{ color: 'orange', opacity: 0.6, weight: 4 }],
        },
        createMarker,
        defaultErrorHandler: () => {
          setRoutingIsLoading(false);
          toast.update(toastId, {
            render: ROUTE_NOT_LOADED,
            type: 'error',
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
        },
      });

      newRouting.addTo(mapRef?.current);

      newRouting.on('routesfound', event => {
        handleRoutesFound(event);
        setRoutingIsLoading(false);

        toast.update(toastId, {
          render: ROUTE_DISTANCE_BETWEEN_POINTS,
          type: 'info',
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
          closeOnClick: true,
        });
      });

      setRouting(newRouting);
    } else if (routing && !routingIsLoading) routing?.remove();
  }, [chosenRoute]);

  return (
    <>
      {plateOptions.length > 0 && (
        <Container>
          <RouteOptions>
            <Select
              ref={selectPlateRef}
              size="sm"
              maxWidth="160px"
              menuMaxHeight={64}
              options={plateOptions}
              onOptionChange={option => {
                setRouteOptions(getRouteOptions(option));
                if (option?.value) setChosenPlateId(option?.value);
                if (!!chosenRoute && Object.keys(chosenRoute).length > 0) {
                  selectRouteRef.current?.clearValue();
                }
              }}
              placeholder=""
              label={{ text: 'Placa' }}
              isClearable={false}
              isDisabled={routingIsLoading}
            />
            <Select
              ref={selectRouteRef}
              size="sm"
              maxWidth="300px"
              menuMaxHeight={64}
              options={routeOptions}
              onOptionChange={option => {
                if (option?.positions) setChosenRoute(option?.positions);
              }}
              placeholder=""
              label={{ text: 'Trajeto visível' }}
              isClearable={false}
              isDisabled={routingIsLoading}
            />
          </RouteOptions>
        </Container>
      )}
      <Map ref={mapRef} />
    </>
  );
};

export default memo(MapContainer);
