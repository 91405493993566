import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';

import styled from 'styled-components';
import tw from 'twin.macro';

import { Outlet } from 'react-router-dom';
import Header from 'components/ui-kit/Header';
import SessionExpiredModal from 'components/ui-kit/SessionExpiredModal';

import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
  ${tw`h-screen min-w-full`}
`;

const Content = styled.main`
  ${tw`w-full h-[calc(100% - 64px)] md:h-[calc(100% - 80px)] overflow-auto`}
`;

const Main = () => {
  const { isSessionExpired, onSessionExpires } = useAuth();

  useEffect(() => {
    window.addEventListener('session-expired', onSessionExpires);

    return () => {
      window.removeEventListener('session-expired', onSessionExpires);
    };
  }, []);

  return (
    <Container>
      <SessionExpiredModal isOpen={isSessionExpired} />

      <Header />

      <Content>
        <Outlet />
      </Content>
    </Container>
  );
};

export default Main;
