import { Routes, Route } from 'react-router-dom';

import Main from 'pages/Main';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import ForgotPassword from 'pages/ForgotPassword';

import Home from 'pages/Main/pages/Home';
import Occurrences from 'pages/Main/pages/Occurrences';
import Financial from 'pages/Main/pages/Financial';
import Register from 'pages/Main/pages/Register';
import Bi from 'pages/Main/pages/BI';
import Reports from 'pages/Main/pages/Reports';
import Profile from 'pages/Main/pages/Profile';

import OccurrencesRoutes from './subroutes/Occurrences';
import FinancialRoutes from './subroutes/Financial';
import RegisterRoutes from './subroutes/Register';
import ReportsRoutes from './subroutes/Reports';
import BiRoutes from './subroutes/BI';

import { ROUTES, SUBROUTES } from 'utils/constants';
import PrivateRoute from './PrivateRoute';

const { HOME, LOGIN, FORGOT_PASSWORD } = ROUTES;

const { path: OCCURRENCES } = SUBROUTES.occurrences;
const { path: FINANCIAL } = SUBROUTES.financial;
const { path: REGISTER } = SUBROUTES.register;
const { path: REPORTS } = SUBROUTES.reports;
const { path: BI } = SUBROUTES.bi;

const renderRoutes = (routes = []) => {
  return routes.map(route => {
    return <Route key={route.path} path={route.path} element={route.element} />;
  });
};

const Router = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute module="main">
            <Main />
          </PrivateRoute>
        }>
        <Route path={OCCURRENCES} element={<Occurrences />}>
          {renderRoutes(OccurrencesRoutes)}
        </Route>

        <Route path={FINANCIAL} element={<Financial />}>
          {renderRoutes(FinancialRoutes)}
        </Route>

        <Route path={REGISTER} element={<Register />}>
          {renderRoutes(RegisterRoutes)}
        </Route>

        <Route path={REPORTS} element={<Reports />}>
          {renderRoutes(ReportsRoutes)}
        </Route>

        <Route path={BI} element={<Bi />}>
          {renderRoutes(BiRoutes)}
        </Route>

        <Route path={SUBROUTES.profile.path} element={<Profile />} />

        <Route path={HOME} element={<Home />} />
      </Route>

      <Route
        path={LOGIN}
        element={
          <PrivateRoute invertValidation>
            <Login />
          </PrivateRoute>
        }
      />

      <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
