import { ReactComponent as InitialIcon } from 'assets/images/svg/home.svg';
import { ReactComponent as OccurrenceIcon } from 'assets/images/svg/winch.svg';
import { ReactComponent as FinalcialIcon } from 'assets/images/svg/financial.svg';
import { ReactComponent as PaymentsIcon } from 'assets/images/svg/payments.svg';
import { ReactComponent as BiIcon } from 'assets/images/svg/bi.svg';
import { ReactComponent as CausesIcon } from 'assets/images/svg/car_crash.svg';
import { ReactComponent as UserIcon } from 'assets/images/svg/user_fill.svg';
import { ReactComponent as RegisterIcon } from 'assets/images/svg/register.svg';
import { ReactComponent as ReportsIcon } from 'assets/images/svg/reports.svg';
import { ReactComponent as ProviderIcon } from 'assets/images/svg/service-provider.svg';
import { ReactComponent as CurrencyIcon } from 'assets/images/svg/payment.svg';
import { ReactComponent as BudgetsIcon } from 'assets/images/svg/budget.svg';
import { ReactComponent as ServicesIcon } from 'assets/images/svg/mechanic-tools.svg';
import { ReactComponent as AttendancesIcon } from 'assets/images/svg/headset.svg';
import { ReactComponent as FranchiseIcon } from 'assets/images/svg/franchise.svg';
import { ReactComponent as ModulesIcon } from 'assets/images/svg/modules.svg';
import { ReactComponent as PermissionsIcon } from 'assets/images/svg/user_shield.svg';

import { SUBROUTES, MODULES, ROUTES } from 'utils/constants';

const { occurrences, financial, register, reports, bi } = MODULES;

export const routes = [
  {
    unrestricted: true,
    name: 'Início',
    icon: <InitialIcon className="main-icon" />,
    path: ROUTES.HOME,
  },
  {
    identifier: occurrences.main,
    name: 'Ocorrências',
    icon: <OccurrenceIcon className="main-icon" />,
    path: SUBROUTES.occurrences.path,
  },
  {
    identifier: financial.main,
    name: 'Financeiro',
    icon: <FinalcialIcon className="main-icon" />,
    path: SUBROUTES.financial.path,
    subroutes: [
      {
        identifier: financial.sub.payments,
        name: 'Pagamentos',
        path: SUBROUTES.financial.subroutes.payments.path,
        icon: <PaymentsIcon className="sub-icon" />,
      },
    ],
  },
  {
    identifier: register.main,
    name: 'Cadastros',
    icon: <RegisterIcon className="main-icon" />,
    path: SUBROUTES.register.path,
    subroutes: [
      {
        identifier: register.sub.causes,
        name: 'Causas',
        path: SUBROUTES.register.subroutes.causes.path,
        icon: <CausesIcon className="sub-icon" />,
      },
      {
        identifier: register.sub.paymentMethods,
        name: 'Métodos de Pagamento',
        path: SUBROUTES.register.subroutes.paymentMethods.path,
        icon: <CurrencyIcon className="sub-icon" />,
      },
      {
        identifier: register.sub.modules,
        name: 'Módulos',
        path: SUBROUTES.register.subroutes.modules.path,
        icon: <ModulesIcon className="sub-icon" />,
      },
      {
        identifier: register.sub.permissions,
        name: 'Permissões',
        path: SUBROUTES.register.subroutes.permissions.path,
        icon: <PermissionsIcon className="sub-icon" />,
      },
      {
        identifier: register.sub.servicesProviders,
        name: 'Prestadores de Serviço',
        path: SUBROUTES.register.subroutes.servicesProviders.path,
        icon: <ProviderIcon className="sub-icon" />,
      },
      {
        identifier: register.sub.typesServicesProvided,
        name: 'Tipos de Serviços Prestados',
        path: SUBROUTES.register.subroutes.typesServicesProvided.path,
        icon: <ServicesIcon className="sub-icon" />,
      },
      {
        identifier: register.sub.users,
        name: 'Usuários',
        path: SUBROUTES.register.subroutes.users.path,
        icon: <UserIcon className="sub-icon" />,
      },
    ],
  },
  {
    identifier: reports.main,
    name: 'Relatórios',
    icon: <ReportsIcon className="main-icon" />,
    path: SUBROUTES.reports.path,
    subroutes: [
      {
        identifier: reports.sub.attendancesPerUser,
        name: 'Atendimentos por Usuário',
        path: SUBROUTES.reports.subroutes.attendancesPerUser.path,
        icon: <AttendancesIcon className="sub-icon" />,
      },
      {
        identifier: reports.sub.financial,
        name: 'Financeiro',
        path: SUBROUTES.reports.subroutes.financial.path,
        icon: <FinalcialIcon className="sub-icon" />,
      },
      {
        identifier: reports.sub.occurrences,
        name: 'Ocorrências',
        path: SUBROUTES.reports.subroutes.occurrences.path,
        icon: <OccurrenceIcon className="sub-icon" />,
      },
      {
        identifier: reports.sub.budgets,
        name: 'Orçamentos',
        path: SUBROUTES.reports.subroutes.budgets.path,
        icon: <BudgetsIcon className="sub-icon" />,
      },
      {
        identifier: reports.sub.useOfFranchise,
        name: 'Utilização de Franquia',
        path: SUBROUTES.reports.subroutes.useOfFranchise.path,
        icon: <FranchiseIcon className="sub-icon" />,
      },
    ],
  },
  {
    identifier: bi.main,
    name: 'BI',
    icon: <BiIcon className="main-icon" />,
    path: SUBROUTES.bi.path,
    subroutes: [
      {
        identifier: bi.sub.occurrences,
        name: 'Ocorrências',
        path: SUBROUTES.bi.subroutes.occurrences.path,
        icon: <OccurrenceIcon className="sub-icon" />,
      },
      {
        identifier: bi.sub.budgets,
        name: 'Orçamentos',
        path: SUBROUTES.bi.subroutes.budgets.path,
        icon: <BudgetsIcon className="sub-icon" />,
      },
    ],
  },
];
