import styled from 'styled-components';
import tw from 'twin.macro';

import { useState } from 'react';
import DefaultModal from 'components/ui-kit/DefaultModal';

import PrimaryButton from 'components/ui-kit/PrimaryButton';
import ImageCrop from './ImageCrop';

const PatternButton = styled(PrimaryButton)`
  ${tw` h-fit py-2 mr-3 last:mr-0 relative overflow-hidden cursor-pointer`}
`;

const ButtonsContent = styled.div`
  ${tw`flex flex-row gap-2 justify-center`}
`;

const BodyModal = styled.div`
  ${tw`flex flex-col gap-2 justify-center `}
`;

const ModalImage = ({ closeModal, setImageURL }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [formReady, setFormReady] = useState(false);

  setFormReady();
  setSelectedImage();
  return (
    <DefaultModal
      isOpen={true}
      onClose={closeModal}
      title="Selecione uma Foto"
      height="auto"
      maxWidth="400px">
      <BodyModal>
        <ImageCrop setImageURL={setImageURL} closeModal={closeModal} />
        <ButtonsContent>
          {formReady && selectedImage && (
            <PatternButton
              width="100px"
              onClick={() => {
                closeModal();
              }}
              disabled={!formReady}>
              Salvar
            </PatternButton>
          )}
        </ButtonsContent>
      </BodyModal>
    </DefaultModal>
  );
};
export default ModalImage;
