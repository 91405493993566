import { useRef, useState } from 'react';
// import ReactCrop, {
//   centerCrop,
//   convertToPixelCrop,
//   makeAspectCrop,
// } from 'react-image-crop';
import { BEARER } from 'utils/constants';
import api from 'services/api';
import styled from 'styled-components';
import tw from 'twin.macro';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import { toast } from 'react-toastify';
import { INVALID_IMG_FORMAT, ERROR_UPDATING_USER_PHOTO } from 'utils/messages';
import useAuth from 'hooks/useAuth';

// const ASPECT_RATIO = 1;
// const MIN_DIMENSION = 150;

const PatternButton = styled(PrimaryButton)`
  ${tw` h-fit py-2 mr-3 last:mr-0 relative overflow-hidden cursor-pointer`}
`;

const ButtonsContent = styled.div`
  ${tw`flex flex-row gap-2 justify-center`}
`;

const LableInput = styled.label`
  ${tw`block mb-3 w-fit`}
`;

const ButtonInput = styled.input`
  ${tw`block w-auto h-auto p-2 rounded-lg border-0`}
`;

// const Canvas = styled.canvas`
//   ${tw`hidden border border-black object-contain w-auto h-auto`}
// `;

const ImageCrop = ({ closeModal, setImageURL }) => {
  // const imgRef = useRef(null);
  const previewRef = useRef(null);
  // const [imgSrc, setImgSrc] = useState('');
  // const [crop, setCrop] = useState();
  const { user } = useAuth();
  const [selectedImage, setSelectedImage] = useState(null);
  const [formReady, setFormReady] = useState(false);

  const uploadImagePerfil = async file => {
    if (!user || !user.token) {
      return null;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const postImage = await api.post('/files/upload', formData, {
        headers: {
          Authorization: BEARER + user.token,
        },
      });

      if (postImage.status === 201) {
        return postImage.data.data.ids[0];
      }
    } catch (error) {
      throw error;
    }
  };

  const updateImage = async imageId => {
    if (!user || !user.token) {
      return;
    }

    const payload = {
      data: {
        image_id: imageId,
      },
    };

    try {
      const putUserResponse = await api.put(`/users/${user.id}`, payload, {
        headers: {
          Authorization: BEARER + user.token,
        },
      });

      if (putUserResponse.status === 200) {
        return true;
      }
    } catch (error) {
      throw error;
    }
  };

  const imageUpload = async () => {
    if (!formReady || !user || !user.token) {
      return;
    }

    try {
      const imageId = await uploadImagePerfil(selectedImage);

      if (imageId) {
        const isUserUpdated = await updateImage(imageId);

        if (isUserUpdated) {
          const gerUrlImage = await api.get(`/files/${imageId}`, {
            headers: {
              Authorization: BEARER + user.token,
            },
            responseType: 'arraybuffer',
          });

          if (gerUrlImage.status === 200) {
            setImageURL(gerUrlImage.request.responseURL);
          }
        }
      }
    } catch (error) {
      toast.error(ERROR_UPDATING_USER_PHOTO);
    }

    setFormReady(false);
  };

  const onSelectFile = event => {
    const file = event.target.files[0];
    if (!file) return;

    if (file) {
      setSelectedImage(file);
      setFormReady(true);
    } else {
      toast.error(INVALID_IMG_FORMAT);
      setFormReady(false);
    }
  };

  // const onImageLoad = e => {
  //   const { width, height } = e.currentTarget;
  //   const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

  //   const crop = makeAspectCrop(
  //     {
  //       unit: '%',
  //       width: cropWidthInPercent,
  //     },
  //     ASPECT_RATIO,
  //     width,
  //     height,
  //   );
  //   const centeredCrop = centerCrop(crop, width, height);
  //   setCrop(centeredCrop);
  // };

  // const setCanvasPreview = (image, canvas, crop) => {
  //   const ctx = canvas.getContext('2d');
  //   if (!ctx) throw new Error();

  //   const pixelRatio = window.devicePixelRatio;
  //   const scaleX = image.naturalWidth / image.width;
  //   const scaleY = image.naturalHeight / image.height;

  //   canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  //   canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  //   ctx.scale(pixelRatio, pixelRatio);
  //   ctx.imageSmoothingQuality = 'high';
  //   ctx.save();

  //   const cropX = crop.x * scaleX;
  //   const cropY = crop.y * scaleY;

  //   ctx.translate(-cropX, -cropY);
  //   ctx.drawImage(
  //     image,
  //     0,
  //     0,
  //     image.naturalWidth,
  //     image.naturalHeight,
  //     0,
  //     0,
  //     image.naturalWidth,
  //     image.naturalHeight,
  //   );

  //   ctx.restore();
  // };

  return (
    <>
      <LableInput>
        <ButtonInput type="file" accept="image/*" onChange={onSelectFile} />
      </LableInput>

      <div className="flex flex-col items-center">
        {/* <ReactCrop
            crop={crop}
            onChange={percentCrop => setCrop(percentCrop)}
            circularCrop
            keepSelection
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}>
            <img
              ref={imgRef}
              src={imgSrc}
              alt="Upload"
              style={{ maxHeight: '70vh' }}
              onLoad={onImageLoad}
            />
          </ReactCrop> */}
        <ButtonsContent>
          <PatternButton
            onClick={() => {
              // setCanvasPreview(
              //   imgRef.current, // HTMLImageElement
              //   previewRef.current, // HTMLCanvasElement
              //   // convertToPixelCrop(
              //   //   crop,
              //   //   imgRef.current.width,
              //   //   imgRef.current.height,
              //   // ),
              // );
              const dataUrl = previewRef.current.toDataURL();
              setImageURL(dataUrl);
            }}>
            Crop Image
          </PatternButton>
          {formReady && selectedImage && (
            <PatternButton
              width="100px"
              onClick={() => {
                imageUpload();
                closeModal();
              }}
              disabled={!formReady}>
              Salvar
            </PatternButton>
          )}
        </ButtonsContent>
      </div>

      {/* {crop && <Canvas ref={previewRef} className="mt-4" />} */}
    </>
  );
};
export default ImageCrop;
