import { Outlet } from 'react-router';

import PrivateRoute from 'routes/PrivateRoute';
import useDocumentTitle from 'hooks/useDocumentTitle';

import { MODULES, VISUALIZE } from 'utils/constants';

const { main } = MODULES.bi;

const Bi = () => {
  useDocumentTitle('BI');
  return (
    <PrivateRoute module={main} permission={VISUALIZE}>
      <Outlet />
    </PrivateRoute>
  );
};

export default Bi;
