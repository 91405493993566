import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  memo,
  useLayoutEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { areEqual } from 'react-window';

import TitleBox from 'components/ui-kit/TitleBox';
import Input from 'components/ui-kit/Input';
import Loader from 'components/ui-kit/Loader';
import FixedGrid from 'components/ui-kit/FixedGrid';
import Checkbox from 'components/ui-kit/Checkbox';

import api from 'services/api';
import { BEARER } from 'utils/constants';

import useAuth from 'hooks/useAuth';

const Container = styled.div`
  ${tw`flex flex-col w-full h-fit gap-5 justify-center items-center pt-2`}
`;

const InsertContainer = styled.div`
  ${tw`flex flex-wrap w-full h-fit items-center justify-center gap-5`}
`;

const TypesContainer = styled.div`
  ${tw`flex flex-col h-48 w-full max-w-[932px] items-center p-2 bg-[var(--gray-theme)] rounded-lg overflow-hidden`}

  .notFound {
    ${tw`h-fit w-full inset-0 m-auto
    text-sm text-center italic text-disabled-dark-contrast  truncate font-semibold`}
  }
`;

const OptionContainer = styled.div`
  ${tw`flex w-full h-full justify-start items-center py-2 px-3 gap-2
  border-solid border-[1px] border-[var(--royal-blue-theme)] rounded-lg shadow-md`}
`;

const GRID_COLUMNS = 3;

const CausesInsert = forwardRef(
  (
    {
      causesInserted = [],
      typeId,
      causesIsLoading = () => {},
      onChangingCauses = () => {},
      isDisabled = false,
    },
    ref,
  ) => {
    const [causesList, setCausesList] = useState([]);

    const [searchInput, setSearchInput] = useState('');

    const [selectedCauses, setSelectedCauses] = useState([]);
    const [causesUpdated, setCausesUpdated] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const { user } = useAuth();

    const getCauses = useCallback(async () => {
      setIsLoading(true);
      causesIsLoading(true);

      setCausesList([]);

      try {
        const { data: response, status } = await api.get(
          'occurrencies/incidents/',
          {
            headers: {
              Authorization: BEARER + user.token,
            },
            params: { category_id: typeId, active: true },
          },
        );

        if (status !== 200) throw new Error();

        const causesLog =
          response?.data?.map(item => ({
            id: item?.id,
            name: item?.name,
            typeId: item?.category?.id,
          })) || [];

        setCausesList(causesLog);
      } catch (error) {
        toast.error('Falha ao buscar Causa');
      } finally {
        setIsLoading(false);
        causesIsLoading(false);
      }
    }, [user, typeId]);

    const handleCheckboxChange = useCallback(
      cause => {
        if (isDisabled) return;

        const isAlreadySelected = selectedCauses.some(c => c.id === cause?.id);

        if (isAlreadySelected) {
          setSelectedCauses(selectedCauses.filter(c => c.id !== cause?.id));
        } else {
          setSelectedCauses([...selectedCauses, cause]);
        }
        setCausesUpdated(true);
      },
      [selectedCauses, isDisabled],
    );

    const combinedCauses = useMemo(() => {
      const filteredTypes = causesList.filter(cause =>
        cause.name.toLowerCase().includes(searchInput.toLowerCase()),
      );

      if (isDisabled) {
        return selectedCauses.filter(cause =>
          cause.name.toLowerCase().includes(searchInput.toLowerCase()),
        );
      }

      const filteredSelectedCauses = selectedCauses.filter(cause =>
        cause.name.toLowerCase().includes(searchInput.toLowerCase()),
      );

      const unselectedCauses = filteredTypes.filter(
        cause =>
          !filteredSelectedCauses.some(
            selectedCause => selectedCause.id === cause.id,
          ),
      );

      return [...filteredSelectedCauses, ...unselectedCauses];
    }, [searchInput, causesList, selectedCauses, isDisabled]);

    const renderTypes = memo(({ data, columnIndex, rowIndex, style }) => {
      const index = rowIndex * GRID_COLUMNS + columnIndex;

      if (!data[index]) return;

      return (
        <div style={{ ...style, padding: '0.5rem' }}>
          <OptionContainer>
            <Checkbox
              isDisabled={isDisabled}
              id={data[index]?.id}
              isChecked={selectedCauses?.some(
                type => type.id === data[index]?.id,
              )}
              onChange={() => handleCheckboxChange(data[index])}
              label={{ text: data[index]?.name }}
              check={{ bg: 'var(--gray-theme)', border: 'var(--gray-theme)' }}
              data-tooltip-id={String(data[index]?.id)}
              data-tooltip-content={data[index]?.name}
            />
            <Tooltip id={String(data[index]?.id)} place="bottom" />
          </OptionContainer>
        </div>
      );
    }, areEqual);

    useImperativeHandle(ref, () => ({
      clearSelectedCauses: () => setSelectedCauses([]),
    }));

    useEffect(() => {
      if (isDisabled || !causesUpdated) return;

      if (onChangingCauses) {
        const selectedTypeIds = selectedCauses.map(cause => cause.id);
        onChangingCauses(selectedTypeIds);
      }

      setCausesUpdated(false);
    }, [selectedCauses, isDisabled, onChangingCauses, causesUpdated]);

    useLayoutEffect(() => {
      if (isDisabled || !typeId) return;

      const fetchGetCauses = async () => {
        await getCauses();
      };

      fetchGetCauses();
    }, [isDisabled, typeId]);

    useEffect(() => {
      if (!causesInserted.length) return;
      setSelectedCauses(causesInserted);
    }, [causesInserted]);

    return (
      <TitleBox title={{ text: 'Causa(s)' }}>
        <Container>
          <InsertContainer>
            <Input
              id="search-input"
              maxWidth="300px"
              placeholder=""
              label={{ text: 'Buscar por nome' }}
              value={searchInput}
              onChange={e => setSearchInput(e.target.value)}
            />
          </InsertContainer>
          <TypesContainer>
            {combinedCauses.length > 0 ? (
              <FixedGrid
                items={combinedCauses}
                columnCount={
                  !isDisabled && combinedCauses.length + 1 >= GRID_COLUMNS
                    ? GRID_COLUMNS
                    : combinedCauses.length
                }
                columnWidth={300}
                rowHeight={60}
                rowCount={
                  !isDisabled
                    ? (combinedCauses.length + 1) / GRID_COLUMNS
                    : combinedCauses.length + 1
                }>
                {renderTypes}
              </FixedGrid>
            ) : isLoading ? (
              <Loader center />
            ) : (
              <span className="notFound">Nenhuma causa encontrada</span>
            )}
          </TypesContainer>
        </Container>
      </TitleBox>
    );
  },
);

export default memo(CausesInsert);
