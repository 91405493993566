import tw from 'twin.macro';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import PrimaryButton from 'components/ui-kit/PrimaryButton';

import LOGO from 'assets/logos/abc_logo_clean.png';
import { ReactComponent as TruckBase } from 'assets/images/svg/truck_base.svg';
import { ReactComponent as TruckCabin } from 'assets/images/svg/truck_cabin.svg';
import { ReactComponent as TruckWindow } from 'assets/images/svg/truck_window.svg';

import { ROUTES } from 'utils/constants';
import useDocumentTitle from 'hooks/useDocumentTitle';

import { variant } from 'utils/functions';

const { LOGIN } = ROUTES;

const Container = styled(motion.div)`
  ${tw`w-screen h-screen flex justify-center bg-[linear-gradient(#091C27, #0A253C)] absolute overflow-hidden`}
`;

const Logo = styled.img`
  ${tw`w-full max-w-lg h-fit`}
`;

const LogoWrapper = styled.div`
  ${tw`flex w-full items-center justify-center`}
`;

const CentralWrapper = styled.div`
  ${tw`flex flex-col w-full max-w-3xl h-full bg-black/30`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-col w-full h-full items-center justify-center lg:justify-center gap-8 p-4`}
`;

const Text = styled.span`
  ${tw`text-sm text-center text-white font-semibold`}

  ${({ $variant }) =>
    variant({
      title: tw`text-4xl font-bold leading-9`,
    })({ $variant })}
`;

const TruckContainer = styled.div`
  ${tw`hidden lg:block min-w-[675px] min-h-[240px] pt-12 pr-[115px] relative overflow-hidden`}
`;

const TruckBack = styled.div`
  ${tw`flex flex-col justify-center items-center w-[35rem] h-[12rem] relative bottom-[50px] z-[1] left-[115px] bg-blue-700`}
`;

const Wheel = styled(motion.div)`
  ${tw`w-full h-full flex items-center justify-center p-1 rounded-circle bg-gray-500 z-[1]`}
`;

const InsideWheel = styled.div`
  ${tw`w-[7px] h-[7px] absolute rounded-circle bg-black`}
`;

const Axle = styled.div`
  ${tw`absolute w-full left-[50px] bottom-0 h-fit`}
`;

const Tire = styled.div`
  ${tw`absolute h-[55px] w-[55px] z-[1] bottom-0 bg-gray-800 rounded-circle p-2`}
  ${props => props.$first && tw`left-[10px]`}
  ${props => props.$second && tw`left-[150px]`}
  ${props => props.$third && tw`left-[400px]`}
  ${props => props.$fourth && tw`left-[500px]`}
`;

const Wheels = ({ $first, $second, $third, $fourth }) => {
  return (
    <Tire $first={$first} $second={$second} $third={$third} $fourth={$fourth}>
      <Wheel
        animate={{ rotate: -181 }}
        transition={{
          duration: 0.8,
          ease: 'easeOut',
          times: 5,
          repeat: Infinity,
        }}>
        <InsideWheel />
        <InsideWheel style={{ top: 11, left: 4 }} />
        <InsideWheel style={{ bottom: 11, right: 4 }} />
        <InsideWheel />
      </Wheel>
    </Tire>
  );
};

const truck = (
  <TruckContainer>
    <TruckBack>
      <Text style={{ marginRight: 'calc(100% - 75%)' }}>Erro...</Text>
      <Text $variant="title" style={{ fontSize: '4rem' }}>
        404
      </Text>
    </TruckBack>

    <TruckCabin style={{ position: 'absolute', bottom: 35, left: 0 }} />
    <TruckWindow style={{ position: 'absolute', top: 40, left: 25 }} />
    <TruckBase
      style={{ position: 'absolute', bottom: 25, left: 52, zIndex: 1 }}
    />
    <Axle>
      <Wheels $first />
      <Wheels $second />
      <Wheels $third />
      <Wheels $fourth />
    </Axle>
  </TruckContainer>
);

const NotFound = () => {
  const navigate = useNavigate();

  useDocumentTitle('404');

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}>
      <CentralWrapper>
        <LogoWrapper>
          <Logo src={LOGO} />
        </LogoWrapper>

        <Wrapper>
          {truck}

          <Text $variant="title">Página Não Encontrada</Text>

          <Text>
            A página que você busca atualmente não foi encontrada.
            <br />
            Verifique a URL ou retorne à página inicial.
          </Text>

          <PrimaryButton onClick={() => navigate(LOGIN)}>
            Página Inicial
          </PrimaryButton>
        </Wrapper>
      </CentralWrapper>
    </Container>
  );
};

export default NotFound;
