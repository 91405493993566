import { useRef, useLayoutEffect } from 'react';

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useLayoutEffect(() => {
    document.title = `${title} - ABC`;
  }, [title]);

  useLayoutEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [],
  );
}

export default useDocumentTitle;
