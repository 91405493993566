import L from 'leaflet';

const baseSettings = {
  iconSize: [30, 30],
  iconAnchor: [15, 15],
  popupAnchor: [0, 0],
  className: '',
};

export class IconFactory {
  constructor(defaultSettings = baseSettings) {
    this.BASE64_PREFIX = 'data:image/svg+xml;base64,';
    this.defaultSettings = defaultSettings;
  }

  FlagIcon(fill = '', settings = baseSettings) {
    return new L.icon({
      ...settings,
      iconUrl: `${this.BASE64_PREFIX}
      ${btoa(`<?xml version="1.0" encoding="UTF-8"?>
      <svg fill="${fill}" width="800px" height="800px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path fill="#FFFFFF" d="M7.5,0C5.0676,0,2.2297,1.4865,2.2297,5.2703
        C2.2297,7.8378,6.2838,13.5135,7.5,15c1.0811-1.4865,5.2703-7.027,5.2703-9.7297C12.7703,1.4865,9.9324,0,7.5,0z"/>
        <circle cx="7.5" cy="5.2703" r="4.8"/>
        <g fill="#FFFFFF" transform="translate(2.5 0.19) scale(0.45)">
          <path d="M18.383 4.318c-.374-.155-.804-.069-1.09.217-1.264 1.263-3.321 1.264-4.586 0-2.045-2.043-5.37-2.043-7.414 0-.188.187-.293.442-.293.707v13c0 .552.447 1 1 1s1-.448 1-1v-4.553c1.271-.997 3.121-.911 4.293.26 2.045 2.043 5.371 2.043 7.414 0 .188-.188.293-.442.293-.707v-8c0-.405-.244-.769-.617-.924z"/>
        </g>
      </svg>`)}`,
    });
  }

  StartIcon(fill = '', settings = baseSettings) {
    return new L.icon({
      ...settings,
      iconUrl: `${this.BASE64_PREFIX}
      ${btoa(`<?xml version="1.0" encoding="UTF-8" standalone="no"?>
      <svg fill="${fill}" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 512 512">
        <path d="M256 0C161.07 0 84.785 76.284 84.785 171.215 84.785 305.135 256 418.713 256 418.713s171.215-113.578 171.215-247.498C427.215 77.979 350.93 0 256 0Zm0 35.6c76.284 0 139.006 62.722 139.006 139.005 0 91.54-100.017 176.3-139.006 205.118-37.294-28.819-139.006-113.577-139.006-205.118C116.994 98.322 179.716 35.6 256 35.6Zm0 79.673c-32.209 0-57.637 25.429-57.637 57.637 0 32.209 25.428 57.637 57.637 57.637s57.637-25.428 57.637-57.637c0-32.208-25.428-57.637-57.637-57.637zm0 305.54a104.72 45.594 0 0 0-104.72 45.593A104.72 45.594 0 0 0 256 512a104.72 45.594 0 0 0 104.72-45.594A104.72 45.594 0 0 0 256 420.812Z"/>
      </svg>`)}`,
    });
  }

  WinchIcon(fill = '', settings = baseSettings) {
    return new L.icon({
      ...settings,
      iconUrl: `${this.BASE64_PREFIX}
      ${btoa(`<?xml version="1.0" encoding="UTF-8" standalone="no"?>
      <svg fill="${fill}" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 512 512">
        <g transform="translate(-214.365 -482.587)">
          <path fill="#fff" d="M470.367 487.923c-108.827 0-197.05 88.222-197.05 197.05 0 48.584 17.664 92.985 46.813 127.343L470.367 989.25 620.6 812.311c29.15-34.353 46.813-78.76 46.813-127.344.005-108.822-88.217-197.044-197.045-197.044Zm0 271.302c-44.757 0-81.037-36.28-81.037-81.037 0-44.757 36.28-81.037 81.037-81.037 44.758 0 81.037 36.28 81.037 81.037 0 44.758-36.28 81.037-81.037 81.037z"/>
          <circle cx="256" cy="204" r="171.961" transform="translate(214.365 482.587)"/>
          <path fill="#fff" d="M356.8 575.631c-.703-.042-1.397.043-2.002.256-1.405.498-2.046 1.032-5.444 
            4.466-3.754 3.79-4.127 4.394-4.11 6.69 0 1.173.09 1.582.463 2.311.25.499 18.165 22.4 39.799 
            48.66l39.336 47.769v15.033h56.949l-.053-9.216c-.053-9.109-.054-9.216-.445-10.07-.231-.463-.658-1.138-.96-1.494-.321-.356-27.559-23.769-60.544-52.039-44.655-38.286-60.222-51.523-60.934-51.86a5.817 
            5.817 0 0 0-2.056-.506zm-5.762 38.54c-.192.206-.205 4-.172 23.931l.054 24.196.462.997c.943 1.993 2.58 3.078 5.142 3.363 
            1.726.195 3.06.764 4.003 1.69 2.153 2.17 2.153 5.835 0 8.005-2.17 2.153-5.836 2.153-8.006 
            0-.765-.782-1.458-2.153-1.619-3.238l-.107-.765h-11.35v.73c0 1.263.712 4.288 1.37 5.854.907 2.117 2.028 3.77 
            3.682 5.443 2.473 2.455 5.337 3.985 8.771 4.679 2.277.48 4.235.48 6.512 0 3.434-.694 6.298-2.224 8.77-4.68 
            2.474-2.49 3.986-5.319 4.697-8.824.659-3.184.268-6.92-1.031-9.874-1.762-4.003-5.142-7.4-8.967-9.001l-1.032-.428v-14.322l-.018-14.321-5.426-6.583c-2.989-3.63-5.55-6.708-5.693-6.85-.015-.014-.029-.016-.042-.002zm182.596 12.705c-24.48-.053-25.12-.035-26.775.303-3.345.712-6.21 2.259-8.647 4.678-2.455 2.473-3.985 5.338-4.679 8.772-.374 1.797-.373 
            1.903-.373 65.15v63.355h23.271l.302 1.387a34.48 34.48 0 0 0 11.849 19.376c9.02 7.365 21.207 9.59 32.273 5.906 11.44-3.807 
            20.015-13.415 22.594-25.299l.303-1.37h6.672c6.03 0 6.849-.035 8.468-.373 3.434-.694 6.298-2.224 8.77-4.68 2.456-2.473 
            3.987-5.337 4.699-8.789.355-1.815.355-2.028.302-31.49-.035-27.683-.071-29.764-.356-31.258-1.21-6.014-3.878-12.348-7.686-18.166-.711-1.067-6.707-9.197-13.343-18.057l-12.063-16.084-3.042-3.042c-5.604-5.587-10.3-8.504-15.923-9.91-1.512-.373-1.708-.373-26.616-.409zm46.88 34.088 6.974 9.287c3.843 5.124 7.525 10.176 8.201 11.262 2.616 4.216 4.556 
            8.913 5.374 13.023l.124.587h-57.304l-1.033-.391c-1.441-.534-3.22-2.313-3.772-3.772-.373-1.032-.39-1.068-.337-13.255l.053-12.204.463-.978c.498-1.05 1.69-2.35 2.561-2.812 1.406-.73 1.246-.73 20.318-.73zm-252.473 51.239.053 21.296.053 21.313.41 1.424c1.832 6.298 6.12 10.585 12.4 12.382 1.423.41 1.477.409 14.446.48l13.005.053.303 1.37c1.672 7.65 6.013 14.731 12.01 19.517 
            9.108 7.259 21.117 9.43 32.112 5.765 11.44-3.807 20.015-13.415 22.595-25.3l.302-1.37h46.043v-56.93h-76.857zm72.72 28.544c1.884-.048 4.386.1 5.666.366 4.448.907 8.167 2.882 11.405 5.996 3.433 3.309 5.587 7.24 6.6 
            12.061.428 2.082.428 6.459 0 8.54-1.28 6.085-4.803 11.369-9.731 14.625-4.056 2.668-7.757 3.771-12.632 3.771-3.024 0-4.626-.248-7.33-1.138-6.476-2.17-12.026-7.704-14.179-14.144-.907-2.758-1.157-4.341-1.157-7.383 0-3.025.249-4.626 1.139-7.33 2.686-8.06 10.017-14.055 18.61-15.247.422-.063.981-.1 1.61-.117zm148.022 0c1.884-.048 4.386.1 5.667.366 4.448.907 8.166 2.882 11.404 5.996 3.434 3.309 5.586 7.24 6.6 12.061.427 2.082.427 6.459 0 8.54-1.28 6.085-4.803 11.369-9.731 14.625-4.057 2.668-7.757 3.771-12.632 3.771-3.025 0-4.625-.248-7.33-1.138-6.475-2.17-12.027-7.704-14.18-14.144-.907-2.758-1.156-4.341-1.156-7.383 0-3.025.25-4.626 1.14-7.33 2.686-8.06 10.015-14.055 18.608-15.247.423-.063.982-.1 1.61-.117z"/>
        </g>
      </svg>
      `)}`,
    });
  }

  MultiWinchIcon(fill = '', settings = baseSettings) {
    return new L.icon({
      ...settings,
      iconUrl: `${this.BASE64_PREFIX}
      ${btoa(`<svg fill="${fill}" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 512 512">
      <g transform="translate(-214.365 -482.587)">
        <path d="M256.002 5.336c-108.827 0-197.049 88.222-197.049 197.049 0 48.584 17.663 92.986 46.813 127.344l150.236 176.935 150.232-176.941c29.15-34.352 46.813-78.758 46.813-127.342.005-108.822-88.218-197.045-197.045-197.045Z" style="opacity:1;fill:#fff;fill-rule:nonzero;stroke:none;stroke-width:5.57031;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none" transform="translate(214.365 482.587)"/>
        <circle cx="256" cy="204" r="171.961" style="display:inline;stroke-width:2.14952" transform="translate(214.365 482.587)"/>
        <path d="M411.272 539.351a4.271 4.271 0 0 0-1.66.211c-1.165.413-1.697.857-4.514 3.703-3.112 3.142-3.42 3.643-3.406 5.545 
        0 .974.073 1.314.383 1.918.206.413 15.06 18.57 32.996 40.34l32.61 39.602v12.464h47.214l-.045-7.64c-.044-7.552-.044-7.642-.369-8.35-.192-.383-.546-.943-.797-1.238-.265-.295-22.846-19.706-50.191-43.143-37.021-31.74-49.928-42.713-50.518-42.994a4.817 4.817 0 0 0-1.703-.418zm-4.777 31.95c-.16.17-.17 3.317-.143 19.841l.045 20.059.383.826c.782 1.652 2.14 2.551 4.264 2.787 1.43.162 2.536.636 3.318 1.403 1.785 1.799 1.785 4.837 0 6.636-1.8 1.785-4.84 1.785-6.639 0-.634-.649-1.209-1.786-1.342-2.685l-.087-.633H396.883v.605c0 1.048.591 3.554 1.137 4.852.752 1.755 1.681 3.127 3.053 4.514 2.05 2.035 4.425 3.303 7.271 3.879 1.888.398 3.51.398 5.399 0 2.846-.576 5.221-1.844 7.271-3.88 2.05-2.064 3.303-4.41 3.893-7.316.546-2.64.221-5.737-.856-8.185-1.46-3.319-4.262-6.136-7.433-7.463l-.856-.356v-11.873l-.013-11.873-4.5-5.457c-2.478-3.009-4.601-5.562-4.72-5.68-.011-.011-.024-.013-.034-.002zm151.379 10.535c-20.296-.045-20.826-.03-22.198.25-2.773.59-5.147 1.873-7.168 3.879-2.035 2.05-3.303 4.424-3.878 7.271-.31 1.49-.311 1.577-.311 54.012v18.812c1.496.008 4.958.007 5.904.022 2.956.045 5.347.393 6.078.574a10.317 10.317 0 0 1 .028.008c6.608 1.652 12.566 5.519 17.98 10.916a10.317 10.317 0 
        0 1 .012.012l2.523 2.523a10.317 10.317 0 0 1 .96 1.106l.413.55c2.895-2.871 6.694-4.854 10.881-5.435.35-.052.816-.084 1.336-.098 1.562-.04 3.636.082 4.698.303 3.687.752 6.77 2.39 9.455 4.97 2.846 2.744 4.63 6.004 5.47 10 .354 1.726.354 5.355 0 7.08-1.062 5.045-3.98 9.427-8.066 12.126-.595.391-1.178.693-1.762 1.006 1.661 2.723 3.093 5.533 4.246 8.418a28.422 28.422 0 0 0 14.698-19.233l.251-1.137h5.532c5 0 5.677-.028 7.02-.308 2.846-.575 5.22-1.844 7.27-3.88 2.036-2.05 3.305-4.425 3.895-7.286.295-1.505.296-1.682.252-26.108-.03-22.95-.059-24.675-.295-25.914-1.003-4.985-3.216-10.235-6.373-15.058-.59-.885-5.56-7.626-11.062-14.971l-10-13.334-2.522-2.523c-4.646-4.632-8.54-7.05-13.2-8.215-1.255-.31-1.418-.309-22.067-.338zm38.867 28.26 5.781 7.699c3.186 4.248 6.238 8.436 6.799 9.336 2.168 3.495 3.777 7.39 4.455 10.796l.104.487H566.372l-.858-.324c-1.194-.443-2.67-1.918-3.127-3.127-.31-.856-.323-.885-.279-10.989l.045-10.119.383-.81c.413-.87 1.4-1.947 2.123-2.33 1.165-.605 1.034-.606 16.845-.606zm-231.584 23.753a4.262 4.262 0 0 0-1.658.213c-1.166.413-1.697.855-4.514 3.701-3.112 3.142-3.421 3.645-3.406 5.547 0 .974.073 1.312.383 1.916.206.413 15.058 18.57 32.994 40.34l32.611 39.604V737.633h47.215l-.045-7.641c-.044-7.552-.045-7.64-.37-8.348-.19-.383-.545-.943-.796-1.238-.266-.295-22.846-19.706-50.191-43.143-37.022-31.74-49.928-42.715-50.518-42.996a4.827 4.827 0 0 0-1.705-.418zm39.232 18.725c6.473 5.537 10.337 8.825 19.701 16.853l.002.002c4.98 4.268 8.282 7.108 12.84 11.018a19.28 19.28 0 0 1 9.451-4.111c.35-.052.814-.084 1.334-.098 1.562-.04 3.636.082 4.698.303 3.687.752 6.77 2.39 9.455 4.97 2.846 2.744 4.632 6.003 5.472 10 .354 1.726.354 5.355 0 7.08a19.99 19.99 0 0 1-2.32 5.973l2.897 2.494c.005-2.581 0-9.78.01-11.48.019-3.42.043-5.24.1-6.582.055-1.332.3-2.927.384-3.336.942-4.63 3.336-9.116 6.66-12.465a10.317 10.317 0 0 1 .053-.055c3.33-3.305 7.714-5.672 12.29-6.646a10.317 10.317 0 0 1 .083-.018c.908-.185 2.942-.42 5.947-.455 3.004-.035 8.189-.026 18.33-.004h.008c1.732.002 
        1.64.01 3.096.012v-13.455h-63.717zm-44.008 13.227c-.159.17-.17 3.315-.142 19.84l.045 20.058.383.828c.781 1.652 2.137 2.551 
        4.261 2.787 1.431.162 2.539.634 3.32 1.4 1.785 1.8 1.785 4.838 0 6.637-1.799 1.785-4.839 1.785-6.638 0-.634-.649-1.21-1.784-1.342-2.683l-.088-.635h-9.41v.605c0 1.048.59 3.556 1.135 4.854.752 1.755 1.68 3.125 3.053 4.512 2.05 2.035 4.424 3.303 7.271 3.879 1.888.398 3.51.398 5.399 0 2.846-.576 5.22-1.844 7.271-3.88 2.05-2.064 3.305-4.41 3.895-7.316.545-2.64.22-5.737-.856-8.185-1.46-3.319-4.262-6.136-7.434-7.463l-.855-.354v-11.873l-.016-11.875-4.498-5.457c-2.478-3.009-4.6-5.56-4.718-5.678-.012-.011-.025-.013-.036-.002zm151.38 10.533c-20.296-.045-20.826-.03-22.198.25-2.773.59-5.147 1.873-7.168 3.879-2.035 2.05-3.304 4.426-3.879 7.273-.31 1.49-.31 1.577-.31 54.012v52.523h19.293l.25 1.15a28.586 28.586 0 0 0 9.824 16.063c7.478 6.106 17.582 7.95 26.756 4.897 9.484-3.157 16.592-11.122 18.73-20.975l.252-1.135h5.531c5 0 5.678-.03 7.02-.31 2.847-.576 5.221-1.844 7.271-3.88 2.036-2.05 3.305-4.425 3.895-7.286.295-1.505.296-1.68.252-26.106-.03-22.95-.059-24.675-.295-25.914-1.003-4.985-3.217-10.237-6.373-15.06-.59-.885-5.561-7.626-11.063-14.971l-10-13.334-2.521-2.522c-4.646-4.63-8.54-7.05-13.201-8.214-1.254-.31-1.417-.31-22.067-.34zm38.867 28.26 5.779 7.699c3.186 4.248 6.24 8.436 6.8 9.336 2.169 3.495 3.777 7.39 4.456 10.797l.101.486h-47.508l-.855-.322c-1.195-.443-2.67-1.918-3.127-3.127-.31-.856-.324-.887-.28-10.99l.046-10.118.382-.81c.413-.87 1.4-1.949 2.124-2.332 1.165-.605 1.032-.604 16.843-.604zm-209.313 42.478.045 17.656.043 17.67.34 1.18c1.519 5.221 5.073 8.776 10.28 10.266 1.18.339 1.225.339 11.978.398l10.78.043.25 1.137c1.387 6.342 4.987 12.212 9.958 16.18 7.552 6.017 17.508 7.817 26.623 4.779 9.484-3.157 16.594-11.122 18.732-20.975l.25-1.135h38.172v-47.199h-63.719zm60.29 23.666c1.561-.04 3.634.082 4.696.303 3.688.752 6.771 
        2.39 9.455 4.97 2.847 2.744 4.632 6.003 5.473 10 .354 1.726.354 5.355 0 7.08-1.062 5.045-3.983 9.424-8.068 12.124-3.363 2.212-6.431 3.127-10.473 3.127-2.507 0-3.834-.206-6.076-.944-5.369-1.8-9.971-6.387-11.756-11.726-.752-2.287-.959-3.6-.959-6.121 0-2.508.206-3.835.943-6.077 2.228-6.681 8.306-11.652 15.43-12.64.35-.052.814-.083 1.334-.096zm122.714 0c1.562-.04 3.637.082 4.7.303 3.687.752 6.77 2.39 9.454 4.97 2.847 2.744 4.63 6.003 5.471 10 .354 1.726.354 5.355 0 7.08-1.062 5.045-3.983 9.424-8.068 12.124-3.363 2.212-6.43 3.127-10.471 3.127-2.508 0-3.834-.206-6.076-.944-5.37-1.8-9.971-6.387-11.756-11.726-.752-2.287-.96-3.6-.96-6.121 0-2.508.207-3.835.944-6.077 2.227-6.681 8.304-11.652 15.428-12.64.35-.052.813-.083 1.334-.096z" style="fill:#fff;stroke-width:.0147496"/>
      </g>
    </svg>
    
      `)}`,
    });
  }
}
