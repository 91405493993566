import {
  useState,
  memo,
  useRef,
  useEffect,
  useLayoutEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';

import TitleBox from 'components/ui-kit/TitleBox';
import Select from 'components/ui-kit/Select';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import IconButton from 'components/ui-kit/IconButton';
import Textarea from 'components/ui-kit/Textarea';
import FileInput from 'components/ui-kit/FileInput';
import LoadingScreen from 'components/ui-kit/LoadingScreen';

import ServiceProviderModal from './ServiceProviderModal';

import api from 'services/api';
import { BEARER } from 'utils/constants';
import { FIELD_IS_REQUIRED } from 'utils/messages';
import { buildPayload, variant } from 'utils/functions';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

import Formatter from 'utils/formatter';

const Form = styled.form`
  ${tw`flex flex-col w-full h-fit gap-3 py-2 justify-center items-center`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-col lg:flex-row h-fit w-full gap-4 items-end`}

  .col {
    ${tw`flex h-full w-full max-w-full lg:max-w-[50%]`}
  }
`;

const Text = styled.span`
  ${tw`text-sm text-center text-disabled-dark leading-4`}

  ${({ $variant }) =>
    variant({
      title: tw`text-base font-bold text-[var(--royal-blue-theme)] truncate`,
      label: tw`font-semibold`,
      value: tw`truncate`,
      notFound: tw`italic text-[var(--gray-dark)] font-semibold truncate`,
    })({ $variant })}
`;

const InsertContainer = styled.div`
  ${tw`flex flex-wrap w-full gap-4 items-center justify-center`}
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-wrap gap-4 w-full justify-around h-fit items-center`}
`;

const Row = styled.div`
  ${tw`flex flex-wrap justify-center items-center h-fit w-full max-w-full gap-4 py-2 px-4 bg-[var(--gray-theme)] rounded-lg overflow-hidden`}
`;

const Item = styled.div`
  ${tw`flex h-fit w-fit justify-center items-center gap-1 overflow-hidden`}

  max-width: ${({ $maxWidth }) => $maxWidth || '100%'};
`;

const validationSchema = yup.object({
  benefitedPlates: yup
    .array(yup.object().required())
    .min(1, 'Pelo menos uma placa deve ser adicionada'),
  serviceProviderId: yup
    .string()
    .required('Prestador de Serviço deve ser adicionado'),
  serviceProviderName: yup.string().notRequired(),
  budgetValue: yup.number().required(FIELD_IS_REQUIRED('Valor Orçado')),
  closedValue: yup.number().required(FIELD_IS_REQUIRED('Valor Fechado')),
  comments: yup.string().notRequired(),
});

const keyMapping = {
  benefitedPlates: 'vehicles_id',
  serviceProviderId: 'tow_id',
  budgetValue: 'cost',
  closedValue: 'cost_final',
  comments: 'description',
};

const ServiceProviderForm = ({
  index,
  budgetType = {},
  plateOptions = [],
  budgetData = {},
  occurrenceInfo = {},
  isDisabled = false,
  deleteBudget = () => {},
  syncData = async () => {},
  serviceId,
  serviceHasChanged = false,
  startService = async () => {},
}) => {
  const [plate, setPlate] = useState({});

  const filesRef = useRef(null);
  const plateSelectRef = useRef(null);

  const [files, setFiles] = useState([]);

  const [modalData, setModalData] = useState({});

  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();

  const { formatToBRL } = new Formatter();

  const handleAddServiceValue = values => {
    setValues(prev => ({
      ...prev,
      serviceProviderId: values?.serviceProviderId,
      serviceProviderName: values?.serviceProviderName,
      budgetValue: values?.budgetValue,
      closedValue: values?.closedValue,
    }));
  };

  const handleRemoveServiceValue = () => {
    setValues(prev => ({
      ...prev,
      serviceProviderId: undefined,
      serviceProviderName: undefined,
      budgetValue: undefined,
      closedValue: undefined,
      comments: '',
    }));
    setFiles([]);
  };

  const onSubmit = async values => {
    setIsLoading(true);

    let newData = { ...budgetData };
    let newValues = { ...values };

    newValues['benefitedPlates'] = values?.benefitedPlates?.map(v => v.id);
    newData['benefitedPlates'] = budgetData?.benefitedPlates?.map(v => v.id);

    const payload = buildPayload(keyMapping, newValues, newData);

    const newFiles = files.filter(file => !file.id);
    const currentFileIds = files.reduce((ids, file) => {
      if (file.id) {
        ids.push(file.id);
      }
      return ids;
    }, []);

    let allFileIds = [...currentFileIds];
    if (newFiles.length > 0) {
      const newFileIds = await filesRef.current?.sendFiles(newFiles);
      allFileIds = [...allFileIds, ...newFileIds];
    }

    const initialFileIds =
      budgetData?.files?.map(file => ({
        id: file.id,
        name: file.name,
      })) || [];

    const removedFiles = initialFileIds.filter(
      file => !currentFileIds.includes(file.id),
    );

    if (removedFiles.length) {
      await filesRef.current?.deleteFiles(removedFiles);
    }

    const fileIdsChanged =
      JSON.stringify(budgetData?.files?.map(file => file?.id).sort()) !==
      JSON.stringify(allFileIds.sort());

    if (fileIdsChanged) {
      payload['files_ids'] = allFileIds;
    }

    if (Object.keys(payload).length === 0) {
      toast.success('Orçamento já salvo');
      setIsLoading(false);
      return;
    }

    try {
      if (budgetData?.id) {
        const { status } = await api.put(
          `/occurrencies/${occurrenceInfo?.id}/budgets/${budgetData?.id}`,
          { data: payload },
          {
            onProgress,
            headers: {
              Authorization: BEARER + user.token,
            },
          },
        );
        if (status !== 200) throw new Error();
      } else {
        payload['type_id'] = budgetType?.id;
        const { status } = await api.post(
          `/occurrencies/${occurrenceInfo?.id}/budgets`,
          { data: payload },
          {
            onProgress,
            headers: {
              Authorization: BEARER + user.token,
            },
          },
        );
        if (status !== 200) throw new Error();
      }

      toast.success('Orçamento salvo com sucesso');

      await syncData();
    } catch (error) {
      let errorMessage;
      switch (error.response?.status) {
        case 400:
          errorMessage =
            'Ocorrência Encerrada: edição de Orçamento indisponível';
          break;
        default:
          errorMessage = 'Falha ao salvar Orçamento';
      }

      toast.error(errorMessage);
    } finally {
      resetProgress();
      setIsLoading(false);
    }
  };

  const handleDeleteBudget = async () => {
    if (!budgetData?.id) {
      deleteBudget(index);
      return;
    }

    setIsLoading(true);

    if (!serviceId) await startService();
    try {
      const { status } = await api.delete(
        `/occurrencies/${occurrenceInfo?.id}/budgets/${budgetData?.id}`,
        {
          onProgress,
          headers: {
            Authorization: BEARER + user.token,
          },
        },
      );

      if (status !== 200) throw new Error();

      toast.success('Orçamento excluído com sucesso');

      deleteBudget(index);
      await syncData();
    } catch {
      toast.error('Falha ao excluir orçamento');
    } finally {
      resetProgress();
      setIsLoading(false);
    }
  };

  const {
    handleSubmit,
    setValues,
    setFieldValue,
    handleChange,
    values,
    submitCount,
    errors,
  } = useFormik({
    initialValues: {
      benefitedPlates: [],
      serviceProviderId: undefined,
      serviceProviderName: undefined,
      budgetValue: undefined,
      closedValue: undefined,
      comments: '',
    },
    onSubmit,
    validationSchema,
  });

  const handleAddBenefitedPlate = useCallback(() => {
    if (values.benefitedPlates.some(item => item?.id === plate?.id)) {
      toast.warning('Esta placa já foi adicionada');
    } else if (values.benefitedPlates.length >= 5) {
      toast.warning('Limite de veículos atingido!');
    } else {
      setFieldValue('benefitedPlates', [...values.benefitedPlates, plate]);
      plateSelectRef.current?.clearValue();
    }
  }, [values.benefitedPlates, plate, setFieldValue, plateSelectRef]);

  const handleRemoveBenefitedPlate = useCallback(
    id => {
      const newVehicles = values.benefitedPlates.filter(
        vehicle => vehicle.id !== id,
      );
      setFieldValue('benefitedPlates', newVehicles);
    },
    [setFieldValue, values.benefitedPlates],
  );

  useEffect(() => {
    const activeErrors = Object.keys(errors)
      .filter(error => errors[error] && submitCount > 0)
      .map(error => errors[error]);

    if (activeErrors.length > 0) {
      toast.error(activeErrors.join('\n'), {
        autoClose: Object.keys(errors).length * 1000,
      });
    }
  }, [submitCount]);

  useLayoutEffect(() => {
    if (Object.keys(budgetData).length === 0) return;

    setFiles(budgetData?.files);

    setValues({
      benefitedPlates: budgetData?.benefitedPlates,
      serviceProviderId: budgetData?.serviceProviderId,
      serviceProviderName: budgetData?.serviceProviderName,
      budgetValue: budgetData?.budgetValue,
      closedValue: budgetData?.closedValue,
      comments: budgetData?.comments,
    });
  }, [budgetData]);

  useEffect(() => {
    if (Object.keys(budgetData).length === 0) return;

    if (isMounted && !serviceId) {
      const payload = buildPayload(keyMapping, values, budgetData);

      if (Object.keys(payload).length > 0) startService();

      if (
        budgetData?.files &&
        JSON.stringify(files) !== JSON.stringify(budgetData?.files)
      )
        startService();
    }

    if (!isMounted) setIsMounted(true);
  }, [values, files, serviceId]);

  useEffect(() => {
    if (serviceHasChanged) setModalData({});
  }, [serviceHasChanged]);

  return (
    <TitleBox
      title={{ text: budgetType?.name }}
      error={
        submitCount > 0 &&
        (errors.benefitedPlates ||
          errors.serviceProviderId ||
          errors.budgetValue)
      }>
      <LoadingScreen isLoading={isLoading} progress={progress} />

      {!isDisabled && (
        <ServiceProviderModal
          {...modalData}
          onAddServiceValue={handleAddServiceValue}
          onClose={() => setModalData({})}
        />
      )}

      <Form onSubmit={handleSubmit}>
        {!isDisabled && (
          <InsertContainer>
            <Select
              ref={plateSelectRef}
              size="sm"
              maxWidth="200px"
              options={plateOptions}
              onOptionChange={option => {
                setPlate({
                  plate: option.label,
                  id: option.value,
                  associateId: option.associateId,
                  associateName: option.associateName,
                });
              }}
              placeholder=""
              label={{ text: 'Placa beneficiada' }}
              error={{
                isActive: submitCount > 0 && errors.benefitedPlates,
                message: 'Uma placa deve ser adicionada.',
              }}
            />

            <PrimaryButton
              type="button"
              onClick={handleAddBenefitedPlate}
              disabled={!plate?.id}>
              Adicionar Placa
            </PrimaryButton>
          </InsertContainer>
        )}

        {values.benefitedPlates.length > 0 ? (
          <>
            {values.benefitedPlates?.map(item => (
              <Row key={item?.id}>
                <Item $maxWidth="500px">
                  <Text $variant="label">Associado beneficiado:</Text>
                  <Text
                    $variant="value"
                    data-tooltip-id={String(index)}
                    data-tooltip-content={item?.associateName}>
                    {item?.associateName}
                  </Text>
                </Item>
                <Item $maxWidth="200px">
                  <Text $variant="label">Placa beneficiada:</Text>
                  <Text $variant="value">{item?.plate}</Text>
                </Item>
                {!isDisabled && (
                  <IconButton
                    type="deactivate"
                    tooltipText={'Remover'}
                    onClick={() => handleRemoveBenefitedPlate(item?.id)}
                  />
                )}
                <Tooltip id={String(index)} place="bottom" />
              </Row>
            ))}
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              height: '60px',
              alignItems: 'center',
            }}>
            <Text $variant="notFound">Nenhuma placa adicionada</Text>
          </div>
        )}

        <TitleBox
          title={{ text: 'Informações' }}
          type="line"
          style={{ margin: '0.5rem 0 0.5rem 0' }}
          error={submitCount > 0 && errors.budgetValue}
        />

        {!isDisabled && (
          <PrimaryButton
            type="button"
            onClick={() =>
              setModalData({
                isOpen: true,
                associatePays: budgetType?.id === 7 || budgetType?.id === 8,
              })
            }>
            Adicionar Prestador de Serviço
          </PrimaryButton>
        )}

        <>
          {values?.serviceProviderId ? (
            <>
              <Row>
                <Item $maxWidth="450px">
                  <Text $variant="label">Prestador de Serviço:</Text>
                  <Text
                    $variant="value"
                    data-tooltip-id={String(index)}
                    data-tooltip-content={values?.serviceProviderName}>
                    {values?.serviceProviderName}
                  </Text>
                </Item>
                <Item $maxWidth="200px">
                  <Text $variant="label">Valor Orçado:</Text>
                  <Text
                    $variant="value"
                    data-tooltip-id={String(index)}
                    data-tooltip-content={formatToBRL(values?.budgetValue)}>
                    {formatToBRL(values?.budgetValue)}
                  </Text>
                </Item>
                <Item $maxWidth="200px">
                  <Text $variant="label">Valor Fechado:</Text>
                  <Text
                    $variant="value"
                    data-tooltip-id={String(index)}
                    data-tooltip-content={formatToBRL(values?.closedValue)}>
                    {formatToBRL(values?.closedValue)}
                  </Text>
                </Item>
                {(budgetType?.id === 7 || budgetType?.id === 8) && (
                  <>
                    <Item $maxWidth="250px">
                      <Text $variant="label">Pago Associado (30%):</Text>
                      <Text
                        $variant="value"
                        data-tooltip-id={String(index)}
                        data-tooltip-content={formatToBRL(
                          values?.closedValue * 0.3,
                        )}>
                        {formatToBRL(values?.closedValue * 0.3)}
                      </Text>
                    </Item>
                    <Item $maxWidth="250px">
                      <Text $variant="label">Pago Associação (70%):</Text>
                      <Text
                        $variant="value"
                        data-tooltip-id={String(index)}
                        data-tooltip-content={formatToBRL(
                          values?.closedValue * 0.7,
                        )}>
                        {formatToBRL(values?.closedValue * 0.7)}
                      </Text>
                    </Item>
                  </>
                )}
                {!isDisabled && (
                  <IconButton
                    type="deactivate"
                    tooltipText={'Remover'}
                    onClick={handleRemoveServiceValue}
                  />
                )}
                <Tooltip id={String(index)} place="bottom" />
              </Row>
              <Wrapper>
                <div className="col">
                  <Textarea
                    id="comments"
                    placeholder="Observações do Orçamento"
                    value={values?.comments}
                    onChange={handleChange}
                    style={{ height: '100px' }}
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <FileInput
                    ref={filesRef}
                    text={{
                      title: isDisabled
                        ? 'Arquivos desse Orçamento'
                        : 'Adicione arquivos desse Orçamento aqui',
                      notFound: 'Nenhum arquivo adicionado',
                    }}
                    height="100px"
                    fileType=".xlsx, .xls, image/*, video/*, .doc, .docx, .txt, .pdf"
                    files={files}
                    setFiles={setFiles}
                    onProgress={onProgress}
                    isDisabled={isDisabled}
                  />
                </div>
              </Wrapper>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                height: '60px',
                alignItems: 'center',
              }}>
              <Text $variant="notFound">
                Nenhuma prestador de serviço adicionado
              </Text>
            </div>
          )}
        </>
        {!isDisabled && (
          <ButtonsContainer>
            <PrimaryButton
              type="button"
              bg="red"
              onClick={handleDeleteBudget}
              disabled={isLoading}>
              Excluir Orçamento
            </PrimaryButton>

            <PrimaryButton type="submit" bg="green" disabled={isLoading}>
              Salvar Orçamento
            </PrimaryButton>
          </ButtonsContainer>
        )}
      </Form>
    </TitleBox>
  );
};

export default memo(ServiceProviderForm);
