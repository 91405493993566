import { memo, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import { motion } from 'framer-motion';

import useAuth from 'hooks/useAuth';

const Container = styled(motion.div)`
  ${tw`flex h-[80px] w-[150px] absolute right-0 top-full select-none`}
`;

const List = styled(motion.ul)`
  ${tw`flex flex-col w-full h-full gap-2 p-3 bg-gray-400 rounded-md mt-3`}
`;

const Items = styled(motion.li)`
  ${tw`flex h-full w-full cursor-pointer items-center justify-start`}
`;

const Text = styled('p')`
  ${tw`text-base font-bold hover:opacity-80 truncate`}

  ${props => (props.$logout ? tw`text-[var(--red-theme)]` : tw`text-blue-700`)}
`;

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: {
    opacity: 0,
    y: 20,
    transition: { duration: 0.1 },
  },
};

const DropDownUser = memo(
  ({ isOpen = false, setIsOpen = () => {}, ...rest }) => {
    const { logout } = useAuth();

    const handleLogout = useCallback(() => {
      logout();

      setIsOpen(false);
    }, [logout, setIsOpen]);

    return (
      <Container
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        variants={{
          open: { display: 'flex' },
          closed: {
            transitionEnd: {
              display: 'none',
            },
          },
        }}
        {...rest}
        onMouseLeave={() => setIsOpen(false)}>
        <List
          variants={{
            open: {
              clipPath: 'inset(0% 0% 0% 0% round 10px)',
              transition: {
                type: 'spring',
                bounce: 0,
                duration: 0.2,
                delayChildren: 0.2,
                staggerChildren: 0.05,
              },
              display: 'flex',
            },
            closed: {
              clipPath: 'inset(10% 50% 90% 50% round 10px)',
              transition: {
                type: 'spring',
                bounce: 0,
                duration: 0.1,
              },
              transitionEnd: {
                display: 'none',
              },
            },
          }}>
          <Items variants={itemVariants}>
            <NavLink to="/profile" onClick={setIsOpen}>
              <Text>Meu Perfil</Text>
            </NavLink>
          </Items>

          <Items variants={itemVariants}>
            <Text $logout={true} onClick={handleLogout}>
              Sair
            </Text>
          </Items>
        </List>
      </Container>
    );
  },
);

export default DropDownUser;
