import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: ${props => (props.$center ? 'auto' : '')};
  height: ${props => (props.$full ? '100vh' : '')};
  width: ${props => (props.$full ? '100%' : '')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = ({ center = false, full = false, ...rest }) => {
  return (
    <Wrapper {...rest} $center={center} $full={full}>
      <Oval
        width={80}
        height={80}
        color="var(--basic-primary)"
        secondaryColor="#084A86"
      />
    </Wrapper>
  );
};

export default Loader;
