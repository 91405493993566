import { useRef, useState, memo, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import DefaultModal from 'components/ui-kit/DefaultModal';
import Select from 'components/ui-kit/Select';
import Input from 'components/ui-kit/Input';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import Table from 'components/ui-kit/Table';
import IconButton from 'components/ui-kit/IconButton';

import Formatter from 'utils/formatter';

import { variant } from 'utils/functions';

const Container = styled.div`
  ${tw`flex flex-col w-full h-fit items-center gap-5 p-2 overflow-auto`}
`;

const InsertContainer = styled.div`
  ${tw`flex flex-wrap w-full h-fit justify-center items-center gap-4`}
`;

const ServiceProviderCard = styled.div`
  ${tw`flex flex-col h-fit w-full max-w-[450px] items-center mt-4 pt-6 p-2 gap-4 bg-white border-4 border-[var(--royal-blue-theme)] rounded-xl relative 
  shadow-lg`}

  .title {
    ${tw`absolute px-3 py-1 text-base font-bold text-white bg-opacity-25 rounded-md [background: var(--blue-gradient)] 
    top-0 -translate-y-1/2`}
  }

  .content {
    ${tw`flex flex-col h-full max-h-full w-full gap-1 overflow-auto`}
  }
`;

const Row = styled.div`
  ${tw`flex flex-row w-full max-w-full gap-2 items-center`}
`;

const Item = styled.div`
  ${tw`flex h-fit w-full justify-center overflow-hidden`}
`;

const Hr = styled.hr`
  ${tw`h-px min-h-[1px] w-full border-0 bg-gray-400`}
`;

const Text = styled.span`
  ${tw`min-h-[22px] text-lg text-center font-semibold text-[var(--royal-blue-theme)] truncate max-w-full`}

  ${({ $variant }) =>
    variant({
      label: tw`text-sm font-bold`,
      value: tw`text-sm font-normal`,
      notFound: tw`text-sm font-normal`,
      notSelected: tw`text-sm italic text-[var(--gray-dark)] flex h-40 w-full max-w-full items-center justify-center`,
    })({ $variant })}
`;

const ButtonsGroup = styled.div`
  ${tw`flex flex-wrap w-fit gap-2`}
`;

const findLowestValues = quotationList => {
  if (quotationList.length === 0) {
    return null;
  }

  const lowestValues = quotationList.reduce(
    (minValues, item) => {
      return {
        budgetValue:
          item.budgetValue < minValues.budgetValue
            ? item.budgetValue
            : minValues.budgetValue,
        closedValue:
          item.closedValue < minValues.closedValue
            ? item.closedValue
            : minValues.closedValue,
        associateValue:
          item.associateValue < minValues.associateValue
            ? item.associateValue
            : minValues.associateValue,
        associationValue:
          item.associationValue < minValues.associationValue
            ? item.associationValue
            : minValues.associationValue,
      };
    },
    {
      budgetValue: quotationList[0].budgetValue,
      closedValue: quotationList[0].closedValue,
      associateValue: quotationList[0].associateValue,
      associationValue: quotationList[0].associationValue,
    },
  );

  return lowestValues;
};

const toFloat = value => {
  if (typeof value === 'string') {
    return parseFloat(value.replace(',', '.'));
  } else if (value === undefined) {
    return 0;
  }
  return value;
};

const ServiceProviderModal = ({
  isOpen = false,
  associatePays = false,
  onAddServiceValue = () => {},
  onClose = () => {},
}) => {
  const [serviceProvider, setServiceProvider] = useState({});
  const [countryId, setCountryId] = useState(1);
  const [stateId, setStateId] = useState();
  const [cityId, setCityId] = useState();

  const [budgetValue, setBudgetValue] = useState();
  const [closedValue, setClosedValue] = useState();
  const [associateValue, setAssociateValue] = useState(0);
  const [associationValue, setAssociationValue] = useState(0);

  const [quotationList, setQuotationList] = useState([]);

  const serviceProviderSelectRef = useRef(null);
  const stateSelectRef = useRef(null);
  const citySelectRef = useRef(null);

  const { phoneMask, formatToBRL } = new Formatter();

  const defaultColumns = [
    {
      label: 'Prestador de Serviço',
      field: 'serviceProviderName',
    },
    {
      label: 'Endereço',
      field: 'address',
    },
    { label: 'Contato(s)', field: 'contacts' },
    {
      label: 'Valor Orçado',
      field: 'budgetValue',
      display: 'budgetValueDisplay',
    },
    {
      label: 'Valor Fechado',
      field: 'closedValue',
      display: 'closedValueDisplay',
    },
    ...(associatePays
      ? [
          {
            label: 'Pago Associado (30%)',
            field: 'associateValue',
            display: 'associateValueDisplay',
          },
          {
            label: 'Pago Associação (70%)',
            field: 'associationValue',
            display: 'associationValueDisplay',
          },
        ]
      : []),
    {
      label: 'Ações',
      field: 'actions',
      disableSort: true,
    },
  ];

  const lowestValues = useMemo(
    () => findLowestValues(quotationList),
    [quotationList],
  );

  const handlePrice = useCallback(
    (value, remove) => {
      if (quotationList.length >= 10) {
        toast.warning(
          'Limite da tabela de preços atingido, remova algum preço para adicionar mais.',
          { autoClose: 5000 },
        );
        return;
      }

      const existingPrice = quotationList.find(
        item => item.serviceProviderId === value.serviceProviderId,
      );

      if (existingPrice) {
        if (remove) {
          setQuotationList(
            quotationList.filter(
              item => item.serviceProviderId !== value.serviceProviderId,
            ),
          );
        } else {
          const updatedList = quotationList.map(item =>
            item.serviceProviderId === existingPrice.serviceProviderId
              ? value
              : item,
          );
          setQuotationList(updatedList);
          toast.info(
            'Este prestador já existe na tabela, os valores foram atualizados.',
            { autoClose: 5000 },
          );
        }
      } else {
        setQuotationList([...quotationList, value]);
      }

      setBudgetValue();
      setClosedValue();
      setServiceProvider({});
      serviceProviderSelectRef?.current?.clearValue();
    },
    [quotationList],
  );

  const handleAcceptServiceValue = useCallback(values => {
    onAddServiceValue(values);
    handleClose();
  }, []);

  const handleClose = useCallback(() => {
    setServiceProvider({});
    setCountryId();
    setStateId();
    setCityId();
    setBudgetValue();
    setClosedValue();
    setQuotationList([]);
    onClose();
  }, []);

  const tableData = useMemo(() => {
    return quotationList.map(item => ({
      ...item,
      contacts: item?.contacts?.map(c => (
        <Row key={c.number}>
          <Item>Nome: {c.name}</Item>
          <Item>
            <span
              style={{ cursor: 'pointer' }}
              data-tooltip-id={item?.serviceProviderId}
              data-tooltip-content="Clique para copiar o contato"
              onClick={() => {
                navigator.clipboard.writeText(c.ddi + c.number);
                toast.success('Contato copiado para área de transferência.');
              }}>
              Número: {`${c.ddi} ${phoneMask(c.number)}`}
            </span>
          </Item>
          <Tooltip id={item?.serviceProviderId} place="left" />
        </Row>
      )),
      budgetValueDisplay:
        item?.budgetValue === lowestValues?.budgetValue ? (
          <span style={{ fontWeight: 600, color: 'green' }}>
            {formatToBRL(item?.budgetValue)}
          </span>
        ) : (
          <span style={{ fontWeight: 600, color: 'red' }}>
            {formatToBRL(item?.budgetValue)}
          </span>
        ),
      closedValueDisplay:
        item?.closedValue === lowestValues?.closedValue ? (
          <span style={{ fontWeight: 600, color: 'green' }}>
            {formatToBRL(item?.closedValue)}
          </span>
        ) : (
          <span style={{ fontWeight: 600, color: 'red' }}>
            {formatToBRL(item?.closedValue)}
          </span>
        ),
      ...(associatePays
        ? {
            associateValueDisplay:
              item?.associateValue === lowestValues?.associateValue ? (
                <span style={{ fontWeight: 600, color: 'green' }}>
                  {formatToBRL(item?.associateValue)}
                </span>
              ) : (
                <span style={{ fontWeight: 600, color: 'red' }}>
                  {formatToBRL(item?.associateValue)}
                </span>
              ),
            associationValueDisplay:
              item?.associationValue === lowestValues?.associationValue ? (
                <span style={{ fontWeight: 600, color: 'green' }}>
                  {formatToBRL(item?.associationValue)}
                </span>
              ) : (
                <span style={{ fontWeight: 600, color: 'red' }}>
                  {formatToBRL(item?.associationValue)}
                </span>
              ),
          }
        : {}),
      actions: (
        <ButtonsGroup>
          <IconButton
            type="edit"
            tooltipText={'Editar valor'}
            onClick={() => {
              serviceProviderSelectRef?.current?.setValue({
                label: item?.serviceProviderName,
                value: item?.serviceProviderId,
              });
              setServiceProvider({
                name: item?.serviceProviderName,
                id: item?.serviceProviderId,
                address: item?.address,
                contacts: item?.contacts,
              });
              setBudgetValue(item?.budgetValue);
              setClosedValue(item?.closedValue);
            }}
          />
          <IconButton
            type="deactivate"
            tooltipText={'Remover'}
            onClick={() => handlePrice(item, true)}
          />
          <IconButton
            type="activate"
            tooltipText={'Aceitar este orçamento'}
            onClick={() => handleAcceptServiceValue(item)}
          />
        </ButtonsGroup>
      ),
    }));
  }, [quotationList, lowestValues, associatePays]);

  useEffect(() => {
    if (!closedValue) {
      setAssociateValue(0);
      setAssociationValue(0);
      return;
    }
    setAssociateValue((toFloat(closedValue) * 0.3).toFixed(2));
    setAssociationValue((toFloat(closedValue) * 0.7).toFixed(2));
  }, [closedValue]);

  useEffect(() => {
    return () => {
      if (isOpen) toast.dismiss();
    };
  }, [isOpen]);

  return (
    <DefaultModal
      isOpen={isOpen}
      title="Prestador de Serviço"
      onClose={handleClose}
      maxWidth="90vw"
      onBackgroundClick={handleClose}
      onEscapeKeyDown={handleClose}>
      <Container>
        <InsertContainer>
          <Select
            isRequired
            size="sm"
            maxWidth="200px"
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint="georef/countries"
            onOptionChange={option => {
              if (stateId) {
                stateSelectRef.current?.clearValue();
              }
              setCountryId(option?.value);
            }}
            placeholder=""
            label={{
              text: 'Filtrar por País',
            }}
            initialValue={{
              label: 'Brasil',
              value: 1,
            }}
          />

          <Select
            size="sm"
            maxWidth="250px"
            menuMaxHeight={200}
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint={`georef/states/?country_id=${countryId}`}
            onOptionChange={option => {
              if (cityId) {
                citySelectRef?.current?.clearValue();
                setCityId();
              }
              setStateId(option.value);
            }}
            placeholder=""
            label={{
              text: 'Filtrar por Estado',
            }}
            isDisabled={!countryId}
          />
          <Select
            ref={citySelectRef}
            size="sm"
            maxWidth="300px"
            menuMaxHeight={200}
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint={
              !stateId ? 'georef/cities/' : `georef/cities/?uf_id=${stateId}`
            }
            onOptionChange={option => {
              setCityId(option.value);
            }}
            placeholder=""
            label={{
              text: 'Filtrar por Cidade',
            }}
            isDisabled={!stateId}
          />

          <Select
            ref={serviceProviderSelectRef}
            isRequired
            size="sm"
            maxWidth="300px"
            menuMaxHeight={200}
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint={
              cityId
                ? `tows/?city_id=${cityId}&active=true`
                : stateId
                  ? `tows/?uf_id=${stateId}&active=true`
                  : countryId
                    ? `tows/?country_id=${countryId}&active=true`
                    : 'tows/?active=true'
            }
            onOptionChange={option => {
              setServiceProvider({
                id: option.value,
                name: option.label,
                address: `${option?.locality?.address ? option.locality.address + ', ' : ''}${option?.locality?.complement ? option.locality.complement + ', ' : ''}${option?.locality?.place_number ? option.locality.place_number + ', ' : ''}${option?.locality?.neighborhood ? option.locality.neighborhood + ', ' : ''}${option?.locality?.city?.name ? option.locality.city.name + ' / ' : ''}${option?.locality?.city?.state?.short_code ? option.locality.city.state.short_code : ''}`,
                contacts:
                  option.value === serviceProvider?.id
                    ? serviceProvider?.contacts
                    : option?.contacts?.map(item => ({
                        name: item?.name || 'Não cadastrado',
                        number: item?.telephone || '-',
                        ddi: item?.ddi || '-',
                      })) || [],
              });
            }}
            placeholder="Selecione um Prestador"
          />
        </InsertContainer>

        <ServiceProviderCard>
          <span className="title">Prestador de Serviço</span>

          {serviceProvider?.id ? (
            <div className="content">
              <Text
                data-tooltip-id={serviceProvider?.id}
                data-tooltip-content={serviceProvider.name}>
                {serviceProvider.name}
              </Text>

              <Hr />

              <Text $variant="label">Endereço</Text>
              <Text
                $variant="value"
                data-tooltip-id={serviceProvider?.id}
                data-tooltip-content={serviceProvider?.address}>
                {serviceProvider?.address}
              </Text>

              <Hr />

              <Text $variant="label">Contato(s)</Text>

              {serviceProvider?.contacts?.length > 0 ? (
                serviceProvider?.contacts?.map(item => (
                  <Row key={item.number}>
                    <Item>
                      <Text
                        $variant="value"
                        data-tooltip-id={serviceProvider?.id}
                        data-tooltip-content={item.name}>
                        Nome: {item.name}
                      </Text>
                    </Item>
                    <Item>
                      <Text
                        $variant="value"
                        style={{ cursor: 'pointer' }}
                        data-tooltip-id={serviceProvider?.id}
                        data-tooltip-content="Clique para copiar o contato"
                        onClick={() => {
                          navigator.clipboard.writeText(item.ddi + item.number);
                          toast.success(
                            'Contato copiado para área de transferência.',
                          );
                        }}>
                        Número: {`${item.ddi} ${phoneMask(item.number)}`}
                      </Text>
                    </Item>
                  </Row>
                ))
              ) : (
                <Text $variant="notFound">Nenhum contato cadastrado</Text>
              )}

              <Hr />
            </div>
          ) : (
            <Text $variant="notSelected">Nenhum prestador selecionado</Text>
          )}

          <InsertContainer>
            <CurrencyInput
              id="budgetValue"
              customInput={Input}
              decimalsLimit={2}
              decimalScale={2}
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              maxWidth="200px"
              placeholder=""
              label={{ text: 'Valor Orçado' }}
              value={budgetValue}
              onValueChange={value => setBudgetValue(value)}
              onBlur={() => setClosedValue(budgetValue)}
              disabled={!serviceProvider?.id}
            />
            <CurrencyInput
              id="closedValue"
              customInput={Input}
              decimalsLimit={2}
              decimalScale={2}
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              maxWidth="200px"
              placeholder=""
              label={{ text: 'Valor Fechado' }}
              value={closedValue}
              onValueChange={value => setClosedValue(value)}
              disabled={!serviceProvider?.id}
            />
            {associatePays && (
              <>
                <CurrencyInput
                  customInput={Input}
                  decimalsLimit={2}
                  decimalScale={2}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  maxWidth="200px"
                  label={{ text: 'Pago Associado (30%)' }}
                  placeholder=""
                  value={associateValue}
                  disabled={true}
                />
                <CurrencyInput
                  customInput={Input}
                  decimalsLimit={2}
                  decimalScale={2}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  maxWidth="200px"
                  label={{ text: 'Pago Associação (70%)' }}
                  placeholder=""
                  value={associationValue}
                  disabled={true}
                />
              </>
            )}
          </InsertContainer>

          <PrimaryButton
            type="button"
            disabled={!budgetValue || !closedValue || !serviceProvider?.id}
            onClick={() =>
              handlePrice(
                {
                  serviceProviderId: serviceProvider?.id,
                  serviceProviderName: serviceProvider?.name,
                  address: serviceProvider?.address,
                  contacts: serviceProvider?.contacts,
                  budgetValue: toFloat(budgetValue),
                  closedValue: toFloat(closedValue),
                  ...(associatePays
                    ? {
                        associateValue: toFloat(associateValue),
                        associationValue: toFloat(associationValue),
                      }
                    : {}),
                },
                false,
              )
            }>
            Inserir valores
          </PrimaryButton>
          <Tooltip id={serviceProvider?.id} place="bottom" />
        </ServiceProviderCard>

        {quotationList.length > 0 && (
          <div style={{ width: '100%' }}>
            <Table columns={defaultColumns} data={tableData} />
          </div>
        )}
      </Container>
    </DefaultModal>
  );
};

export default memo(ServiceProviderModal);
