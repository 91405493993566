import { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { startOfYear, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import PageContainer from 'components/ui-kit/PageContainer';
import PageHeader from 'components/ui-kit/PageHeader';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import TitleBox from 'components/ui-kit/TitleBox';

import api from 'services/api';
import { BEARER } from 'utils/constants';
import { getBackendDateFormat, variant } from 'utils/functions';
import { NO_DATA_FOUND, STANDARD_ERROR } from 'utils/messages';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';
import useDocumentTitle from 'hooks/useDocumentTitle';

import { ReactComponent as LogoIcon } from 'assets/logos/abc_logo_brazil.svg';
import { ReactComponent as WinchIcon } from 'assets/images/svg/winch.svg';
import { ReactComponent as UserIcon } from 'assets/images/svg/user-fill.svg';
import { ReactComponent as CarIcon } from 'assets/images/svg/vehicle_front.svg';
import { ReactComponent as MapIcon } from 'assets/images/svg/map.svg';

const blink = keyframes`
  50% {
    border-color: transparent;
  }
  100% {
    border-color: #0A253D;
  }
`;

const typing = keyframes`
  0% {
    width: 0%;
    visibility: hidden;
  }
  100% {
    width: 100%;
    visibility: visible;
  }
`;

const Container = styled.div`
  ${tw`flex flex-col w-full h-full items-center gap-10 md:gap-16 mt-6 md:mt-0`}
`;

const Logo = styled(LogoIcon)`
  ${tw`h-32 w-full max-w-2xl fill-[var(--dark-blue-theme)] shrink-0`}
`;

const Title = styled.h1`
  ${tw`overflow-hidden whitespace-nowrap border-r-4 border-r-[var(--dark-blue-theme)] pr-5 md:pr-10 text-2xl md:text-5xl bg-clip-text bg-gradient-to-b from-[#1F53FF] to-[#0A1B24] [-webkit-text-fill-color: transparent;] font-bold`}

  animation: ${typing} 2s steps(20) infinite alternate, ${blink} 0.7s infinite;
`;

const Text = styled.span`
  ${tw`text-lg text-center text-white leading-none`}

  ${({ $variant }) =>
    variant({
      title: tw`font-semibold`,
      value: tw`text-5xl font-bold`,
    })({ $variant })}
`;

const Wrapper = styled.div`
  ${tw`flex flex-wrap gap-6 w-full justify-center`}
`;

const Card = styled.div`
  ${tw`flex flex-col w-full max-w-[18rem] h-fit [background: var(--blue-gradient)] p-2 gap-4 items-center rounded-2xl shadow-lg shadow-basic-black
  border-solid border-2 border-[var(--gray-dark)] overflow-hidden`}
`;

const Ball = styled.div`
  ${tw`flex min-h-[13rem] min-w-[13rem] w-[13rem] max-w-full rounded-circle p-2 bg-white bg-opacity-30 justify-center items-center overflow-hidden ring-[var(--gray-theme)] ring-8 m-2`}
`;

const iconsConfig = {
  flexShrink: 0,
  height: '55px',
  width: '55px',
  fill: '#fff',
};

const counter = (id, start, end, duration) => {
  let obj = document.getElementById(id);
  if (!obj) return;
  let current = start;
  let range = end - start;
  let increment = end > start ? 1 : -1;
  let step = Math.abs(Math.floor(duration / range));
  let timer = setInterval(() => {
    current += increment;
    obj.textContent = current;
    if (current === end) {
      clearInterval(timer);
    }
  }, step);
};

const Home = () => {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  useDocumentTitle('Início');

  const getData = useCallback(async () => {
    setIsLoading(true);
    setData([]);

    try {
      const { data: response, status } = await api.get(
        'dashboard/summary/welcome',
        {
          onProgress,
          headers: {
            Authorization: BEARER + user.token,
          },
          params: {
            from_date: getBackendDateFormat(startOfYear(new Date())),
            to_date: getBackendDateFormat(new Date()),
          },
        },
      );

      if (status !== 200) throw new Error();

      const dataLog = [];

      if (response.data?.total_occurrences) {
        dataLog.push({
          icon: WinchIcon,
          title: 'N° de ocorrências atendidas',
          value: response.data?.total_occurrences || 0,
        });
      }

      if (response.data?.total_clients) {
        dataLog.push({
          icon: UserIcon,
          title: 'N° de associados atendidos',
          value: response.data?.total_clients || 0,
        });
      }

      if (response.data?.total_vehicles) {
        dataLog.push({
          icon: CarIcon,
          title: 'N° de veículos atendidos',
          value: response.data?.total_vehicles || 0,
        });
      }

      if (response.data?.total_locales) {
        dataLog.push({
          icon: MapIcon,
          title: 'N° de locais atendidos',
          value: response.data?.total_locales || 0,
        });
      }

      setData(dataLog);
    } catch (error) {
      let errorMessage;
      switch (error.response?.status) {
        case 404:
          errorMessage = NO_DATA_FOUND;
          break;
        default:
          errorMessage = STANDARD_ERROR;
      }
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
      resetProgress();
    }
  }, [user]);

  useLayoutEffect(() => {
    const fetchData = async () => {
      await getData();
    };
    fetchData();

    return () => {
      toast.dismiss();
    };
  }, []);

  useEffect(() => {
    if (!data.length) return;

    data.forEach((item, index) => {
      const startValue = Math.max(0, item.value - 100);
      counter(`count${index}`, startValue, item.value, 3000);
    });
  }, [data]);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader titles={['Início']} />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <Container>
        <div>
          <Title>Bem vindo de volta!</Title>
        </div>

        <Logo />

        {!!data.length && (
          <>
            <TitleBox
              type="line"
              title={{
                text: `Informações de todo sistema no ano de ${format(utcToZonedTime(new Date(), 'America/Sao_Paulo'), 'yyyy', { timeZone: 'America/Sao_Paulo' })}`,
                bg: '#F2F2F2',
              }}
            />
            <Wrapper>
              {data?.map((item, index) => (
                <Card key={index}>
                  <item.icon style={iconsConfig} />
                  <Text $variant="title">{item?.title}</Text>

                  <Ball>
                    <Text $variant="value" id={`count${index}`}></Text>
                  </Ball>
                </Card>
              ))}
            </Wrapper>
          </>
        )}
      </Container>
    </PageContainer>
  );
};

export default Home;
