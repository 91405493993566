import { useState } from 'react';
import styled from 'styled-components';
import useAuth from 'hooks/useAuth';
import tw from 'twin.macro';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import DropDownUser from './DropDownUser';
import MenuToggle from '../Menu/MenuToggle';
import Menu from '../Menu';

import { ReactComponent as UserIcon } from 'assets/images/svg/user_fill.svg';
import { ReactComponent as WhatsIcon } from 'assets/images/svg/whatsapp.svg';
import { ReactComponent as PhoneIcon } from 'assets/images/svg/telefone_filled.svg';
import { ReactComponent as LogoIcon } from 'assets/logos/abc_logo_brazil.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/svg/arrow_down.svg';

const iconStyles = {
  height: '25px',
  width: '25px',
  fill: 'var(--royal-blue-theme)',
  cursor: 'pointer',
};

const Container = styled.header`
  ${tw`flex w-full h-[64px] md:h-[80px] items-center justify-between z-30 bg-white shadow-md sticky top-0 left-0`};
`;

const LogoContainer = styled.div`
  ${tw`flex w-full h-full lg:max-w-md relative`}
  background: var(--dark-blue-gradient);
`;

const LogoBorder = styled.div`
  ${tw`flex w-full h-full items-center justify-center lg:skew-x-[140deg] lg:right-[-110px] lg:absolute px-2`}
  background: var(--dark-blue-gradient);
`;

const Logo = styled(LogoIcon)`
  ${tw`flex justify-center items-center h-[60px] lg:h-full w-full lg:skew-x-[-140deg] fill-white`}
`;

const ContainerDetails = styled.div`
  ${tw`hidden lg:flex w-fit h-full items-center mr-4 gap-10`};
`;

const LoginBox = styled.div`
  ${tw`flex items-center w-fit h-full gap-2`}
`;

const TextBox = styled.div`
  ${tw`flex items-center justify-center w-fit h-full text-sm text-center font-medium gap-2`}

  p {
    ${tw`text-[var(--gray-dark)]`}
  }

  .user {
    ${tw`w-fit max-w-[150px] text-base text-[var(--royal-blue-theme)] font-bold truncate cursor-pointer`}
  }
`;

const IconsContainer = styled.div`
  ${tw`w-fit flex justify-between gap-4`}
`;

const Picture = styled.div`
  ${tw`flex w-[50px] h-[50px] rounded-circle bg-gray-300 items-center justify-center`}
`;

const DropDownContainer = styled.div`
  ${tw`relative inline-block`}
`;

const Header = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);

  const { user } = useAuth();

  return (
    <Container
      onMouseLeave={() => {
        if (isOpenMenu) {
          setTimeout(() => {
            setIsOpenMenu(false);
          }, 400);
        }
      }}>
      <LogoContainer>
        <MenuToggle
          isOpen={isOpenMenu}
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        />
        <Menu isOpen={isOpenMenu} setIsOpen={setIsOpenMenu} />
        <LogoBorder>
          <NavLink to="/">
            <Logo alt="Logo ABC" />
          </NavLink>
        </LogoBorder>
      </LogoContainer>

      <ContainerDetails>
        <IconsContainer>
          <WhatsIcon
            style={iconStyles}
            onClick={() => toast('Em desenvolvimento!')}
          />
          <PhoneIcon
            style={iconStyles}
            onClick={() => toast('Em desenvolvimento!')}
          />
        </IconsContainer>
        <LoginBox>
          <NavLink to="/profile">
            <Picture>
              <UserIcon
                width="35px"
                height="35px"
                fill="var(--royal-blue-theme)"
              />
            </Picture>
          </NavLink>

          <TextBox>
            <p>Olá,</p>
            <div
              className="user"
              onClick={() => setIsOpenDropDown(!isOpenDropDown)}>
              {user?.name}
            </div>
          </TextBox>

          <DropDownContainer>
            <ArrowIcon
              style={{ cursor: 'pointer' }}
              width={20}
              height={20}
              fill="var(--royal-blue-theme)"
              onClick={() => setIsOpenDropDown(!isOpenDropDown)}
            />
            <DropDownUser
              isOpen={isOpenDropDown}
              setIsOpen={setIsOpenDropDown}
            />
          </DropDownContainer>
        </LoginBox>
      </ContainerDetails>
    </Container>
  );
};

export default Header;
