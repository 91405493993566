import styled, { css } from 'styled-components';
import tw from 'twin.macro';

const Box = styled.div`
  ${tw`flex h-fit min-h-fit w-full border-2 rounded-lg px-2 pt-6 pb-2 relative mt-3 shadow-lg`}

  ${({ $error, $color, $justify, $maxWidth }) => css`
    border-color: ${$error
      ? 'var(--red-theme)'
      : $color || 'var(--royal-blue-theme)'};
    justify-content: ${$justify || 'center'};
    max-width: ${$maxWidth || '100%'};
  `}
`;

const Line = styled.div`
  ${tw`flex h-px w-full px-2 pb-3 relative mt-3 border-solid border-t-2`}

  ${({ $error, $color, $justify, $maxWidth }) => css`
    border-color: ${$error
      ? 'var(--red-theme)'
      : $color || 'var(--royal-blue-theme)'};
    justify-content: ${$justify || 'center'};
    max-width: ${$maxWidth || '100%'};
  `}
`;

const Title = styled.span`
  ${tw`px-1 sm:px-2 py-0 m-0 border-solid border-x-2 h-fit w-fit max-w-full absolute top-0 -translate-y-1/2 leading-6
   text-base sm:text-xl text-center font-bold truncate`}

  ${({ $error, $color, $bg }) => css`
    color: ${$error ? 'var(--red-theme)' : $color || 'var(--royal-blue-theme)'};
    border-color: ${$error
      ? 'var(--red-theme)'
      : $color || 'var(--royal-blue-theme)'};
    background: ${$bg || '#FFF'};
  `}
`;

const Container = styled.div`
  ${tw`flex w-full h-fit`}

  ${({ $height }) => css`
    height: ${$height || '100%'};
  `}
`;

const TitleBox = ({
  type = 'box',
  title = { text: '', justify: '', bg: '' },
  color = '',
  maxWidth = '',
  height = '',
  error = false,
  children,
  ...rest
}) => {
  return (
    (type === 'box' && (
      <Box
        $justify={title.justify}
        $color={color}
        $maxWidth={maxWidth}
        $error={error}>
        <Title $color={color} $bg={title.bg} $error={error}>
          {title.text}
        </Title>
        <Container $height={height} {...rest}>
          {children}
        </Container>
      </Box>
    )) ||
    (type === 'line' && (
      <Line
        $justify={title.justify}
        $color={color}
        $maxWidth={maxWidth}
        $error={error}
        {...rest}>
        <Title $color={color} $bg={title.bg} $error={error}>
          {title.text}
        </Title>
      </Line>
    ))
  );
};

export default TitleBox;
