import {
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
  useRef,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';

import PageContainer from 'components/ui-kit/PageContainer';
import PageHeader from 'components/ui-kit/PageHeader';
import MapContainer from 'components/pages/Occurrences/Form/MapContainer';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import Tabs from 'components/ui-kit/Tabs';

import BasicInfosForm from 'components/pages/Occurrences/Form/BasicInfosForm';
import BudgetForms from 'components/pages/Occurrences/Form/BudgetForms';
import Authorizations from 'components/pages/Occurrences/Form/Authorizations';
import History from 'components/pages/Occurrences/Form/History';
import OccurrenceClosureModal from 'components/pages/Occurrences/Form/OccurrenceClosureModal';
import OccurrenceDeleteModal from 'components/pages/Occurrences/Form/OccurrenceDeleteModal';

import api from 'services/api';
import { getItem, addItem, removeItem } from 'providers/storage';
import { getUniqueValues, hasPermission, variant } from 'utils/functions';
import {
  DEV_ID,
  SUPERVISOR_ID,
  OPERATOR_ID,
  BEARER,
  SUBROUTES,
  MODULES,
  UPDATE,
  DELETE,
  CREATE,
} from 'utils/constants';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

import SSEManager from 'services/sse';

import { ReactComponent as BasicInfoIcon } from 'assets/images/svg/edit.svg';
import { ReactComponent as BudgetsIcon } from 'assets/images/svg/dollar_sign.svg';
import { ReactComponent as AuthorizationsIcon } from 'assets/images/svg/authorization.svg';
import { ReactComponent as HistoryIcon } from 'assets/images/svg/history.svg';

const { main } = MODULES.occurrences;

const StyledPage = styled(PageContainer)`
  ${tw`md:flex-row h-full p-0 overflow-hidden`}

  & > * {
    ${tw`m-0`}
  }
`;

const Container = styled.div`
  ${tw`flex flex-col h-full w-full m-0`}

  ${({ $variant }) =>
    variant({
      map: tw`min-h-[35%] md:min-h-full md:w-[35%] overflow-visible relative`,
      form: tw`min-h-[65%] md:min-h-full md:w-[65%] gap-6 p-4 overflow-x-hidden overflow-y-auto`,
    })({ $variant })}
`;

const BlockHeader = styled.div`
  ${tw`h-[64px] w-screen md:h-[80px] bg-transparent fixed top-0 left-0 z-[50]`}
`;

const compareWithoutPositions = (array1, array2) => {
  let cleanArray1 = array1;
  let cleanArray2 = array2;

  cleanArray1 = cleanArray1?.map(item => ({
    locations:
      item?.locations?.map(loc => ({
        ...loc,
        origin: { ...loc.origin, position: undefined },
        destiny: { ...loc.destiny, position: undefined },
      })) || [],
  }));

  cleanArray2 = cleanArray2?.map(item => ({
    locations:
      item?.locations?.map(loc => ({
        ...loc,
        origin: { ...loc.origin, position: undefined },
        destiny: { ...loc.destiny, position: undefined },
      })) || [],
  }));

  return JSON.stringify(cleanArray1) === JSON.stringify(cleanArray2);
};

const tabMapping = {
  basicInfos: 'Informações Básicas',
  budgets: 'Orçamentos',
  authorizations: 'Autorizações',
  history: 'Histórico',
};

const Form = () => {
  const [occurrenceData, setOccurrenceData] = useState([]);
  const [occurrenceInfo, setOccurrenceInfo] = useState({});

  const [closureModal, setClosureModal] = useState({});
  const [deleteModal, setDeleteModal] = useState({});

  const [serviceHasChanged, setServiceHasChanged] = useState(false);

  const [plateOptions, setPlateOptions] = useState([]);
  const [oldPlateOptions, setOldPlateOptions] = useState([]);
  const [routingIsLoading, setRoutingIsLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [startingService, setStartingService] = useState(false);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const navigate = useNavigate();
  const { id: occurrenceId } = useParams();

  const tabRef = useRef(null);

  const { init, close } = new SSEManager();

  const getOccurrence = useCallback(
    async id => {
      setIsLoading(true);
      setOccurrenceData([]);

      try {
        const { data: response, status } = await api.get(
          `/occurrencies/${id}`,
          {
            onDownloadProgress: onProgress,
            headers: {
              Authorization: BEARER + user.token,
            },
          },
        );

        if (status !== 200) throw new Error();

        const data = response.data;

        const benefitedPlates = data?.vehicles?.map(vehicle => ({
          id: vehicle.id,
          plate: vehicle?.plate,
          associateId: vehicle?.client?.id,
          associateName: vehicle?.client?.display_name,
          associationName: vehicle?.client?.association_name,
        }));

        const occurrenceLog = {
          basicInfos: {
            typeId: data?.category?.id || 1,
            vehicles:
              data?.vehicles?.map(item => ({
                id: item?.id,
                plate: item?.plate,
                associateId: item?.client?.id,
                associateName: item?.client?.display_name || '-',
                locations: item?.locales
                  ?.filter(loc => !!loc?.id)
                  .map(
                    loc =>
                      ({
                        id: loc?.id,
                        origin: {
                          cityId: loc?.from_city?.id,
                          cityName: loc?.from_city?.name,
                          stateId: loc?.from_city?.state?.id,
                          stateName: loc?.from_city?.state?.name,
                          stateShortCode: loc?.from_city?.state?.short_code,
                          ...((loc?.from_city?.latitude &&
                            loc?.from_city?.longitude && {
                              position: [
                                loc.from_city.latitude,
                                loc.from_city.longitude,
                              ],
                            }) ||
                            []),
                        },
                        destiny: {
                          cityId: loc?.to_city?.id,
                          cityName: loc?.to_city?.name,
                          stateId: loc?.to_city?.state?.id,
                          stateName: loc?.to_city?.state?.name,
                          stateShortCode: loc?.to_city?.state?.short_code,
                          ...((loc?.to_city?.latitude &&
                            loc?.to_city?.longitude && {
                              position: [
                                loc.to_city.latitude,
                                loc.to_city.longitude,
                              ],
                            }) ||
                            []),
                        },
                      }) || [],
                  ),
              })) || [],
            causes:
              data?.types?.map(item => ({
                id: item?.id,
                name: item?.name,
                typeId: item?.category?.id,
                typeName: item?.category?.name,
              })) || [],
            description: data?.description || '',
            files:
              data?.files_infos?.map(file => ({
                id: file?.id,
                name: file?.name || '-',
                extension: file?.extension,
                isSaved: file?.created_on ? true : false,
              })) || [],
          },
          budgets: data?.budgets?.map(b => ({
            id: b?.id,
            benefitedPlates:
              b?.vehicles_id?.map(vehicleId =>
                benefitedPlates.find(plate => plate.id === vehicleId),
              ) || [],
            typeId: b?.type?.id,
            typeName: b?.type?.name,
            serviceProviderId: b?.tow?.id,
            serviceProviderName: b?.tow?.name,
            budgetValue: b?.cost || 0,
            closedValue: b?.cost_final || 0,
            comments: b?.description || '',
            files:
              b?.files?.map(file => ({
                id: file?.id,
                name: file?.name || '-',
                extension: file?.extension,
                isSaved: file?.created_on ? true : false,
              })) || [],
          })),
          authorizations:
            data?.authorizations?.map(a => ({
              type: a?.type || '-',
              downloads:
                a?.downloads?.map(item => ({
                  type: item?.type,
                  url: item?.url,
                })) || [],
              associateName:
                getUniqueValues(
                  a?.vehicles_id?.map(vehicleId =>
                    benefitedPlates.find(plate => plate.id === vehicleId),
                  ) || [],
                  'associateName',
                  value => value || '-',
                ) || '-',
              plate:
                a?.vehicles_id
                  ?.map(vehicleId => {
                    const vehicle = benefitedPlates.find(
                      plate => plate.id === vehicleId,
                    );
                    return vehicle.plate;
                  })
                  .join(', ') || '-',
              associationName:
                getUniqueValues(
                  a?.vehicles_id?.map(vehicleId =>
                    benefitedPlates.find(plate => plate.id === vehicleId),
                  ) || [],
                  'associationName',
                  value => value || '-',
                ) || '-',
            })) || [],
        };

        setOccurrenceData(occurrenceLog);

        const platesLog =
          occurrenceLog?.basicInfos?.vehicles?.map(item => ({
            value: item?.id,
            label: item?.plate,
            associateId: item?.associateId,
            associateName: item?.associateName,
            locations: item?.locations,
          })) || [];

        if (!compareWithoutPositions(platesLog, plateOptions)) {
          setPlateOptions(platesLog);
          setOldPlateOptions(platesLog);
        }

        setOccurrenceInfo({
          id: data?.id,
          numProtocol: data?.nr_protocol,
          statusId: data?.status_id,
          isBeingServedBy: {
            id: data?.user_attended?.id,
            name: data?.user_attended?.display_name || '-',
          },
          wasClosedBy: {
            id: data?.user_closed?.id,
            name: data?.user_closed?.display_name || '-',
          },
        });

        setIsLoading(false);
        resetProgress();

        return { success: true };
      } catch (error) {
        toast.error('Falha ao buscar informações sobre a Ocorrência.');

        setTimeout(() => {
          setIsLoading(false);
          resetProgress();
          navigate(SUBROUTES.occurrences.path);
        }, 3500);

        return { success: false };
      } finally {
        if (getItem('serviceId'))
          await handleService({
            start: false,
            serviceId: getItem('serviceId'),
          });
      }
    },
    [user, plateOptions],
  );

  const handleService = useCallback(
    async ({ start = false, serviceId = undefined }) => {
      setStartingService(true);
      try {
        if (start) {
          const { status } = await api.put(
            `occurrencies/${occurrenceInfo?.id}/attending`,
            null,
            {
              onProgress,
              headers: {
                Authorization: BEARER + user.token,
              },
            },
          );
          if (status !== 200) throw new Error();

          if (serviceHasChanged) setServiceHasChanged(false);

          addItem('serviceId', occurrenceInfo?.id);
        } else {
          removeItem('serviceId');
          await api.delete(
            `occurrencies/${occurrenceInfo?.id || serviceId}/attending`,
            {
              onProgress,
              headers: {
                Authorization: BEARER + user.token,
              },
            },
          );
        }
      } catch (error) {
        if (start) toast.error('Falha ao iniciar atendimento');
      } finally {
        setStartingService(false);
        resetProgress();
      }
    },
    [occurrenceInfo, user, serviceHasChanged],
  );

  const resetService = useCallback(() => {
    setServiceHasChanged(false);
    removeItem('serviceId');
  }, []);

  const onStart = data => {
    const item = JSON.parse(data);

    const info = {
      id: item?.id,
      numProtocol: item?.nr_protocol,
      statusId: item?.status_id,
      isBeingServedBy: {
        id: item?.user_attended?.id,
        name: item?.user_attended?.display_name || '-',
      },
      wasClosedBy: {
        id: item?.user_closed?.id,
        name: item?.user_closed?.display_name || '-',
      },
    };
    setOccurrenceInfo(info);
  };

  const onUpdate = data => {
    const item = JSON.parse(data);

    const info = {
      id: item?.id,
      numProtocol: item?.nr_protocol,
      statusId: item?.status_id,
      isBeingServedBy: {
        id: item?.user_attended?.id,
        name: item?.user_attended?.display_name || '-',
      },
      wasClosedBy: {
        id: item?.user_closed?.id,
        name: item?.user_closed?.display_name || '-',
      },
    };
    setOccurrenceInfo(info);
  };

  const canCreate = hasPermission(user?.role, main, null, CREATE);

  const canEdit = Boolean(
    (occurrenceInfo?.statusId < 5 &&
      hasPermission(user?.role, main, null, UPDATE)) ||
      (user?.role?.id === DEV_ID &&
        hasPermission(user?.role, main, null, UPDATE)) ||
      (user?.role?.id === SUPERVISOR_ID &&
        hasPermission(user?.role, main, null, UPDATE)) ||
      (user?.role?.id === OPERATOR_ID &&
        hasPermission(user?.role, main, null, UPDATE)),
  );

  const canDelete = Boolean(
    (occurrenceInfo?.statusId < 5 &&
      hasPermission(user?.role, main, null, DELETE)) ||
      (user?.role?.id === DEV_ID &&
        hasPermission(user?.role, main, null, DELETE)) ||
      (user?.role?.id === SUPERVISOR_ID &&
        hasPermission(user?.role, main, null, DELETE)) ||
      (user?.role?.id === OPERATOR_ID &&
        hasPermission(user?.role, main, null, DELETE)),
  );

  const options = {
    endpoint: 'occurrences',
    params: {
      id: occurrenceId,
    },
    eventListeners: [
      {
        name: 'storage',
        handler: event => onStart(event.data),
      },
      {
        name: 'update',
        handler: event => onUpdate(event.data),
      },
      {
        name: 'delete',
        handler: event => {
          const { user: userWhoDeleted } = JSON.parse(event.data);
          if (userWhoDeleted?.id !== user?.id) {
            setDeleteModal({});
            setIsLoading(true);
            toast.warning(
              `Esta ocorrência foi excluída pelo usuário: ${userWhoDeleted?.display_name}. Voltando para o Painel de Ocorrências.`,
              {
                autoClose: 3500,
              },
            );
            if (!routingIsLoading && !isLoading) {
              setTimeout(() => {
                setIsLoading(false);
                navigate(SUBROUTES.occurrences.path);
              }, 3500);
            }
          }
        },
      },
    ],
  };

  useLayoutEffect(() => {
    if (!occurrenceId) return;

    let success;

    const fetchOccurrence = async () => {
      ({ success } = await getOccurrence(occurrenceId));

      if (success) init(options);
    };

    fetchOccurrence();

    return () => {
      close();
      toast.dismiss();
    };
  }, []);

  useEffect(() => {
    if (!Object.keys(occurrenceInfo)?.length) return;

    if (occurrenceInfo?.statusId === 4)
      setClosureModal({
        isOpen: true,
        occurrenceInfo: occurrenceInfo,
      });
    if (!!closureModal?.isOpen && occurrenceInfo?.statusId === 5) {
      toast.info(
        `Ocorrência encerrada por: ${occurrenceInfo?.wasClosedBy?.name}`,
      );
      setClosureModal({});
    }
  }, [occurrenceInfo]);

  useEffect(() => {
    if (getItem('serviceId'))
      handleService({ start: false, serviceId: getItem('serviceId') });

    return async () => {
      if (getItem('serviceId'))
        await handleService({ start: false, serviceId: getItem('serviceId') });
    };
  }, []);

  useEffect(() => {
    if (
      !!occurrenceInfo?.isBeingServedBy?.id &&
      occurrenceInfo?.isBeingServedBy?.id !== user.id
    ) {
      setServiceHasChanged(true);
      removeItem('serviceId');
    }
  }, [occurrenceInfo, user]);

  useEffect(() => {
    if (occurrenceInfo?.statusId && !getItem('serviceId')) {
      switch (occurrenceInfo?.statusId) {
        case 2:
          return tabRef.current?.setTab(tabMapping['budgets']);
        case 3:
        case 4:
          return tabRef.current?.setTab(tabMapping['authorizations']);
        default:
          return tabRef.current?.setTab(tabMapping['basicInfos']);
      }
    }
  }, [occurrenceInfo?.statusId, getItem('serviceId')]);

  return (
    <StyledPage hidden={isLoading}>
      {routingIsLoading && <BlockHeader />}

      <LoadingScreen
        isLoading={isLoading || startingService}
        progress={progress}
      />

      <OccurrenceClosureModal
        {...closureModal}
        onClose={() => {
          setClosureModal({});
        }}
      />
      <OccurrenceDeleteModal
        {...deleteModal}
        onClose={() => {
          setDeleteModal({});
        }}
      />

      <Container $variant="map">
        <MapContainer
          plateOptions={plateOptions}
          routingIsLoading={routingIsLoading}
          setRoutingIsLoading={setRoutingIsLoading}
        />
      </Container>

      <Container $variant="form">
        <PageHeader
          titles={[
            ...(!occurrenceInfo?.id && canCreate
              ? ['Inserindo']
              : canEdit
                ? ['Editando']
                : ['Visualizando']),
            ...(occurrenceInfo?.numProtocol
              ? [`Ocorrência N° ${occurrenceInfo.numProtocol}`]
              : ['Nova Ocorrência']),
          ]}
          addRedirects={[
            {
              title: 'Voltar',
              disabled: routingIsLoading,
              goBack: true,
            },
          ]}
        />
        <Tabs
          ref={tabRef}
          tabs={[
            {
              name: tabMapping['basicInfos'],
              Icon: BasicInfoIcon,
            },
            ...(occurrenceInfo?.statusId > 1
              ? [
                  {
                    name: tabMapping['budgets'],
                    Icon: BudgetsIcon,
                  },
                ]
              : []),
            ...(occurrenceInfo?.statusId > 2
              ? [
                  {
                    name: tabMapping['authorizations'],
                    Icon: AuthorizationsIcon,
                  },
                ]
              : []),
            ...(occurrenceInfo?.id &&
            (user?.role?.id === DEV_ID ||
              user?.role?.id === SUPERVISOR_ID ||
              user?.role?.id === OPERATOR_ID)
              ? [
                  {
                    name: tabMapping['history'],
                    Icon: HistoryIcon,
                  },
                ]
              : []),
          ]}
          startTab={tabMapping['basicInfos']}
          onSwitchTab={async () => {
            if (occurrenceInfo?.isBeingServedBy?.id === user.id) {
              await handleService({ start: false });
            }
            if (
              JSON.stringify(oldPlateOptions) !== JSON.stringify(plateOptions)
            ) {
              setPlateOptions(oldPlateOptions);
            }
          }}
          blockSwitching={occurrenceInfo?.isBeingServedBy?.id === user.id}
          blockMessage="Você tem certeza que deseja trocar de aba? Lembre-se de que todas as informações não salvas serão perdidas.">
          <BasicInfosForm
            tabName={tabMapping['basicInfos']}
            routingIsLoading={routingIsLoading}
            canCreate={!occurrenceInfo?.id && canCreate}
            canEdit={canEdit}
            canDelete={canDelete}
            data={occurrenceData?.basicInfos}
            occurrenceInfo={occurrenceInfo}
            serviceHasChanged={serviceHasChanged}
            syncData={async () => await getOccurrence(occurrenceInfo?.id)}
            startService={() => {
              handleService({ start: true });
            }}
            resetService={resetService}
            plateOptions={plateOptions}
            setPlateOptions={setPlateOptions}
            onDeleteOccurrence={() =>
              setDeleteModal({
                isOpen: true,
                occurrenceInfo: occurrenceInfo,
              })
            }
          />

          {occurrenceInfo?.statusId > 1 && (
            <BudgetForms
              tabName={tabMapping['budgets']}
              routingIsLoading={routingIsLoading}
              canEdit={canEdit}
              canDelete={canDelete}
              data={occurrenceData?.budgets}
              occurrenceInfo={occurrenceInfo}
              serviceHasChanged={serviceHasChanged}
              syncData={async () => await getOccurrence(occurrenceInfo?.id)}
              startService={() => {
                handleService({ start: true });
              }}
              resetService={resetService}
              plateOptions={plateOptions}
              onDeleteOccurrence={() =>
                setDeleteModal({
                  isOpen: true,
                  occurrenceInfo: occurrenceInfo,
                })
              }
            />
          )}

          {occurrenceInfo?.statusId > 2 && (
            <Authorizations
              tabName={tabMapping['authorizations']}
              routingIsLoading={routingIsLoading}
              canEdit={canEdit}
              canDelete={canDelete}
              data={occurrenceData?.authorizations}
              occurrenceInfo={occurrenceInfo}
              serviceHasChanged={serviceHasChanged}
              syncData={async () => await getOccurrence(occurrenceInfo?.id)}
              startService={() => {
                handleService({ start: true });
              }}
              resetService={resetService}
              onDeleteOccurrence={() =>
                setDeleteModal({
                  isOpen: true,
                  occurrenceInfo: occurrenceInfo,
                })
              }
            />
          )}

          {!!occurrenceInfo?.id &&
            (user?.role?.id === DEV_ID ||
              user?.role?.id === SUPERVISOR_ID ||
              user?.role?.id === OPERATOR_ID) && (
              <History
                tabName={tabMapping['history']}
                routingIsLoading={routingIsLoading}
                canEdit={canEdit}
                canDelete={canDelete}
                occurrenceInfo={occurrenceInfo}
                serviceHasChanged={serviceHasChanged}
                syncData={async () => await getOccurrence(occurrenceInfo?.id)}
                startService={() => {
                  handleService({ start: true });
                }}
                resetService={resetService}
                onDeleteOccurrence={() =>
                  setDeleteModal({
                    isOpen: true,
                    occurrenceInfo: occurrenceInfo,
                  })
                }
              />
            )}
        </Tabs>
      </Container>
    </StyledPage>
  );
};

export default Form;
