import React, { useContext } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import Input from 'components/ui-kit/Input';
import PrimaryButton from 'components/ui-kit/PrimaryButton';

import useWindowSizes from 'hooks/useWindowSizes';
import {
  FIELD_IS_REQUIRED,
  INVALID_MIN_LENGTH,
  FAIL_CHANGE_PASSWORD,
} from 'utils/messages';
import { BEARER } from 'utils/constants';
import api from 'services/api';
import AuthContext from 'context/Auth';

const ChangeSession = styled.form`
  ${tw`w-2/6 flex flex-col gap-y-5 items-center`}
`;

const DataWrapper = styled.div`
  ${tw`gap-2 h-auto `}
`;

const DataWrapperButton = styled.div`
  ${tw`gap-1 h-auto flex flex-wrap items-center`}
`;

const Button = styled(PrimaryButton)`
  ${tw`bg-[linear-gradient(180deg, rgba(9, 28, 39, 1) 0%, #084A86 100%)] hover:opacity-[0.85] relative overflow-hidden`}
`;

const ChangePassword = ({ userData, setChangePassword }) => {
  const { width } = useWindowSizes();
  const { user } = useContext(AuthContext);

  const validationSchema = yup.object({
    password_current: yup.string().required(FIELD_IS_REQUIRED('Senha Atual')),
    password_new: yup
      .string()
      .min(5, INVALID_MIN_LENGTH('Nova Senha', 5))
      .required(FIELD_IS_REQUIRED('Nova Senha')),
    password_new_confirm: yup
      .string()
      .oneOf([yup.ref('password_new'), null], 'As senhas não correspondem')
      .required(FIELD_IS_REQUIRED('Confirmar Nova Senha')),
  });

  const onSubmit = async values => {
    try {
      if (user.role.display_name === 'Usuário') {
        const { status } = await api.put(
          `/users/${userData.id}/password/`,
          {
            password_new: values.password_new,
            password_new_confirm: values.password_new_confirm,
          },
          { headers: { Authorization: BEARER + user.token } },
        );
        if (status !== 200) throw new Error();
      } else {
        const { status } = await api.put(
          '/users/password/',
          {
            data: {
              password_current: values.password_current,
              password_new: values.password_new,
              password_new_confirm: values.password_new_confirm,
            },
          },
          { headers: { Authorization: BEARER + user.token } },
        );
        if (status !== 200) throw new Error();
      }
    } catch (error) {
      toast.error(FAIL_CHANGE_PASSWORD);
    }
  };

  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      data: {
        password_current: '',
        password_new: '',
        password_new_confirm: '',
      },
    },
    onSubmit,
    validationSchema,
  });

  return (
    <ChangeSession onSubmit={handleSubmit}>
      <DataWrapper>
        <Input
          id="password_current"
          type="password"
          value={values.password_current}
          label={{ text: 'Senha Atual' }}
          onChange={handleChange}
          width={width > 500 ? '20vw' : '50vw'}
          error={{
            isActive: touched.password_current && errors.password_current,
            message: errors.password_current,
          }}
        />
      </DataWrapper>
      <DataWrapper>
        <Input
          id="password_new"
          type="password"
          value={values.password_new}
          label={{ text: 'Nova Senha' }}
          onChange={handleChange}
          width={width > 500 ? '20vw' : '50vw'}
          error={{
            isActive: touched.password_new && errors.password_new,
            message: errors.password_new,
          }}
        />
      </DataWrapper>
      <DataWrapper>
        <Input
          id="password_new_confirm"
          type="password"
          value={values.password_new_confirm}
          label={{ text: 'Confirmar Nova Senha' }}
          onChange={handleChange}
          width={width > 500 ? '20vw' : '50vw'}
          error={{
            isActive:
              touched.password_new_confirm && errors.password_new_confirm,
            message: errors.password_new_confirm,
          }}
        />
      </DataWrapper>
      <DataWrapperButton>
        <Button type="submit" width="100px">
          Salvar
        </Button>
        <Button type="button" width="100px" onClick={() => setChangePassword()}>
          Fechar
        </Button>
      </DataWrapperButton>
    </ChangeSession>
  );
};
export default ChangePassword;
