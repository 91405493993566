import { useEffect } from 'react';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';

import styled from 'styled-components';
import tw from 'twin.macro';
import * as yup from 'yup';

import Input from 'components/ui-kit/Input';
import { AnimatePresence, motion } from 'framer-motion';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import { useNavigate, Link as LinkBase } from 'react-router-dom';

import { FIELD_IS_REQUIRED, INVALID_MIN_LENGTH } from 'utils/messages';
import { ROUTES } from 'utils/constants';

import LOGO from 'assets/logos/abc_logo_clean.png';

import video from 'assets/video/loginmapvideo.mp4';

const Container = styled(motion.div)`
  ${tw`flex w-screen h-screen overflow-hidden absolute`}
`;

const LoginContainer = styled.div`
  ${tw`w-full h-full absolute flex flex-col z-10 bg-black/60`}
  ${tw`mobileS:max-w-[320px] mobileM:max-w-[375px] mobileL:max-w-[425px] mobileXL:max-w-[480px] md:max-w-[480px]`}
`;

const LogoContainer = styled.div`
  ${tw`w-full h-[35%] items-center justify-center skew-y-6 flex bg-[linear-gradient(0deg, #0A1B24 0%, #084D8D 99.98%, #084A86 99.99%)]`}
  ${props => props.top && tw`relative bottom-10`}
  ${props => props.bottom && tw`relative top-10`}
`;

const FormContainer = styled.form`
  ${tw`w-full h-[50%] flex skew-y-6 flex-col items-center justify-center z-20 px-2`}

  & > * {
    ${tw`mb-3 last:mb-0`}
  }
`;

const Form = styled.div`
  ${tw`w-full h-fit flex flex-col justify-center items-center gap-4 -skew-y-6`}
`;

const Link = styled(LinkBase)`
  ${tw`[color: var(--gray-theme)] no-underline text-sm font-normal transition duration-200 hover:text-gray-200`}
`;

const Image = styled.img`
  ${tw`w-full max-w-sm md:max-w-full h-fit -skew-y-6`}
`;

const ErrorMessage = styled(motion.p)`
  ${tw`text-themes-red text-center`}
`;

const StyledInput = styled(Input)`
  ${tw`[color: var(--gray-theme)] focus:border-white placeholder:[color: var(--gray-theme)]`}
`;

const validationSchema = yup.object({
  username: yup.string().required(FIELD_IS_REQUIRED('Nome de Usuário')),
  password: yup
    .string()
    .min(5, INVALID_MIN_LENGTH('Senha', 5))
    .required(FIELD_IS_REQUIRED('Senha')),
});

const Login = () => {
  const { login, resetError, error } = useAuth();
  const navigate = useNavigate();

  const onSubmit = async values => {
    const response = await login(values);

    if (response.success) navigate(ROUTES.HOME);
  };

  const { handleSubmit, handleBlur, handleChange, values, touched, errors } =
    useFormik({
      initialValues: {
        username: '',
        password: '',
      },
      onSubmit,
      validationSchema,
    });

  useEffect(() => {
    if (
      (values.username.length > 0 && error) ||
      (values.password.length > 0 && error)
    ) {
      const timer = setTimeout(() => {
        resetError();
      }, 2500);
      return () => clearTimeout(timer);
    }
  }, [values, error]);

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}>
      <video
        autoPlay
        loop
        muted
        style={{
          boxShadow: 'inset 4px 15px 40px -27px rgba(0,0,0,0.75)',
          height: '100vh',
          width: '100vw',
          objectFit: 'cover',
        }}>
        <source src={video} type="video/mp4" />
      </video>

      <LoginContainer>
        <LogoContainer top>
          <Image src={LOGO} />
        </LogoContainer>
        <FormContainer onSubmit={handleSubmit}>
          <Form>
            <StyledInput
              id="username"
              background="transparent"
              placeholder="Nome de Usuário"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              maxWidth="250px"
              error={{
                isActive: touched.username && errors.username,
                message: errors.username,
              }}
            />

            <StyledInput
              isPassword
              eyeColor="var(--gray-theme)"
              background="transparent"
              id="password"
              autoComplete="on"
              placeholder="Senha"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              maxWidth="250px"
              error={{
                isActive: touched.password && errors.password,
                message: errors.password,
              }}
            />
            <AnimatePresence>
              {error && (
                <ErrorMessage
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}>
                  {error}
                </ErrorMessage>
              )}
            </AnimatePresence>
            <PrimaryButton type="submit">Entrar</PrimaryButton>

            <Link to={ROUTES.FORGOT_PASSWORD}>Esqueceu sua senha?</Link>
          </Form>
        </FormContainer>
        <LogoContainer bottom />
      </LoginContainer>
    </Container>
  );
};

export default Login;
