import { useEffect, useState, memo, useLayoutEffect, useCallback } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Tooltip } from 'react-tooltip';

import PrimaryButton from 'components/ui-kit/PrimaryButton';
import TitleBox from 'components/ui-kit/TitleBox';
import IconButton from 'components/ui-kit/IconButton';

import LocationModal from './LocationModal';

import { variant } from 'utils/functions';

const Container = styled.div`
  ${tw`flex flex-col lg:flex-row h-fit w-full gap-4`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-col gap-2 w-full h-full items-center`}
`;

const Text = styled.span`
  ${tw`text-sm text-center text-disabled-dark max-w-full`}

  ${({ $variant }) =>
    variant({
      label: tw`font-semibold`,
      value: tw`truncate`,
      notFound: tw`italic text-[var(--gray-dark)] font-semibold truncate`,
    })({ $variant })}
`;

const Row = styled.div`
  ${tw`flex flex-col sm:flex-row justify-center items-center h-fit w-full max-w-full gap-y-2 gap-x-5 py-2 px-4 bg-[var(--gray-theme)] rounded-lg overflow-hidden`}
`;

const Item = styled.div`
  ${tw`flex h-fit w-full justify-center items-center gap-1 overflow-hidden`}

  max-width: ${({ $maxWidth }) => $maxWidth || '100%'};
`;

const ADDITIONS_LIMIT = 5;

const LocationsInsert = ({
  locationsInserted = [],
  onLocationsChange = () => {},
  isDisabled = false,
  serviceHasChanged = false,
}) => {
  const [data, setData] = useState([]);

  const [modalData, setModalData] = useState({});

  const updateData = newData => {
    setData(newData);
    onLocationsChange({
      locations: newData?.map(loc => ({
        id: loc?.id,
        origin: {
          cityId: loc.origin?.cityId,
          cityName: loc.origin?.cityName,
          stateShortCode: loc.origin?.stateShortCode,
          position: loc.origin?.position || [],
        },
        destiny: {
          cityId: loc.destiny?.cityId,
          cityName: loc.destiny?.cityName,
          stateShortCode: loc.destiny?.stateShortCode,
          position: loc.destiny?.position || [],
        },
      })),
    });
  };

  const onAddLocation = useCallback(
    (location, type) => {
      const newData = (prevData => {
        if (type === 'origin') {
          if (
            data.length >= ADDITIONS_LIMIT &&
            data.every(item => item?.origin && !!item?.origin)
          ) {
            return;
          }
          const index = prevData.findIndex(
            item => item.destiny && !item?.origin?.cityId,
          );

          if (index !== -1) {
            return [
              ...prevData.slice(0, index),
              { ...prevData[index], origin: location },
              ...prevData.slice(index + 1),
            ];
          } else {
            return [...prevData, { origin: location }];
          }
        } else if (type === 'destiny') {
          if (
            data.length >= ADDITIONS_LIMIT &&
            data.every(item => item?.destiny && !!item?.destiny)
          ) {
            return;
          }
          const index = prevData.findIndex(
            item => item.origin && !item?.destiny?.cityId,
          );

          if (index !== -1) {
            return [
              ...prevData.slice(0, index),
              { ...prevData[index], destiny: location },
              ...prevData.slice(index + 1),
            ];
          } else {
            return [...prevData, { destiny: location }];
          }
        }
      })(data);
      updateData(newData);
    },
    [data],
  );

  const handleRemoveOrigin = useCallback(
    indexToRemove => {
      const newData = (prevData => {
        let newData = prevData.map((item, index) => {
          if (index === indexToRemove) {
            return { ...item, origin: {} };
          }
          return item;
        });

        newData = newData.filter(
          item => item?.origin?.cityId || item?.destiny?.cityId,
        );

        return newData;
      })(data);
      updateData(newData);
    },
    [data],
  );

  const handleRemoveDestiny = useCallback(
    indexToRemove => {
      const newData = (prevData => {
        let newData = prevData.map((item, index) => {
          if (index === indexToRemove) {
            return { ...item, destiny: {} };
          }
          return item;
        });

        newData = newData.filter(
          item => item?.origin?.cityId || item?.destiny?.cityId,
        );

        return newData;
      })(data);
      updateData(newData);
    },
    [data],
  );

  useEffect(() => {
    if (serviceHasChanged) setModalData({});
  }, [serviceHasChanged]);

  useLayoutEffect(() => {
    if (locationsInserted.length === 0) return;

    setData(locationsInserted);
  }, [locationsInserted]);

  return (
    <>
      <LocationModal
        {...modalData}
        onAddLocation={onAddLocation}
        onClose={() => setModalData({})}
      />
      <TitleBox
        title={{ text: 'Localizações' }}
        type="line"
        style={{ margin: '1rem 0 1rem 0' }}
      />
      <Container>
        <Wrapper>
          {!isDisabled && (
            <PrimaryButton
              type="button"
              onClick={() =>
                setModalData({
                  isOpen: true,
                  type: 'origin',
                  title: 'Local de Origem',
                  isDisabled:
                    data.length >= ADDITIONS_LIMIT &&
                    data.every(item => item?.origin && !!item?.origin),
                })
              }>
              Adicionar Local de Origem
            </PrimaryButton>
          )}

          {data?.map((loc, index) => (
            <Row key={index}>
              {loc?.origin?.cityId ? (
                <>
                  <Item $maxWidth="80%">
                    <Text $variant="label">Origem:</Text>
                    <Text
                      $variant="value"
                      data-tooltip-id={String(loc?.origin?.cityId)}
                      data-tooltip-content={`${loc?.origin?.cityName || '-'} / ${loc?.origin?.stateShortCode || '-'}`}>
                      {`${loc?.origin?.cityName || '-'} / ${loc?.origin?.stateShortCode || '-'}`}
                    </Text>
                  </Item>
                  {!isDisabled && (
                    <IconButton
                      type="deactivate"
                      tooltipText={'Remover'}
                      onClick={() => handleRemoveOrigin(index)}
                    />
                  )}
                  <Tooltip id={String(loc?.origin?.cityId)} place="bottom" />
                </>
              ) : (
                <Text $variant="notFound">
                  Nenhum local de origem selecionado
                </Text>
              )}
            </Row>
          ))}
        </Wrapper>

        <Wrapper>
          {!isDisabled && (
            <PrimaryButton
              type="button"
              onClick={() =>
                setModalData({
                  isOpen: true,
                  type: 'destiny',
                  title: 'Local de Destino',
                  isDisabled:
                    data.length >= ADDITIONS_LIMIT &&
                    data.every(item => item?.destiny && !!item?.destiny),
                })
              }>
              Adicionar Local de Destino
            </PrimaryButton>
          )}

          {data?.map((loc, index) => (
            <Row key={index}>
              {loc?.destiny?.cityId ? (
                <>
                  <Item $maxWidth="80%">
                    <Text $variant="label">Destino:</Text>
                    <Text
                      $variant="value"
                      data-tooltip-id={String(loc?.destiny?.cityId)}
                      data-tooltip-content={`${loc?.destiny?.cityName || '-'} / ${loc?.destiny?.stateShortCode || '-'}`}>
                      {`${loc?.destiny?.cityName || '-'} / ${loc?.destiny?.stateShortCode || '-'}`}
                    </Text>
                  </Item>
                  {!isDisabled && (
                    <IconButton
                      type="deactivate"
                      tooltipText={'Remover'}
                      onClick={() => handleRemoveDestiny(index)}
                    />
                  )}
                  <Tooltip id={String(loc?.destiny?.cityId)} place="bottom" />
                </>
              ) : (
                <Text $variant="notFound">
                  Nenhum local de destino selecionado
                </Text>
              )}
            </Row>
          ))}
        </Wrapper>
      </Container>
      {data.length === 0 && (
        <div
          style={{
            display: 'flex',
            height: '60px',
            alignItems: 'center',
            maxWidth: '100%',
          }}>
          <Text $variant="notFound">Nenhum local selecionado</Text>
        </div>
      )}
    </>
  );
};

export default memo(LocationsInsert);
