import {
  useState,
  useLayoutEffect,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { ThreeDots } from 'react-loader-spinner';
import { IMaskInput } from 'react-imask';
import { validate as isCNPJ } from 'cnpj';
import phone from 'phone';

import PageContainer from 'components/ui-kit/PageContainer';
import PageHeader from 'components/ui-kit/PageHeader';
import Input from 'components/ui-kit/Input';
import Select from 'components/ui-kit/Select';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import TitleBox from 'components/ui-kit/TitleBox';
import Textarea from 'components/ui-kit/Textarea';
import IconButton from 'components/ui-kit/IconButton';
import Checkbox from 'components/ui-kit/Checkbox';

import api from 'services/api';
import { BEARER, SUBROUTES, MODULES, UPDATE, CREATE } from 'utils/constants';
import {
  buildPayload,
  validateLandlineBR,
  hasPermission,
  variant,
} from 'utils/functions';
import { onlyNumbers } from 'utils/regex';
import Formatter from 'utils/formatter';

import {
  BASIC_INFOS,
  FIELD_IS_REQUIRED,
  ALREADY_SAVED_INFORMATION,
  SUCCESS_SERVICE_PROVIDER,
  ERROR_SERVICE_PROVIDER,
  ERROR_ALREADY_EXISTS,
  UNIQUE_PHONE,
} from 'utils/messages';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

const { subroutes } = SUBROUTES.register;
const { main, sub } = MODULES.register;

const Container = styled.form`
  ${tw`h-fit w-full rounded-2xl bg-white px-2 py-4`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-wrap w-full h-fit justify-center items-center py-2 gap-5`}
`;

const ContactInsert = styled.div`
  ${tw`flex flex-col w-full gap-2 items-center`}

  .notFound {
    ${tw`flex flex-col w-full min-h-[100px] justify-center items-center`}
  }
`;

const Row = styled.div`
  ${tw`flex flex-wrap justify-between items-center h-fit w-full max-w-xl gap-y-2 gap-x-5 py-2 px-4 bg-[var(--gray-theme)] rounded-lg`}
`;

const Item = styled.div`
  ${tw`flex h-fit w-fit max-w-[250px] items-center gap-1 overflow-hidden`}
`;

const Text = styled.span`
  ${tw`text-sm text-center text-disabled-dark`}

  ${({ $variant }) =>
    variant({
      label: tw`font-semibold`,
      value: tw`truncate`,
      notFound: tw`italic text-[var(--gray-dark)] font-semibold truncate`,
    })({ $variant })}
`;

const TypesServicesContainer = styled.div`
  ${tw`flex flex-wrap gap-6 w-full h-fit items-center justify-center`}
`;

const keyMapping = {
  businessName: 'name',
  fantasyName: 'fantasy_name',
  businessTaxId: 'gov_registry',
  zipCode: 'cep',
  countryId: 'country_id',
  stateId: 'uf_id',
  cityId: 'city_id',
  street: 'address',
  complement: 'complement',
  number: 'place_number',
  neighborhood: 'neighborhood',
  contacts: 'contacts',
  typesServices: 'equipaments_ids',
  comments: 'description',
};

const getPayload = (values, data) => {
  const transformContactsData = data =>
    data['contacts']?.map(contact => ({
      name: contact.name,
      telephone: contact.number,
      ddi: contact.ddi,
    }));

  let newData = { ...data };
  let newValues = { ...values };

  newData['contacts'] = transformContactsData(newData);
  newValues['contacts'] = transformContactsData(newValues);
  newData['typesServices'] = newData?.typesServices?.map(type => type.id);
  newValues['typesServices'] = newValues?.typesServices?.map(type => type.id);

  return buildPayload(keyMapping, newValues, newData);
};

const validationSchema = yup.object({
  businessName: yup.string().required(FIELD_IS_REQUIRED('Nome Empresarial')),
  fantasyName: yup.string().required(FIELD_IS_REQUIRED('Nome Fantasia')),
  businessTaxId: yup
    .string()
    .required(FIELD_IS_REQUIRED('CNPJ'))
    .test('businessTaxId', 'CNPJ inválido', value => {
      return isCNPJ(value);
    }),
  zipCode: yup
    .string()
    .min(8, 'O CEP deve ter no mínimo 8 caracteres')
    .required(FIELD_IS_REQUIRED('CEP')),
  countryId: yup.number().required(FIELD_IS_REQUIRED('País')),
  stateId: yup.number().required(FIELD_IS_REQUIRED('Estado')),
  cityId: yup.number().required(FIELD_IS_REQUIRED('Cidade')),
  street: yup.string().required(FIELD_IS_REQUIRED('Logradouro')),
  complement: yup.string().notRequired(),
  number: yup.string().required(FIELD_IS_REQUIRED('Número')),
  neighborhood: yup.string().required(FIELD_IS_REQUIRED('Bairro')),
  contacts: yup
    .array(yup.object().required())
    .min(1, 'Pelo menos um contato deve ser adicionado'),
  typesServices: yup
    .array(yup.object().required())
    .min(1, 'Pelo menos um tipo de serviço deve ser selecionado'),
  comments: yup.string().notRequired(),
});

const Form = () => {
  const [data, setData] = useState({});

  const [typesServices, setTypesServices] = useState([]);
  const [isLoadingTypes, setIsLoadingTypes] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [phoneName, setPhoneName] = useState('');
  const [phoneType, setPhoneType] = useState(1);
  const [phoneDDI, setPhoneDDI] = useState('+55');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();

  const countrySelectRef = useRef(null);
  const stateSelectRef = useRef(null);
  const citySelectRef = useRef(null);

  const { phoneMask } = new Formatter();

  const canEdit = hasPermission(
    user?.role,
    main,
    sub.servicesProviders,
    UPDATE,
  );
  const canCreate = hasPermission(
    user?.role,
    main,
    sub.servicesProviders,
    CREATE,
  );

  const typesServicesUnique = useMemo(() => {
    return typesServices.filter(
      (value, index, self) => index === self.findIndex(t => t.id === value.id),
    );
  }, [typesServices]);

  const getTypesServices = useCallback(async () => {
    if (
      (!!Object.keys(data).length && !canEdit) ||
      (!Object.keys(data).length && !canCreate)
    )
      return;

    setIsLoadingTypes(true);

    try {
      const { data: response, status } = await api.get(`tows/equipaments/`, {
        headers: {
          Authorization: BEARER + user.token,
        },
        params: {
          active: true,
        },
      });

      if (status !== 200) throw new Error();

      const typesServicesLog =
        response?.data?.map(item => ({
          id: item.id !== null ? item.id : undefined,
          name: item?.name,
        })) || [];

      setTypesServices(prev => [...prev, ...typesServicesLog]);
    } catch {
      return;
    } finally {
      setIsLoadingTypes(false);
    }
  }, [user, data, canEdit, canCreate]);

  const getCompany = useCallback(
    async companyId => {
      setIsLoading(true);
      setData({});

      try {
        const { data: response, status } = await api.get(`tows/${companyId}`, {
          onProgress,
          headers: {
            Authorization: BEARER + user.token,
          },
        });

        if (status !== 200) throw new Error();

        const {
          id,
          name: businessName,
          fantasy_name: fantasyName,
          gov_registry: businessTaxId,
          locality: address,
          contacts,
          equipaments: typesServices,
          description: comments,
        } = response.data;

        const companyLog = {
          id,
          businessName: businessName || '',
          fantasyName: fantasyName || '',
          businessTaxId: businessTaxId || '',
          zipCode: address?.cep || '',
          countryId: address?.city?.state?.country?.id,
          countryName: address?.city?.state?.country?.name,
          stateId: address?.city?.state?.id,
          stateName: address?.city?.state?.name,
          cityId: address?.city?.id,
          cityName: address?.city?.name,
          street: address?.address || '',
          complement: address?.complement || '',
          number: address?.place_number !== null ? address.place_number : '',
          neighborhood: address?.neighborhood || '',
          contacts:
            contacts?.map(item => ({
              id: item?.id,
              name: item?.name,
              number: item?.telephone,
              ddi: item?.ddi,
            })) || [],
          typesServices:
            typesServices?.map(item => ({
              id: item.id !== null ? item.id : undefined,
              name: item?.name,
            })) || [],
          comments: comments || '',
        };

        setTypesServices(companyLog.typesServices);

        setValues(companyLog);
        setData(companyLog);

        countrySelectRef?.current?.setValue({
          value: companyLog.countryId,
          label: companyLog?.countryName,
          autoSelection: true,
        });

        stateSelectRef?.current?.setValue({
          value: companyLog.stateId,
          label: companyLog?.stateName,
          autoSelection: true,
        });

        citySelectRef?.current?.setValue({
          value: companyLog?.cityId,
          label: companyLog?.cityName,
          autoSelection: true,
        });

        resetProgress();
        setIsLoading(false);
      } catch (error) {
        toast.error(ERROR_SERVICE_PROVIDER('buscar'));

        setTimeout(() => {
          setIsLoading(false);
          resetProgress();
          navigate(subroutes.servicesProviders.path, {
            state: { currentPage: state?.currentPage },
          });
        }, 3500);
      }
    },
    [user],
  );

  const onSubmit = useCallback(
    async values => {
      setIsLoading(true);

      const payload = getPayload(values, data);

      if (Object.keys(payload).length === 0) {
        toast.success(ALREADY_SAVED_INFORMATION);
        setIsLoading(false);
        return;
      }

      try {
        const { status } = data.id
          ? await api.put(
              `tows/${data.id}`,
              { data: payload },
              {
                onProgress,
                headers: {
                  Authorization: BEARER + user.token,
                },
              },
            )
          : await api.post(
              'tows/',
              { data: payload },
              {
                onProgress,
                headers: {
                  Authorization: BEARER + user.token,
                },
              },
            );

        if (status !== 200 && status !== 201) throw new Error();

        let successMessage =
          Object.keys(data).length > 0
            ? SUCCESS_SERVICE_PROVIDER('editado')
            : SUCCESS_SERVICE_PROVIDER('criado');

        toast.success(successMessage);

        setTimeout(() => {
          navigate(subroutes.servicesProviders.path, {
            state: { currentPage: state?.currentPage },
          });
          resetProgress();
          setIsLoading(false);
        }, [2000]);
      } catch (error) {
        let errorMessage;
        switch (error.response?.status) {
          case 403:
            errorMessage =
              'Erro ao inserir informações. CEP informado não pertence à Cidade selecionada.';
            break;
          case 404:
            errorMessage =
              'Erro ao inserir informações. CEP informado não foi encontrado.';
            break;
          case 409:
            errorMessage = ERROR_ALREADY_EXISTS;
            break;
          default:
            errorMessage =
              Object.keys(data).length > 0
                ? ERROR_SERVICE_PROVIDER('editar')
                : ERROR_SERVICE_PROVIDER('criar');
        }
        toast.error(errorMessage);
        resetProgress();
        setIsLoading(false);
      }
    },
    [user, data, state],
  );

  const {
    handleSubmit,
    setValues,
    setFieldValue,
    handleChange,
    submitCount,
    values,
    errors,
  } = useFormik({
    initialValues: {
      businessName: '',
      fantasyName: '',
      businessTaxId: '',
      zipCode: '',
      countryId: 1,
      stateId: undefined,
      cityId: undefined,
      street: '',
      complement: '',
      number: '',
      neighborhood: '',
      contacts: [],
      typesServices: [],
      comments: '',
    },
    onSubmit,
    validationSchema,
  });

  const onSearchByZipCode = useCallback(
    async zipCode => {
      setIsLoading(true);

      try {
        const { data: response, status } = await api.get(
          '/georef/ceps/' + zipCode,
          {
            onProgress,
            headers: {
              Authorization: BEARER + user.token,
            },
          },
        );

        if (status !== 200) throw new Error();

        const { city } = response.data;

        countrySelectRef?.current?.setValue({
          value: city?.state?.country?.id,
          label: city?.state?.country?.name,
          autoSelection: true,
        });

        stateSelectRef?.current?.setValue({
          value: city?.state?.id,
          label: city?.state?.name,
          autoSelection: true,
        });

        citySelectRef?.current?.setValue({
          value: city?.id,
          label: city?.name,
          autoSelection: true,
        });

        setValues(prev => ({
          ...prev,
          countryId: city?.state?.country?.id,
          stateId: city?.state?.id,
          cityId: city?.id,
        }));
      } catch {
        toast.error('CEP não encontrado');
      } finally {
        resetProgress();
        setIsLoading(false);
      }
    },
    [countrySelectRef, stateSelectRef, citySelectRef],
  );

  const addContact = useCallback(() => {
    if (phoneDDI === '+55' && phoneType === 2) {
      if (!validateLandlineBR(phoneNumber)) {
        setInvalidPhoneNumber(true);
        setTimeout(() => {
          if (invalidPhoneNumber) setInvalidPhoneNumber(false);
        }, 4000);
        return;
      }
    } else {
      const { isValid } = phone(`${phoneDDI}${phoneNumber}`);

      if (!isValid) {
        setInvalidPhoneNumber(true);
        setTimeout(() => {
          if (invalidPhoneNumber) setInvalidPhoneNumber(false);
        }, 4000);
        return;
      }
    }

    const isDuplicate = values.contacts?.some(
      contact => contact.number === phoneNumber,
    );

    if (isDuplicate) {
      toast.warning(UNIQUE_PHONE);
      return;
    } else {
      const formatName = value => {
        if (!value) return undefined;

        return value.trim();
      };

      const newContact = {
        name: formatName(phoneName),
        number: phoneNumber,
        ddi: phoneDDI,
      };
      setValues(prev => ({
        ...prev,
        contacts: [...prev.contacts, newContact],
      }));

      setPhoneName('');
      setPhoneNumber('');
    }
  }, [
    phoneName,
    phoneType,
    phoneNumber,
    phoneDDI,
    values.contacts,
    invalidPhoneNumber,
  ]);

  const removeContact = useCallback(
    ({ number, ddi }) => {
      const updatedContacts = values.contacts.filter(
        contact => contact.number !== number || contact.ddi !== ddi,
      );

      setValues(prev => ({
        ...prev,
        contacts: updatedContacts,
      }));
    },
    [values.contacts],
  );

  useLayoutEffect(() => {
    const fetchData = async () => {
      if (id) await getCompany(id);
      await getTypesServices();
    };
    fetchData();

    return () => {
      toast.dismiss();
    };
  }, [id]);

  useEffect(() => {
    const activeErrors = Object.keys(errors)
      .filter(
        error =>
          (errors[error] === errors.contacts ||
            errors[error] === errors.typesServices) &&
          submitCount > 0,
      )
      .map(error => errors[error]);

    if (activeErrors.length > 0) {
      toast.error(activeErrors.join('.\n'));
    }
  }, [submitCount]);

  useEffect(() => {
    if (values.zipCode?.length !== 8 || values.zipCode === data.zipCode) return;

    const fetchSearchByZipCode = async () => {
      await onSearchByZipCode(values.zipCode);
    };

    fetchSearchByZipCode();
  }, [values.zipCode, data?.zipCode]);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={[
          'Cadastros',
          'Prestadores de Serviço',
          data?.businessName
            ? `Editando: ${data.businessName}`
            : 'Novo Prestador de Serviço',
        ]}
        addRedirects={[
          {
            title: 'Voltar',
            disabled: isLoading,
            path: subroutes.servicesProviders.path,
            state: { currentPage: state?.currentPage },
          },
        ]}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <Container onSubmit={handleSubmit}>
        <TitleBox
          title={{ text: BASIC_INFOS }}
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2rem',
          }}>
          <Wrapper>
            <Input
              isRequired
              id="businessName"
              maxWidth="500px"
              value={values.businessName}
              maxLength={100}
              onChange={handleChange}
              placeholder=""
              label={{
                text: 'Nome Empresarial (Razão social)',
              }}
              error={{
                isActive: submitCount > 0 && errors.businessName,
                message: errors.businessName,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />

            <Input
              isRequired
              id="fantasyName"
              maxWidth="350px"
              value={values.fantasyName}
              maxLength={80}
              onChange={handleChange}
              placeholder=""
              label={{
                text: 'Nome Fantasia',
              }}
              error={{
                isActive: submitCount > 0 && errors.fantasyName,
                message: errors.fantasyName,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />

            <Input
              isRequired
              id="businessTaxId"
              as={IMaskInput}
              unmask={true}
              mask="00.000.000/0000-00"
              maxWidth="200px"
              placeholder=""
              label={{ text: 'CNPJ' }}
              value={values.businessTaxId}
              onAccept={value => setFieldValue('businessTaxId', value)}
              error={{
                isActive: submitCount > 0 && errors.businessTaxId,
                message: errors.businessTaxId,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />
          </Wrapper>

          <TitleBox type="line" title={{ text: 'Endereço' }} />

          <Wrapper>
            <Input
              isRequired
              id="zipCode"
              as={IMaskInput}
              unmask={true}
              mask="00000-000"
              maxWidth="120px"
              placeholder=""
              label={{ text: 'CEP' }}
              value={values.zipCode}
              onAccept={value => setFieldValue('zipCode', value)}
              error={{
                isActive: submitCount > 0 && errors.zipCode,
                message: errors.zipCode,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />

            <Select
              isRequired
              ref={countrySelectRef}
              size="sm"
              maxWidth="200px"
              fieldToOptionValue="id"
              fieldToOptionLabel="name"
              endpoint="georef/countries"
              onOptionChange={option => {
                if (values.stateId) {
                  stateSelectRef.current?.clearValue();
                }

                if (values.zipCode && option?.value !== values.countryId)
                  setFieldValue('zipCode', '');

                setFieldValue('countryId', option?.value);
              }}
              placeholder=""
              label={{
                text: 'País',
              }}
              initialValue={{
                label: 'Brasil',
                value: 1,
              }}
              error={{
                isActive: submitCount > 0 && errors.countryId,
                message: errors.countryId,
              }}
              isDisabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />

            <Select
              isRequired
              ref={stateSelectRef}
              size="sm"
              maxWidth="250px"
              fieldToOptionValue="id"
              fieldToOptionLabel="name"
              endpoint={`georef/states/?country_id=${values.countryId}`}
              onOptionChange={option => {
                if (values.cityId) {
                  citySelectRef.current?.clearValue();
                }

                if (values.zipCode && option?.value !== values.stateId)
                  setFieldValue('zipCode', '');

                setFieldValue('stateId', option?.value);
              }}
              placeholder=""
              label={{
                text: 'Estado',
              }}
              error={{
                isActive: submitCount > 0 && errors.stateId,
                message: errors.stateId,
              }}
              isDisabled={
                !values.countryId ||
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />

            <Select
              isRequired
              ref={citySelectRef}
              size="sm"
              maxWidth="300px"
              fieldToOptionValue="id"
              fieldToOptionLabel="name"
              endpoint={`georef/cities/?uf_id=${values.stateId}`}
              onOptionChange={option => {
                if (values.zipCode && option?.value !== values.cityId)
                  setFieldValue('zipCode', '');

                setFieldValue('cityId', option?.value);
              }}
              placeholder=""
              label={{
                text: 'Cidade',
              }}
              error={{
                isActive: submitCount > 0 && errors.cityId,
                message: errors.cityId,
              }}
              isDisabled={
                !values.stateId ||
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />

            <Input
              isRequired
              id="street"
              maxWidth="400px"
              value={values.street}
              maxLength={150}
              onChange={handleChange}
              placeholder=""
              label={{
                text: 'Logradouro',
              }}
              error={{
                isActive: submitCount > 0 && errors.street,
                message: errors.street,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />

            <Input
              id="complement"
              maxWidth="200px"
              value={values.complement}
              maxLength={50}
              onChange={handleChange}
              placeholder=""
              label={{
                text: 'Complemento',
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />

            <Input
              isRequired
              id="number"
              maxWidth="100px"
              value={values.number}
              maxLength={20}
              onChange={e => {
                if (!e.target.value.match(onlyNumbers)) return;
                setFieldValue('number', e.target.value);
              }}
              placeholder=""
              label={{
                text: 'Número',
              }}
              error={{
                isActive: submitCount > 0 && errors.number,
                message: errors.number,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />

            <Input
              isRequired
              id="neighborhood"
              maxWidth="300px"
              value={values.neighborhood}
              maxLength={100}
              onChange={handleChange}
              placeholder=""
              label={{
                text: 'Bairro',
              }}
              error={{
                isActive: submitCount > 0 && errors.neighborhood,
                message: errors.neighborhood,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />
          </Wrapper>

          <TitleBox
            type="line"
            title={{ text: 'Contato(s)' }}
            error={submitCount > 0 && errors.contacts}
          />

          <ContactInsert>
            {((!!Object.keys(data).length && canEdit) ||
              (!Object.keys(data).length && canCreate)) && (
              <Wrapper>
                <Input
                  id="phoneName"
                  maxWidth="300px"
                  value={phoneName}
                  maxLength={50}
                  onChange={e => setPhoneName(e.target.value)}
                  placeholder=""
                  label={{
                    text: 'Nome',
                  }}
                />

                <Select
                  isClearable={false}
                  size="sm"
                  maxWidth="150px"
                  options={[
                    {
                      label: 'Celular',
                      value: 1,
                    },
                    {
                      label: 'Fixo',
                      value: 2,
                    },
                  ]}
                  onOptionChange={option => {
                    if (phoneNumber) {
                      setPhoneNumber('');
                    }
                    setPhoneType(option?.value);
                  }}
                  placeholder=""
                  label={{
                    text: 'Tipo',
                  }}
                  initialValue={{
                    label: 'Celular',
                    value: 1,
                  }}
                />

                <Select
                  isClearable={false}
                  size="sm"
                  maxWidth="150px"
                  fieldToOptionValue="ddi"
                  fieldToOptionLabel={item => `${item?.ddi} (${item?.name})`}
                  endpoint="georef/countries"
                  onOptionChange={option => {
                    setPhoneDDI(option?.value);
                  }}
                  placeholder=""
                  label={{
                    text: 'DDI',
                  }}
                  initialValue={{
                    label: '+55 (Brasil)',
                    value: '+55',
                  }}
                />

                <Input
                  id="phoneNumber"
                  as={IMaskInput}
                  unmask={true}
                  mask={phoneType === 2 ? '(00) 0000-0000' : '(00) 00000-0000'}
                  maxWidth="200px"
                  placeholder=""
                  label={{ text: '(DDD) + Número' }}
                  value={phoneNumber}
                  onAccept={value => setPhoneNumber(value)}
                  error={{
                    isActive: invalidPhoneNumber,
                    message: 'Número de Telefone inválido',
                  }}
                  disabled={!phoneType || !phoneDDI}
                />

                <PrimaryButton type="button" onClick={addContact}>
                  Adicionar Contato
                </PrimaryButton>
              </Wrapper>
            )}

            {values.contacts?.length > 0 ? (
              values.contacts.map((item, index) => (
                <Row key={index}>
                  <Item>
                    <Text $variant="label">Nome:</Text>
                    <Text
                      $variant="value"
                      data-tooltip-id={String(index)}
                      data-tooltip-content={item?.name || 'Não cadastrado'}>
                      {item?.name || 'Não cadastrado'}
                    </Text>
                  </Item>

                  <Item>
                    <Text $variant="label">Número:</Text>
                    <Text
                      $variant="value"
                      style={{ cursor: 'pointer' }}
                      data-tooltip-id={String(index)}
                      data-tooltip-content="Clique para copiar o contato"
                      onClick={() => {
                        navigator.clipboard.writeText(item.ddi + item.number);
                        toast.success(
                          'Contato copiado para área de transferência.',
                        );
                      }}>{`${item?.ddi || '-'} ${phoneMask(item?.number) || '-'}`}</Text>
                  </Item>

                  {((!!Object.keys(data).length && canEdit) ||
                    (!Object.keys(data).length && canCreate)) && (
                    <IconButton
                      type="deactivate"
                      tooltipText={'Remover'}
                      onClick={() =>
                        removeContact({
                          number: item?.number,
                          ddi: item?.ddi,
                        })
                      }
                    />
                  )}
                  <Tooltip id={String(index)} place="bottom" />
                </Row>
              ))
            ) : (
              <div className="notFound">
                <Text $variant="notFound">Nenhum Contato cadastrado</Text>
              </div>
            )}
          </ContactInsert>

          <TitleBox
            type="line"
            title={{ text: 'Tipos de Serviços Prestados' }}
            error={submitCount > 0 && errors.typesServices}
          />

          <TypesServicesContainer>
            {isLoadingTypes ? (
              <ThreeDots
                visible
                width={40}
                height={10}
                color="var(--gray-dark)"
              />
            ) : typesServicesUnique.length === 0 ? (
              <Text $variant="notFound">Nenhum Tipo de Serviço encontrado</Text>
            ) : (
              typesServicesUnique.map(type => (
                <Checkbox
                  key={type.id}
                  id={type.id}
                  label={{ text: type.name }}
                  isChecked={values.typesServices?.some(
                    item => item.id === type.id,
                  )}
                  onChange={() => {
                    const updatedCheck = values.typesServices?.some(
                      item => item.id === type.id,
                    )
                      ? values.typesServices.filter(item => item.id !== type.id)
                      : [...values.typesServices, type];
                    setFieldValue('typesServices', updatedCheck);
                  }}
                  isDisabled={
                    (!!Object.keys(data).length && !canEdit) ||
                    (!Object.keys(data).length && !canCreate)
                  }
                />
              ))
            )}
          </TypesServicesContainer>

          <Textarea
            id="comments"
            placeholder="Observações"
            maxWidth="600px"
            height="100px"
            value={values.comments}
            maxLength={200}
            onChange={handleChange}
            disabled={
              (!!Object.keys(data).length && !canEdit) ||
              (!Object.keys(data).length && !canCreate)
            }
          />

          {((!!Object.keys(data).length && canEdit) ||
            (!Object.keys(data).length && canCreate)) && (
            <PrimaryButton
              type="submit"
              maxWidth="300px"
              bg="green"
              disabled={isLoading}>
              Salvar
            </PrimaryButton>
          )}
        </TitleBox>
      </Container>
    </PageContainer>
  );
};

export default Form;
