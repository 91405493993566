import { format, isValid, sub as subtract } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { getCenter, isValidCoordinate } from 'geolib';
import { saveAs } from 'file-saver';

export const variant =
  (variants, variantPropName = '$variant') =>
  props => {
    const variantKey = props[variantPropName];
    return variants[variantKey] || '';
  };

export const limitStringSize = (string = '', maxSize = 30) => {
  if (!string) return;

  if (string.length < maxSize) return string;

  return `${string.slice(0, maxSize)}...`;
};

export const formatDate = (date = new Date()) => {
  if (!isValid(date)) return;

  return format(date, 'dd/MM/yyyy');
};

export const defaultDateFormat = (
  date,
  time = true,
  onlyTime = false,
  type = '',
) => {
  let targetDate = new Date(date);

  if (type === 'montly') targetDate = formatDate(date);

  if (!isValid(targetDate)) return;

  if (onlyTime) return format(targetDate, 'HH:mm:ss');
  else if (!time) return format(targetDate, 'dd/MM/yyyy');

  return format(targetDate, 'dd/MM/yyyy - HH:mm');
};

export const getBackendDateFormat = (date, time = true) => {
  const timeZone = 'America/Sao_Paulo';
  const zonedDate = utcToZonedTime(date, timeZone);

  if (time) return format(zonedDate, "yyyy-MM-dd'T'HH:mm:ssXXX");

  return format(zonedDate, 'yyyy-MM-dd');
};

export const renameObjectProperties = (obj, propertyMapping) => {
  const newObj = {};

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key) && propertyMapping.hasOwnProperty(key)) {
      newObj[propertyMapping[key]] = obj[key];
    } else {
      newObj[key] = obj[key];
    }
  }

  return newObj;
};

export const getInitialDates = (
  values = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  },
  endDate = new Date(),
) => {
  const end = endDate || new Date();
  const start = subtract(end, values);

  return { startDate: start, endDate: end };
};

export const phoneMask = (value = '') => {
  if (!value) return;

  let phone = value;

  if (value.length === 11) {
    const start = value.substring(0, 2);
    const nine = value.substring(2, 3);
    const mid = value.substring(3, 7);
    const end = value.substring(7, 11);

    phone = `(${start}) ${nine} ${mid}-${end}`;
  } else if (value.length === 10) {
    const start = value.substring(0, 2);
    const mid = value.substring(2, 6);
    const end = value.substring(6, 10);

    phone = `(${start}) ${mid}-${end}`;
  }

  return phone;
};

export const formatPlate = (plate = '') => {
  const formattedPlate = plate.replace(/([A-Z]{3})([0-9]{4})/, '$1-$2');

  return formattedPlate;
};

export const getCoordsFromGeoJSON = geoJson => {
  if (geoJson?.type === 'MultiPolygon') {
    const waypoints = [];

    geoJson.geometry.coordinates.forEach(polygon => {
      polygon.forEach(ring => {
        ring.forEach(coord => {
          waypoints.push([coord[1], coord[0]]);
        });
      });
    });

    return waypoints;
  } else if (geoJson?.type === 'Polygon') {
    const waypoints = [];

    geoJson.geometry.coordinates.forEach(ring => {
      ring.forEach(coord => {
        waypoints.push([coord[1], coord[0]]);
      });
    });

    return waypoints;
  } else if (geoJson?.type === 'FeatureCollection') {
    const waypoints = [];

    geoJson.features.forEach(feature => {
      const geometry = feature.geometry;

      if (geometry.type === 'Polygon' || geometry.type === 'MultiPolygon') {
        const coordinates =
          geometry.type === 'Polygon'
            ? [geometry.coordinates]
            : geometry.coordinates;

        coordinates.forEach(polygon => {
          polygon.forEach(ring => {
            ring.forEach(coord => {
              waypoints.push([coord[1], coord[0]]);
            });
          });
        });
      }
    });

    return waypoints;
  }
};

export const getPointCenter = (points = []) => {
  const coordinates = [];

  for (const point of points) {
    const coordinate = { latitude: point[0], longitude: point[1] };

    if (isValidCoordinate(coordinate)) coordinates.push(coordinate);
  }

  const center = getCenter(coordinates);

  return center;
};

export const exportDateFormat = (date, time = true) => {
  const targetDate = new Date(date);

  if (!time) return format(targetDate, 'dd_MM_yyyy');

  return format(targetDate, 'dd_MM_yyyy_HH_mm');
};

export const getExportData = (
  data = [],
  columns = [],
  options = { formatting: {}, validateColumns: false },
) => {
  const items = data.map(event => {
    const fields = [];

    columns.forEach((column, index) => {
      const { field, hide } = column;

      if (hide) return;

      let value = event[field];

      const { formatting } = options;

      if (Object.keys(formatting).length > 0 && formatting[field]) {
        value = formatting[field](value);
      }

      fields[index] = value;
    });

    return fields;
  });

  if (options?.validateColumns) {
    const visibleColumns = columns.filter(column => !column?.hide);

    return { items, visibleCols: visibleColumns };
  }

  return items;
};

export const getFileType = type => {
  const imageExtensions = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'webp',
    'tiff',
    'svg',
    'ico',
  ];
  const videoExtensions = [
    'mp4',
    'webm',
    'mov',
    'avi',
    'flv',
    'mkv',
    '3gp',
    '3g2',
    'wmv',
  ];
  const documentExtensions = ['csv', 'txt'];
  const excel = ['xlsx', 'xls'];
  const word = ['docx', 'doc'];
  const pdf = 'pdf';

  if (imageExtensions.includes(type)) return 'image';
  else if (videoExtensions.includes(type)) return 'video';
  else if (documentExtensions.includes(type)) return 'document';
  else if (excel.includes(type)) return 'excel';
  else if (word.includes(type)) return 'word';
  else if (type === pdf) return 'pdf';
  else return 'unknown';
};

export const saveFile = ({ data, ext, contentType, filename } = {}) => {
  const blob = new Blob([data], {
    type: contentType,
  });

  saveAs(blob, `${filename}.${ext}`);
};

export const isEmpty = value => {
  if (value == null) return true;
  if (typeof value === 'string') return value.trim().length === 0;
  if (Array.isArray(value)) return value.length === 0;
  if (typeof value === 'object') return Object.keys(value).length === 0;
  return false;
};

export const buildRoute = (basePath, subroutes) => {
  return Object.keys(subroutes).reduce((routes, key) => {
    const path = `${basePath}${subroutes[key].path}`;
    routes[key] = { path };

    if (subroutes[key].subroutes) {
      routes[key].subroutes = buildRoute(path, subroutes[key].subroutes);
    }

    return routes;
  }, {});
};

export const buildPayload = (keyMapping, values, data) => {
  return Object.keys(keyMapping).reduce((payload, key) => {
    let dataValue = data[key];

    if (
      !(key in values) ||
      JSON.stringify(values[key]) !== JSON.stringify(dataValue)
    ) {
      let value = values[key] !== undefined ? values[key] : '';
      payload[keyMapping[key]] =
        typeof value === 'string' ? value.trim() : value;
    }

    return payload;
  }, {});
};

export const getUniqueValues = (data, key, formatValue) => {
  const uniqueItems = Array.from(
    new Set(data?.map(v => v[key]).map(formatValue)),
  ).join(', ');

  return uniqueItems;
};

export const validateLandlineBR = phoneNumber => {
  if (phoneNumber.length !== 10) {
    return false;
  }

  const validDDD =
    /^(11|12|13|14|15|16|17|18|19|21|22|24|27|28|31|32|33|34|35|37|38|41|42|43|44|45|46|47|48|49|51|53|54|55|61|62|64|63|65|66|67|68|69|71|73|74|75|77|79|81|82|83|84|85|86|87|88|89|91|92|93|94|95|96|97|98|99)$/;

  if (!validDDD.test(phoneNumber.substring(0, 2))) {
    return false;
  }

  const validInitials = /^[2-5]$/;
  if (!validInitials.test(phoneNumber[2])) {
    return false;
  }

  return true;
};

export const getFileName = disposition => {
  const utf8FilenameRegex = /filename\*=utf-8''([^;]*)/i;
  const asciiFilenameRegex = /filename=("?)([^"]*)\1/i;

  let fileName = '';

  const utf8Match = utf8FilenameRegex.exec(disposition);
  if (utf8Match != null) {
    fileName = decodeURIComponent(utf8Match[1]);
  } else {
    const asciiMatch = asciiFilenameRegex.exec(disposition);
    if (asciiMatch != null) {
      fileName = asciiMatch[2];
    }
  }

  return fileName;
};

export const hasPermission = (userRole, moduleId, submoduleId, permission) => {
  const module = userRole.modules.find(
    module => module.identifier === moduleId,
  );

  if (module && module.isActive) {
    if (module.permissions[permission]) {
      if (submoduleId) {
        const submodule = module.submodules.find(
          submodule => submodule.identifier === submoduleId,
        );

        if (submodule && submodule.isActive) {
          return submodule.permissions[permission] === true;
        }
        return false;
      }
      return true;
    }
  }

  return false;
};
